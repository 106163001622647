import { IconHistory, IconHistoryToggle } from "@tabler/icons-react";
import clsx from "clsx";
import { format, isBefore } from "date-fns";

import { nextUpdate } from "@joy/shared-calculator";
import { displayDays, mapOrEmpty, pluralize, toTitle } from "@joy/shared-utils";

import { specialChars } from "../../assets";
import { Time, settingParts } from "../../components";
import {
  ApnSetting,
  DeliverySetting,
  DynamicAlarmSetting,
  GaugeSettingsItem,
  LoggerSetting,
  MeasurementSetting,
  QualitySetting,
  ScheduleSetting,
  ServerSetting,
  StaticAlarmSetting,
} from "../../data";

export const statusInfo = ({
  deactivatedAt,
  response,
  setting,
}: GaugeSettingsItem) => {
  const left = {
    Icon: IconHistoryToggle,
    prefix: "Updated",
    date: response?.updatedAt,
    fallback: "",
  };
  const right = {
    Icon: IconHistory,
    prefix: "Updating",
    date: nextUpdate({
      now: new Date(),
      schedule: response?.settings.schedule,
      timezone: response?.timezone,
    }),
    fallback: "on next contact",
  };

  if (setting?.active === false) {
    if (deactivatedAt) {
      left.prefix = "Disabled";
      left.date = deactivatedAt;
      right.date = undefined;
      right.prefix = "";
      right.fallback = "";
    } else {
      right.prefix = "Disabling";
    }
  } else {
    if (!response) {
      left.prefix = "";
    }
    if (
      response &&
      (!setting || isBefore(setting.updatedAt, response.updatedAt))
    ) {
      right.date = undefined;
      right.prefix = "";
      right.fallback = "Up to date";
    }
  }

  return { left, right };
};

export const statusRenderer = ({
  Icon,
  prefix,
  date,
  fallback,
}: ReturnType<typeof statusInfo>["left"]) => (
  <>
    <Icon className="size-5 flex-none opacity-60" />
    <Time
      className="truncate"
      prefix={prefix}
      date={date}
      options={{ fallback }}
    />
  </>
);

export const scheduleRenderer = (
  schedule: ScheduleSetting & { timezone?: string },
) => {
  const startTime = format(
    new Date(1, 1, 1, schedule.startTime.hour, schedule.startTime.minute, 0, 0),
    "h:mm aaa",
  );

  let days, hours;
  switch (schedule.every) {
    case "Month":
      days = (
        <p className={settingParts.sentence}>
          On the <strong>21st</strong>, <strong>Monthly</strong>
        </p>
      );
      hours = (
        <p className={settingParts.sentence}>
          At <strong>{startTime}</strong> ({schedule.timezone || "UTC"})
        </p>
      );
      break;
    case "Fortnight":
      days = (
        <p className={settingParts.sentence}>
          On the <strong>1st</strong> and <strong>14th</strong>,{" "}
          <strong>Fortnightly</strong>
        </p>
      );
      hours = (
        <p className={settingParts.sentence}>
          At <strong>{startTime}</strong> ({schedule.timezone || "UTC"})
        </p>
      );
      break;
    case "Week":
      days = (
        <div className={settingParts.sentence}>
          Weekly, <strong>{displayDays(schedule.days)}</strong>
        </div>
      );
      hours = (
        <p className={settingParts.sentence}>
          Starting at <strong>{startTime}</strong> ({schedule.timezone || "UTC"}
          )
        </p>
      );
      break;
  }

  return (
    <>
      {days}
      {hours}
      {schedule.every === "Week" && schedule.frequency < 24 && (
        <p>
          Every <strong>{schedule.frequency}</strong> hours
          {schedule.endTime && (
            <>
              , until <strong>{schedule.endTime}</strong>
            </>
          )}
        </p>
      )}
    </>
  );
};

const defaultStaticAlarm: StaticAlarmSetting = {
  enabled: false,
  polarity: null,
  threshold: null,
  error: null,
};
const defaultDynamicAlarm: DynamicAlarmSetting = {
  enabled: false,
  polarity: null,
  rate: null,
};
type AlarmSetting = {
  staticAlarms?: StaticAlarmSetting[] | null | undefined;
  dynamicAlarms?: DynamicAlarmSetting[] | null | undefined;
};

export const buildAlarmsSetting = (
  setting: AlarmSetting | null | undefined,
): AlarmSetting => ({
  staticAlarms: [
    setting?.staticAlarms?.[0] || defaultStaticAlarm,
    setting?.staticAlarms?.[1] || defaultStaticAlarm,
    setting?.staticAlarms?.[2] || defaultStaticAlarm,
  ],
  dynamicAlarms: [
    setting?.dynamicAlarms?.[0] || defaultDynamicAlarm,
    setting?.dynamicAlarms?.[1] || defaultDynamicAlarm,
  ],
});

export const alarmRenderer = ({
  staticAlarms,
  dynamicAlarms,
}: AlarmSetting) => (
  <>
    {mapOrEmpty(
      (staticAlarms || []).filter((alarm) => alarm.enabled),
      ({ polarity, threshold, error }) => (
        <p className={settingParts.sentence}>
          When reading is <strong>{polarity}</strong> than{" "}
          <strong>
            {threshold} {specialChars.plusMinus}
            {error}
          </strong>
        </p>
      ),
      <div className={clsx("flex gap-2", settingParts.sentence)}>
        <IconHistoryToggle className="size-5 flex-none opacity-60" />
        <span>No static alarms</span>
      </div>,
    )}
    {mapOrEmpty(
      (dynamicAlarms || []).filter((alarm) => alarm.enabled),
      ({ polarity, rate }) => (
        <p className={settingParts.sentence}>
          When reading is <strong>{polarity}</strong> more than{" "}
          <strong>{rate}</strong>
        </p>
      ),
      <div className={clsx("flex gap-2", settingParts.sentence)}>
        <IconHistoryToggle className="size-5 flex-none opacity-60" />
        <span>No dynamic alarms</span>
      </div>,
    )}
  </>
);

type SonicQualitySetting = {
  measurement: MeasurementSetting | null | undefined;
  quality: QualitySetting | null | undefined;
};

export const buildSonicQualitySetting = (
  setting: SonicQualitySetting | object | null | undefined,
) =>
  setting &&
  "measurement" in setting &&
  "quality" in setting &&
  (setting.measurement || setting.quality)
    ? {
        measurement: setting.measurement,
        quality: setting.quality,
      }
    : null;

export const sonicQualityRenderer = ({
  measurement,
  quality,
}: SonicQualitySetting) => (
  <>
    {measurement && (
      <p className={settingParts.sentence}>{toTitle(measurement)} Setup</p>
    )}
    {quality && (
      <p className={settingParts.sentence}>
        <strong>RSSI: </strong>
        {quality.rssi}
      </p>
    )}
    {quality && (
      <p className={settingParts.sentence}>
        <strong>SRC: </strong>
        {quality.src}
      </p>
    )}
  </>
);

export const serverRenderer = ({
  apn,
  server,
}: {
  apn: ApnSetting | null | undefined;
  server: ServerSetting | null | undefined;
}) => (
  <>
    {apn && (
      <>
        <p className={settingParts.sentence}>
          <strong>Gateway: </strong>
          {apn.gateway}
          {apn.authenticated ? " (authenticated)" : ""}
        </p>
        {apn.username && (
          <p className={settingParts.sentence}>
            <strong>Username: </strong>
            {apn.username}
          </p>
        )}
        {apn.password && (
          <p className={settingParts.sentence}>
            <strong>Password: </strong>
            {apn.password}
          </p>
        )}
      </>
    )}
    {server && (
      <>
        <p className={settingParts.sentence}>
          <strong>Host: </strong>
          {server.host}
        </p>
        <p className={settingParts.sentence}>
          <strong>Port: </strong>
          {server.port}
        </p>
      </>
    )}
  </>
);

export const dataRenderer = ({
  logger,
  delivery,
}: {
  logger: LoggerSetting | null | undefined;
  delivery: DeliverySetting | null | undefined;
}) => (
  <>
    {logger && (
      <>
        <p className={settingParts.sentence}>
          Samples data every{" "}
          <strong>{pluralize(logger.sampling, "minute")}</strong>
        </p>
        <p className={settingParts.sentence}>
          Stores data every{" "}
          <strong>{pluralize(logger.frequency, "hour")}</strong>
        </p>
      </>
    )}
    {delivery && (
      <p className={settingParts.sentence}>
        Try <strong>{delivery.attempts}</strong> times (
        <strong>{delivery.period}</strong> second delay)
      </p>
    )}
  </>
);
