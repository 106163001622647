import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  ComboboxProps,
} from "@headlessui/react";
import {
  IconCheck,
  IconChevronDown,
  IconExclamationCircle,
} from "@tabler/icons-react";
import { Accessor, rankItem } from "@tanstack/match-sorter-utils";
import { FieldApi } from "@tanstack/react-form";
import clsx from "clsx";
import { ReactNode, useMemo, useState } from "react";

import { tw } from "../../assets";
import { transitions } from "../transitions";
import { FieldError } from "./error";
import { Field, Label, fieldKinds } from "./parts";
import { TextInputProps, inputKinds, inputVariants } from "./text";

type ComboBaseProps<D> = TextInputProps & {
  id?: string;
  placeholder?: string;
  autoFocus?: boolean;
  loading?: ReactNode;
  openButton?: boolean;
  optionKey?: (option: NonNullable<D>) => string | number;
  optionLabel?: (option: NonNullable<D>) => string;
  optionCreate?: (query: string) => NonNullable<D>;
  invalid?: boolean;
};

type ComboLookupProps<D> = {
  filtered: NonNullable<D>[];
  query: string;
  setQuery: (query: string) => void;
};

const optionParts = {
  option: tw`group flex cursor-pointer select-none items-center gap-2 rounded-md px-3 py-2 data-[focus]:bg-gray-300/20`,
  icon: tw`invisible size-4 flex-shrink-0 fill-white group-data-[selected]:visible`,
  text: tw`truncate`,
};

export const ComboBase = <D, M extends boolean>({
  id,
  className,
  icon: Icon,
  kind = "standard",
  variant = "action",
  placeholder,
  autoFocus,
  openButton = true,
  loading,
  optionKey = (o) => `${o}`,
  optionLabel = (o) => `${o}`,
  optionCreate,
  invalid,
  filtered,
  query,
  setQuery,
  by,
  ...props
}: ComboLookupProps<D> & ComboBaseProps<D> & ComboboxProps<D, M>) => (
  <Combobox
    by={
      by ||
      (props.multiple
        ? undefined
        : (a, b) => (a && optionKey(a)) === (b && optionKey(b)))
    }
    {...props}
  >
    <div
      className={clsx(
        inputKinds[kind],
        inputVariants[variant],
        invalid && inputVariants.error,
        className,
      )}
    >
      {Icon && <Icon className={inputKinds.icon} />}
      {props.multiple && Array.isArray(props.value) && (
        <div className="flex w-full flex-wrap gap-x-1">
          {props.value.map((v) => (
            <div
              key={optionKey(v)}
              className="mt-2 block min-w-24 max-w-36 truncate rounded-md border-0 bg-gray-300 px-2 py-0.5"
              title={optionLabel(v)}
            >
              {optionLabel(v)}
            </div>
          ))}
        </div>
      )}
      <ComboboxInput
        id={id}
        className={clsx(inputKinds.wrapped, "form-input min-w-4 text-left")}
        displayValue={(o: D) => (o ? optionLabel(o) : "")}
        onChange={(event) => setQuery(event.target.value)}
        placeholder={placeholder}
        autoFocus={autoFocus}
      />
      {invalid && (
        <IconExclamationCircle
          className={clsx(inputKinds.icon, "text-red-700")}
        />
      )}
      {loading}
      {openButton && (
        <ComboboxButton>
          <IconChevronDown className={inputKinds.icon} />
        </ComboboxButton>
      )}
    </div>
    <ComboboxOptions
      transition
      anchor="bottom"
      className={clsx(
        "z-20 w-[var(--input-width)] overflow-auto rounded-md border-0 bg-white shadow-md ring-1 ring-inset ring-gray-200 transition-colors [--anchor-gap:4px] [--anchor-max-height:20rem] focus:outline-none",
        transitions.fade,
      )}
    >
      {filtered.map((option) => (
        <ComboboxOption
          key={optionKey(option)}
          value={option}
          className={optionParts.option}
        >
          <IconCheck className={optionParts.icon} />
          <div className={optionParts.text} title={optionLabel(option)}>
            {optionLabel(option)}
          </div>
        </ComboboxOption>
      ))}
      {optionCreate &&
        query.length > 0 &&
        !filtered.find((i) => optionLabel(i) === query) && (
          <ComboboxOption
            value={optionCreate(query)}
            className={optionParts.option}
          >
            <IconCheck className={optionParts.icon} />
            <div className={optionParts.text}>Use "{query}"</div>
          </ComboboxOption>
        )}
    </ComboboxOptions>
  </Combobox>
);

type ComboInputProps<D> = ComboBaseProps<D> & {
  options: NonNullable<D>[];
  accessors: Accessor<NonNullable<D>>[];
};

export const ComboInput = <D, M extends boolean>({
  options,
  accessors,
  ...rest
}: ComboInputProps<D> & ComboboxProps<D, M>) => {
  const [query, setQuery] = useState("");
  const filtered = useMemo(
    () =>
      options
        .map((o) => {
          const { passed, rank } = rankItem(o, query, {
            accessors,
            threshold: 2,
          });

          return { o, rank, passed };
        })
        .filter((r) => r.passed)
        .sort((a, b) => a.rank - b.rank)
        .map((r) => r.o),
    [options, query],
  );

  return (
    <ComboBase
      filtered={filtered}
      setQuery={setQuery}
      query={query}
      {...rest}
    />
  );
};

export const ComboInputField = <D, M extends boolean>({
  field,
  ...props
}: {
  field: FieldApi<any, any, any, any, M extends true ? D[] : D>;
} & ComboInputProps<D> &
  ComboboxProps<D, M>) => (
  <ComboInput
    id={field.name}
    name={field.name}
    value={field.state.value as ComboboxProps<D, M>["value"]}
    onChange={(e) => field.handleChange(e as M extends true ? D[] : D)}
    invalid={field.state.meta.errors.length > 0}
    {...props}
  />
);

export const ComboField = <D, M extends boolean = false>({
  field,
  label,
  fieldKind,
  ...rest
}: {
  field: FieldApi<any, any, any, any, M extends true ? D[] : D>;
  label: string;
  fieldKind?: keyof typeof fieldKinds;
} & ComboInputProps<D> &
  ComboboxProps<D, M>) => (
  <Field kind={fieldKind}>
    <Label htmlFor={field.name.toString()}>{label}</Label>
    <ComboInputField field={field} {...rest} />
    <FieldError field={field} />
  </Field>
);
