import useResizeObserver from "@react-hook/resize-observer";
import { DependencyList, UIEvent, useCallback, useEffect, useRef } from "react";

export const useScrollReached = (
  fn: () => void,
  dependencies: DependencyList,
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const fetchMoreOnBottomReached = useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;

        if (scrollHeight - scrollTop - clientHeight < 50) {
          fn();
        }
      }
    },
    dependencies,
  );
  useEffect(() => {
    fetchMoreOnBottomReached(containerRef.current);
  }, [fetchMoreOnBottomReached]);
  useResizeObserver(containerRef, () =>
    fetchMoreOnBottomReached(containerRef.current),
  );

  return {
    containerRef,
    onScroll: (e: UIEvent<HTMLDivElement>) =>
      fetchMoreOnBottomReached(e.currentTarget),
  };
};
