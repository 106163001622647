import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { CommentDeleteContent } from "../comments";
import { TankDownloadContent } from "./download";
import { TankEditContent } from "./edit";

export const TankActionPanel = () => {
  const { Modal, Slide, props } = useMatch({
    from: "/_authenticated/tanks/$tankId",
    select: (s) => {
      const props = {
        tankId: s.params.tankId,
        commentId: s.search.comment,
        entityId: s.params.tankId,
        entityType: "tank" as const,
      };

      switch (s.search.action) {
        case "delete":
          if (s.search.comment) return { Modal: CommentDeleteContent, props };
          return { props };
        case "download":
          return { Modal: TankDownloadContent, props };
        case "edit":
          return { Slide: TankEditContent, props };
      }

      return { props };
    },
  });

  return (
    <Actions
      modal={Modal && <Modal {...props} />}
      slide={Slide && <Slide {...props} />}
    />
  );
};
