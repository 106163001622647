import {
  FieldProps,
  Field as HeadlessField,
  Label as HeadlessLabel,
  LabelProps,
} from "@headlessui/react";
import clsx from "clsx";
import { ForwardedRef, forwardRef } from "react";

import { tw } from "../../assets";

export const fieldKinds = {
  column: tw`flex flex-1 flex-col items-start items-stretch gap-2 group-[.is-collapsed]/collapse:px-4 sm:group-[.is-collapsed]/collapse:grid sm:group-[.is-collapsed]/collapse:grid-cols-3 sm:group-[.is-collapsed]/collapse:gap-4 sm:group-[.is-collapsed]/collapse:space-y-0 sm:group-[.is-collapsed]/collapse:px-6 sm:group-[.is-collapsed]/collapse:py-5`,
  subgroup: tw`flex flex-1 flex-col items-start items-stretch gap-2 group-[.is-collapsed]/collapse:px-1 sm:group-[.is-collapsed]/collapse:grid sm:group-[.is-collapsed]/collapse:grid-cols-3 sm:group-[.is-collapsed]/collapse:gap-4 sm:group-[.is-collapsed]/collapse:space-y-0 sm:group-[.is-collapsed]/collapse:px-1 sm:group-[.is-collapsed]/collapse:py-1`,
  wrapped: tw`flex flex-1 flex-col items-start items-stretch gap-2`,
};

export const Field = ({
  kind = "column",
  className,
  ...props
}: { kind?: keyof typeof fieldKinds } & FieldProps) => (
  <HeadlessField className={clsx(fieldKinds[kind], className)} {...props} />
);

export const labelKinds = {
  standard: tw`block self-start text-sm font-medium text-gray-900 group-[.is-collapsed]/collapse:pt-1.5`,
  error: tw`block text-left text-sm font-normal text-red-700 group-[.is-collapsed]/collapse:col-span-2 group-[.is-collapsed]/collapse:col-start-2`,
};

export const Label = forwardRef(
  (
    {
      kind = "standard",
      className,
      ...props
    }: { kind?: keyof typeof labelKinds } & LabelProps,
    ref: ForwardedRef<HTMLLabelElement>,
  ) => (
    <HeadlessLabel
      ref={ref}
      className={clsx(labelKinds[kind], className)}
      {...props}
    />
  ),
);
