import { createFileRoute } from "@tanstack/react-router";
import clsx from "clsx";
import { z } from "zod";

import { technologyIcons } from "../../../assets";
import {
  StatsHeader,
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  imageCell,
  stackCell,
} from "../../../components";
import {
  gauge,
  gaugesQuery,
  listLoader,
  useAuth,
  userName,
} from "../../../data";
import { useTable } from "../../../hooks";

const validateSearch = z.object({
  action: z.enum(["create"]).optional(),
});

export const Route = createFileRoute("/_authenticated/gauges/")({
  validateSearch,
  loader: async ({ context }) => listLoader(context.queryClient, gaugesQuery()),
  component,
});

export function component() {
  const { hasTeamPermission } = useAuth();
  const gauges = useTable(gaugesQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "technology",
        imageCell({ header: "", icons: technologyIcons }),
      ),
      c.accessor(
        "name",
        stackCell({
          header: "Name",
          detail: (a) => [[a.account?.name || "Unassigned", a.customer?.name]],
        }),
      ),
      c.accessor("account.name", {
        header: "Account",
        meta: {
          className: clsx(
            cellKinds.appearsxl,
            "divide-x divide-gray-400 truncate",
          ),
        },
        cell: (v) =>
          [v.getValue() || "Unassigned", v.row.original.customer?.name]
            .filter((i) => !!i)
            .map((i, idx) => (
              <span className="px-1 first-of-type:pl-0" key={idx}>
                {i}
              </span>
            )),
      }),
      c.accessor("updatedAt", dateCell({ header: "Updated" })),
      c.accessor("updatedBy", {
        header: "By",
        meta: { className: cellKinds.appears },
        cell: (v) => userName(v.getValue()),
      }),
      c.display(actionCell()),
    ],
    select: (gaugeId) => ({
      to: "/gauges/$gaugeId",
      params: { gaugeId },
    }),
    initialVisibility: {
      customer_name: hasTeamPermission("admin"),
    },
    create: hasTeamPermission("admin")
      ? { to: "/gauges", search: { action: "create" }, replace: true }
      : undefined,
    word: gauge,
  });

  return (
    <TablePage {...gauges}>
      <StatsHeader stats={gauges.stats} />
    </TablePage>
  );
}
