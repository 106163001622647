import { IconCalculator, IconGauge, IconSettings } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { technologyIcons } from "../../../assets";
import { ItemHeader, NotFound, Tabs, Time } from "../../../components";
import {
  GaugeProduct,
  gaugeQuery,
  singleItemLoader,
  useAuth,
} from "../../../data";
import {
  GaugeActionPanel,
  GaugeCalculatorPanel,
  GaugeInfoPanel,
  GaugeObservationsPanel,
  GaugeSettingsPanel,
} from "../../../panels/gauge";

const validateSearch = z.object({
  action: z
    .enum([
      "edit",
      "config",
      "strapping",
      "disable",
      "schedule",
      "sonic",
      "delete",
    ])
    .optional(),
  comment: z.string().optional(),
});

const showSettings: Record<GaugeProduct, boolean> = {
  Cippus: false,
  TekADC: true,
  TekUltrasonic: true,
  Unregistered: false,
};

export const Route = createFileRoute("/_authenticated/gauges/$gaugeId")({
  validateSearch,
  component,
  notFoundComponent,
  loader: ({ context, params }) =>
    singleItemLoader(context.queryClient, gaugeQuery(params.gaugeId)),
  meta: ({ loaderData }) => [
    {
      title: loaderData.name,
    },
  ],
});

export function notFoundComponent() {
  const { hasTeamPermission } = useAuth();

  return hasTeamPermission("admin") ? (
    <NotFound
      item="Gauge"
      action={{ to: "/gauges", search: { action: "create" } }}
      actionLabel="Add gauge"
    />
  ) : (
    <NotFound
      item="Gauge"
      fix="Head back to the home page to find what you're looking for"
      action={{ to: "/" }}
      actionLabel="Go to home page"
    />
  );
}

export function component() {
  const gaugeId = Route.useParams({ select: (p) => p.gaugeId });
  const { data } = useSuspenseQuery(gaugeQuery(gaugeId));

  return (
    <>
      <ItemHeader
        image={technologyIcons[data.technology]}
        title={data.name}
        subtitle={<Time date={data.seenAt} prefix="Last active " />}
      />
      <Tabs
        name="gauge"
        tabs={[
          {
            icon: IconGauge,
            name: "Gauge",
            panel: <GaugeObservationsPanel gaugeId={gaugeId} />,
          },
          {
            name: "Config",
            icon: IconCalculator,
            panel: <GaugeCalculatorPanel gaugeId={gaugeId} />,
          },
          {
            name: "Settings",
            icon: IconSettings,
            hidden: !showSettings[data.product],
            panel: <GaugeSettingsPanel gaugeId={gaugeId} />,
          },
        ]}
        info={<GaugeInfoPanel gaugeId={gaugeId} />}
      />
      <GaugeActionPanel />
    </>
  );
}
