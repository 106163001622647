declare const auth_client_id: string;
declare const map_token: string;

declare global {
  const is_local: boolean | undefined;
}

export const config = {
  authId: auth_client_id,
  mapToken: map_token,
};
