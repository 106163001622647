import { Validator } from "@tanstack/form-core";
import { FieldComponent, FormOptions, useForm } from "@tanstack/react-form";
import { ReactNode, useState } from "react";

import { specialChars, tw } from "../../assets";
import { Button, Form, FormError, Progress } from "../../components";

export const inlineFormStyles = {
  list: tw`divide-y divide-gray-200 border-t border-gray-300 text-sm leading-6`,
  row: tw`relative w-full items-center px-4 py-6 sm:flex`,
  label: tw`font-medium sm:w-48 sm:flex-none sm:pr-6`,
  content: tw`relative mt-1 flex min-h-9 min-w-0 items-center gap-x-6 transition-opacity aria-disabled:pointer-events-none aria-disabled:opacity-15 sm:mt-0 sm:flex-auto`,
  display: tw`flex-1 truncate aria-hidden:hidden`,
};

export const InlineForm = <
  TFormData,
  TFormValidator extends Validator<TFormData, unknown> | undefined = undefined,
>({
  display,
  fields,
  formOptions,
  label,
}: {
  display?: string;
  fields: (field: FieldComponent<TFormData, TFormValidator>) => ReactNode;
  formOptions: FormOptions<TFormData, TFormValidator>;
  label: string;
}) => {
  const [submitError, setSubmitError] = useState<Error>();
  const form = useForm(formOptions);
  const [update, setUpdate] = useState(false);
  const [updating, setUpdating] = useState(false);

  return (
    <div className={inlineFormStyles.row}>
      <dt className={inlineFormStyles.label}>{label}</dt>
      <dd className={inlineFormStyles.content} aria-disabled={updating}>
        <Form
          kind="row"
          hidden={!update}
          onSubmit={async () => {
            setSubmitError(undefined);
            try {
              setUpdating(true);
              await form.handleSubmit();
              setUpdate(false);
              form.reset();
            } catch (e) {
              setSubmitError(
                e instanceof Error ? e : new Error(`Update failed`),
              );
            } finally {
              setUpdating(false);
            }
          }}
          onReset={() => {
            form.reset();
            setSubmitError(undefined);
            setUpdate(false);
          }}
        >
          <div className="flex min-w-0 flex-1 flex-col gap-y-1">
            <div className="flex gap-x-2">{fields(form.Field)}</div>
            <FormError error={submitError} />
          </div>
          <Button type="submit" kind="link" text="Save" />
          <Button type="reset" kind="link" variant="cancel" text="Cancel" />
        </Form>
        <p className={inlineFormStyles.display} aria-hidden={update}>
          {display || specialChars.endash}
        </p>
        <Button
          kind="link"
          aria-hidden={update}
          onClick={() => setUpdate(true)}
          text="Update"
        />
      </dd>
      <Progress show={updating} />
    </div>
  );
};
