import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { brandIcons } from "../../../assets";
import {
  StatsHeader,
  TablePage,
  actionCell,
  cellKinds,
  dateCell,
  imageCell,
  stackCell,
} from "../../../components";
import {
  account,
  accountsQuery,
  listLoader,
  useAuth,
  userName,
} from "../../../data";
import { useTable } from "../../../hooks";
import { AccountsActionPanel } from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["create"]).optional(),
});

export const Route = createFileRoute("/_authenticated/accounts/")({
  validateSearch,
  loader: async ({ context }) =>
    listLoader(context.queryClient, accountsQuery()),
  component,
});

export function component() {
  const { hasTeamPermission } = useAuth();
  const accounts = useTable(accountsQuery(), {
    columnDefs: (c) => [
      c.accessor(
        "product",
        imageCell({
          header: "",
          icons: brandIcons,
        }),
      ),
      c.accessor(
        "name",
        stackCell({
          header: "Name",
          detail: (a) => [[a.customer?.name, a.plan?.code]],
        }),
      ),
      c.accessor("customer.name", {
        header: "Customer",
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor("plan.code", {
        header: "Plan",
        meta: { className: cellKinds.appearsxl },
      }),
      c.accessor(
        "updatedAt",
        dateCell({
          header: "Updated",
        }),
      ),
      c.accessor("updatedBy", {
        header: "By",
        meta: { className: cellKinds.appears },
        cell: (v) => userName(v.getValue()),
      }),
      c.display(actionCell()),
    ],
    select: (accountId) => ({
      to: "/accounts/$accountId",
      params: { accountId },
    }),
    initialVisibility: {
      customer_name: hasTeamPermission("admin"),
      plan_code: hasTeamPermission("admin"),
    },
    create: hasTeamPermission("admin")
      ? { to: "/accounts", search: { action: "create" }, replace: true }
      : undefined,
    word: account,
  });

  return (
    <TablePage {...accounts}>
      <StatsHeader stats={accounts.stats} />
      <AccountsActionPanel />
    </TablePage>
  );
}
