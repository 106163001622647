import { Range, convertRange } from "./range.js";
import { StrapTable, strappingCalculate, strappingMax } from "./strapping.js";

export type TekADCProbe = {
  name: string;
  rangeDigital: Range;
  rangeAnalog: Range;
  rangeProbe: Range;
  rangeHeight: Range;
};

export const tekADCProbes: TekADCProbe[] = [
  {
    name: "Rochester 0.3 Bar",
    rangeDigital: { min: 0, max: 1024 },
    rangeAnalog: { min: 0, max: 5 },
    rangeProbe: { min: 0.5, max: 4.5 },
    rangeHeight: { min: 0, max: 3060 },
  },
];

export const tekADCProbeNames = [
  ...tekADCProbes.map((probe) => probe.name),
  "Custom",
];

export const tekADCProbeName = (config: TekADCOptions["config"]): string =>
  tekADCProbes.find(
    (probe) =>
      probe.rangeDigital.min === config.rangeDigital.min &&
      probe.rangeDigital.max === config.rangeDigital.max &&
      probe.rangeAnalog.min === config.rangeAnalog.min &&
      probe.rangeAnalog.max === config.rangeAnalog.max &&
      probe.rangeProbe.min === config.rangeProbe.min &&
      probe.rangeProbe.max === config.rangeProbe.max &&
      probe.rangeHeight.min === config.rangeHeight.min &&
      probe.rangeHeight.max === config.rangeHeight.max,
  )?.name || "Custom";

export type TekADCOptions = {
  config: {
    rangeDigital: Range;
    rangeAnalog: Range;
    rangeProbe: Range;
    rangeHeight: Range;
    specificGravityProbe: number;
    specificGravityActual: number;
    offsetHeight: number;
    offsetVolume: number;
  };
  strappingTable: StrapTable;
};

export const tekADCReading = (
  reading: number,
  {
    config: { rangeDigital, rangeAnalog, rangeProbe, rangeHeight },
  }: TekADCOptions,
) => {
  const analogValue = convertRange(reading, {
    rangeIn: rangeDigital,
    rangeOut: rangeAnalog,
  });
  const probeValue = convertRange(analogValue, {
    rangeIn: rangeProbe,
    rangeOut: rangeHeight,
  });

  return probeValue;
};

export const tekADCHeight = (reading: number, options: TekADCOptions) => {
  const probeValue = tekADCReading(reading, options);
  const { specificGravityProbe, specificGravityActual, offsetHeight } =
    options.config;

  return (
    probeValue * (specificGravityProbe / specificGravityActual) + offsetHeight
  );
};

export const tekADCVolume = (reading: number, options: TekADCOptions) => {
  const volume = strappingCalculate(
    options.strappingTable,
    tekADCHeight(reading, options),
    "mm",
  );

  return volume ? volume + options.config.offsetVolume : undefined;
};

export const tekADCReverse = (volume: number, options: TekADCOptions) => {
  const height = strappingCalculate(
    options.strappingTable,
    volume - options.config.offsetVolume,
    "l",
  );
  if (height === undefined) return undefined;

  const {
    specificGravityProbe,
    specificGravityActual,
    offsetHeight,
    rangeHeight,
    rangeProbe,
    rangeAnalog,
    rangeDigital,
  } = options.config;
  const probeValue =
    (height - offsetHeight) / (specificGravityProbe / specificGravityActual);

  const analogValue = convertRange(probeValue, {
    rangeIn: rangeHeight,
    rangeOut: rangeProbe,
  });
  const reading = convertRange(analogValue, {
    rangeIn: rangeAnalog,
    rangeOut: rangeDigital,
  });

  return reading;
};

export const tekADCLimit = (percent: number, options: TekADCOptions) => {
  const max = strappingMax(options.strappingTable, "l");
  if (!max) return undefined;
  const volume = (percent / 100) * max;
  const reading = tekADCReverse(volume, options);
  if (reading === undefined || reading < 0) return undefined;

  return Math.round(reading);
};
