import { IconChevronDown } from "@tabler/icons-react";
import {
  RowData,
  Table as TanstackTable,
  flexRender,
} from "@tanstack/react-table";
import clsx from "clsx";
import { DetailedHTMLProps, HTMLAttributes } from "react";

import { specialChars, tw } from "../assets";
import { EmptyTable, EmptyTableProps, cellKinds, tableParts } from "./cells";
import { Progress } from "./navigation";
import { text } from "./scheme";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    className?: string;
  }
}

export const tableVariants = {
  card: tw`rounded-sm bg-white shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg`,
  slide: tw`-mx-6`,
};

export type TableProps<I> = {
  table: TanstackTable<I>;
  container: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  variant?: keyof typeof tableVariants;
  updating?: boolean;
  loading?: boolean;
  empty?: EmptyTableProps;
};

export const Table = <I,>({
  table,
  container,
  updating,
  loading,
  empty,
  variant = "card",
}: TableProps<I>) => (
  <div
    {...container}
    className={clsx(tableVariants[variant], container.className)}
  >
    <table className={tableParts.table}>
      <thead className={tableParts.head}>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            <th className="w-5">
              <span className="absolute inset-x-0 bottom-0 border-b border-b-gray-300">
                <Progress show={updating} />
              </span>
            </th>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                id={header.column.id}
                title={header.column.columnDef.header?.toString()}
                onClick={header.column.getToggleSortingHandler()}
                aria-sort={
                  (
                    {
                      asc: "ascending",
                      desc: "descending",
                      none: undefined,
                    } as const
                  )[header.column.getIsSorted() || "none"]
                }
                className={clsx(
                  text,
                  cellKinds.title,
                  header.column.columnDef.meta?.className,
                  header.column.getCanSort() && "cursor-pointer",
                )}
              >
                <span className="flex items-center justify-start gap-3">
                  <span className="min-w-0 truncate">
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </span>
                  {header.column.getCanSort() && (
                    <IconChevronDown
                      className="group-aria-sorted:bg-gray-100 group-aria-sorted:opacity-100 group-aria-desc:rotate-180 size-5 flex-none rounded-sm p-0.5 opacity-0 transition-all group-hover:opacity-100"
                      stroke={2.4}
                    />
                  )}
                </span>
              </th>
            ))}
            <th className="w-5" />
          </tr>
        ))}
      </thead>
      <tbody>
        {table.getRowModel().rows.map((row) => (
          <tr
            key={row.id}
            onClick={row.getToggleSelectedHandler()}
            className={clsx("relative", row.getCanSelect() && "cursor-pointer")}
          >
            <td />
            {row.getVisibleCells().map((cell) => (
              <td
                key={cell.id}
                className={clsx(
                  tableParts.row,
                  cell.column.columnDef.meta?.className,
                )}
              >
                {flexRender(cell.column.columnDef.cell, cell.getContext())}
              </td>
            ))}
            <td />
          </tr>
        ))}
        {loading &&
          Array.from({ length: 3 }, (_, i) => (
            <tr key={`loading-${i}`} className="relative">
              <td />
              {table.getAllLeafColumns().map((column) => (
                <td
                  key={column.id}
                  className={clsx(
                    tableParts.row,
                    column.columnDef.meta?.className,
                  )}
                >
                  <span className="block animate-pulse rounded-md bg-gray-200 text-transparent">
                    {specialChars.nbsp}
                  </span>
                </td>
              ))}
              <td />
            </tr>
          ))}
        {!loading && !!empty && table.getRowCount() === 0 && (
          <tr>
            <td />
            <td colSpan={table.getAllLeafColumns().length}>
              <span className="flex flex-col items-center justify-center px-2 py-8">
                <EmptyTable {...empty} />
              </span>
            </td>
            <td />
          </tr>
        )}
      </tbody>
    </table>
  </div>
);
