import { useNavigate } from "@tanstack/react-router";

import { TablePage } from "../../components";
import { rolesQuery, useAuth, usePrefetchRole, user } from "../../data";
import { useTable } from "../../hooks";
import { roleColumns } from "../role";

export const CustomerUsersPanel = ({ customerId }: { customerId: string }) => {
  const navigate = useNavigate();
  const prefetchRole = usePrefetchRole();
  const { hasTeamPermission } = useAuth();

  const roles = useTable(rolesQuery(customerId, "customer"), {
    columnDefs: roleColumns({
      prefetchRole,
      hasTeamPermission,
      navigate,
      display: "user",
      current: { to: "/accounts/$accountId" },
      permissions: ["read", "write", "install", "admin"],
    }),
    initialSort: [{ id: "Manage", desc: true }],
    create: {
      to: "/customers/$customerId",
      search: { action: "add", entity: "user" },
    },
    word: user,
  });

  return <TablePage {...roles} />;
};
