import { IconBuildingFactory, IconUsers } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import {
  EntityUpdated,
  ItemHeader,
  NotFound,
  Tabs,
  pageParts,
} from "../../../components";
import { customerQuery, singleItemLoader } from "../../../data";
import {
  CustomerAccountsPanel,
  CustomerActionPanel,
  CustomerInfoPanel,
  CustomerUsersPanel,
} from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["add", "edit", "delete"]).optional(),
  entity: z.enum(["user", "account"]).optional(),
  role: z.string().optional(),
});

export const Route = createFileRoute("/_admin/customers/$customerId")({
  validateSearch,
  loader: ({ context, params }) =>
    singleItemLoader(context.queryClient, customerQuery(params.customerId)),
  component,
  notFoundComponent: () => (
    <NotFound
      item="Customer"
      action={{ to: "/customers", search: { action: "create" } }}
      actionLabel="Add customer"
    />
  ),
  meta: ({ loaderData }) => [
    {
      title: loaderData.name,
    },
  ],
});

export function component() {
  const customerId = Route.useParams({ select: (p) => p.customerId });
  const { data } = useSuspenseQuery(customerQuery(customerId));

  return (
    <div className={pageParts.page}>
      <ItemHeader title={data.name} subtitle={<EntityUpdated {...data} />} />
      <Tabs
        name="customer"
        tabs={[
          {
            name: "Accounts",
            icon: IconBuildingFactory,
            panel: <CustomerAccountsPanel customerId={customerId} />,
          },
          {
            name: "Users",
            icon: IconUsers,
            panel: <CustomerUsersPanel customerId={customerId} />,
          },
        ]}
        info={<CustomerInfoPanel customerId={customerId} />}
      />
      <CustomerActionPanel />
    </div>
  );
}
