import { toTitle } from "@joy/shared-utils";

import { RadioField, SlideContent, TextField } from "../../components";
import { useUpdateGaugeSonicQuality } from "../../data";

export const GaugeSonicQualityContent = ({ gaugeId }: { gaugeId: string }) => {
  const { data, error, form, validators } = useUpdateGaugeSonicQuality(gaugeId);

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description="Update and save the gauge schedule below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="measurement"
        validators={validators.measurement}
        children={(field) => (
          <RadioField
            field={field}
            label="Measurement Type"
            options={validators.measurement.onSubmit._def.values}
            optionLabel={(o) => toTitle(o)}
          />
        )}
      />
      <form.Field
        name="rssi"
        validators={validators.filter}
        children={(field) => (
          <TextField
            field={field}
            label="RSSI Filter"
            type="number"
            inputMode="decimal"
          />
        )}
      />
      <form.Field
        name="src"
        validators={validators.filter}
        children={(field) => (
          <TextField
            field={field}
            label="SRC Filter"
            type="number"
            inputMode="decimal"
          />
        )}
      />
    </SlideContent>
  );
};
