import { IconPower } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { useMemo } from "react";

import { Setting } from "../../components";
import { gaugeSettingsQuery, useAuth } from "../../data";
import {
  alarmRenderer,
  buildAlarmsSetting,
  buildSonicQualitySetting,
  dataRenderer,
  scheduleRenderer,
  serverRenderer,
  sonicQualityRenderer,
  statusInfo,
  statusRenderer,
} from "./settings.renderers";

export const GaugeSettingsPanel = ({ gaugeId }: { gaugeId: string }) => {
  const { hasTeamPermission } = useAuth();
  const { data } = useSuspenseQuery(gaugeSettingsQuery(gaugeId));

  const status = useMemo(() => statusInfo(data), [data]);

  return (
    <div className="flex flex-col gap-3">
      <Setting
        title="Status"
        edit={
          hasTeamPermission("super") && data.setting?.active !== false
            ? {
                to: "/gauges/$gaugeId",
                params: { gaugeId },
                search: { action: "disable" },
                icon: IconPower,
                variant: "danger",
                text: "Disable Gauge",
              }
            : undefined
        }
        className="flex gap-2"
        left={status.left}
        right={status.right}
        render={statusRenderer}
      />
      <Setting
        title="Schedule"
        edit={
          data.permissions.includes("install")
            ? {
                to: `/gauges/$gaugeId`,
                params: { gaugeId },
                search: { action: "schedule" },
                text: "Edit Schedule",
              }
            : undefined
        }
        left={
          data.response?.settings.schedule && {
            ...data.response.settings.schedule,
            timezone: data.response.timezone,
          }
        }
        right={
          data.setting?.settings.schedule && {
            ...data.setting.settings.schedule,
            timezone: data.setting.timezone,
          }
        }
        render={scheduleRenderer}
      />
      {data.product === "TekUltrasonic" && (
        <Setting
          title="Sonic Quality Setup"
          edit={
            data.permissions.includes("install")
              ? {
                  to: `/gauges/$gaugeId`,
                  params: { gaugeId },
                  search: { action: "sonic" },
                  text: "Edit Sonic Quality",
                }
              : undefined
          }
          left={buildSonicQualitySetting(data.response?.settings)}
          right={buildSonicQualitySetting(data.setting?.settings)}
          render={sonicQualityRenderer}
        />
      )}
      <Setting
        title="Alarms"
        left={buildAlarmsSetting(data.response?.settings)}
        right={buildAlarmsSetting(data.setting?.settings)}
        render={alarmRenderer}
      />
      <Setting
        title="Data Logging"
        left={
          data.response?.settings
            ? {
                logger: data.response.settings.logger,
                delivery: data.response.settings.delivery,
              }
            : null
        }
        right={null}
        render={dataRenderer}
      />
      <Setting
        title="Server"
        left={
          data.response?.settings
            ? {
                apn: data.response.settings.apn,
                server: data.response.settings.server,
              }
            : null
        }
        right={null}
        render={serverRenderer}
      />
    </div>
  );
};
