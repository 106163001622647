import { downloadFile } from "../download.js";
import { NestedObjectKeys, getValue } from "../get.js";

export const wrapCell = (value: unknown) => {
  if (value === undefined || value === null) return "";
  const stringified = JSON.stringify(value);
  return stringified.replaceAll('\\"', '""');
};

export type ToCSVOptions<
  I extends object,
  K extends NestedObjectKeys<I> = NestedObjectKeys<I>,
> = {
  cells: K[];
  headers: Record<K, string>;
  visibility?: Partial<Record<K, boolean>>;
};

export const toCSV = <I extends object, K extends NestedObjectKeys<I>>(
  items: I[],
  { cells, headers, visibility }: ToCSVOptions<I, K>,
): string => {
  const visibleCells = cells.filter((c) => visibility?.[c] !== false);

  const rows = [
    visibleCells.map((cell) => wrapCell(headers[cell])),
    ...(items || []).map((row) =>
      visibleCells.map((cell) => wrapCell(getValue(row, cell))),
    ),
  ];
  return rows.map((r) => r.join(",")).join("\r\n");
};

export const downloadCSV = <I extends object, K extends NestedObjectKeys<I>>(
  items: I[],
  { file, ...options }: ToCSVOptions<I, K> & { file: string },
) => {
  const asBlob = new Blob([toCSV(items, options)], { type: "text/csv" });
  downloadFile(asBlob, `${file}.csv`);
};
