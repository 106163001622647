import { InputProps, ListboxProps } from "@headlessui/react";
import { Suspense } from "react";

import { tw } from "../assets";
import { LinkButton, LinkButtonProps } from "./button";
import { ListInput, ListInputProps, TextInput, TextInputProps } from "./forms";
import { Table, TableProps } from "./table";

export const pageParts = {
  page: tw`flex h-full min-h-0 flex-col`,
  actions: tw`container mx-auto mt-4 flex flex-wrap items-center justify-between gap-2`,
  contents: tw`container mx-auto mt-4 min-h-0 flex-1`,
};

const Views = <TData, TView extends string>({
  table,
  value,
  variant,
  views,
}: {
  table: TableProps<TData>;
  value: TView;
  variant?: TableProps<TData>["variant"];
  views?: Record<TView, typeof Table>;
}) => {
  const View = (views && views[value]) || Table;

  return (
    <Suspense>
      <View {...table} variant={variant} />
    </Suspense>
  );
};

export const TablePage = <TData, TView extends string = "table">({
  actions,
  children,
  filter,
  create,
  table,
  view,
  views,
  variant,
}: {
  actions?: React.ReactNode;
  children?: React.ReactNode;
  filter: TextInputProps & InputProps;
  create: LinkButtonProps | undefined;
  table: TableProps<TData>;
  view: ListInputProps<TView> & ListboxProps<any, TView> & { value: TView };
  views?: Record<TView, typeof Table>;
  variant?: TableProps<TData>["variant"];
}) => (
  <div className={pageParts.page}>
    {children}
    <div className={pageParts.actions}>
      <TextInput {...filter} />
      {actions}
      {view.options.length > 1 && (
        <div>
          <ListInput {...view} />
        </div>
      )}
      {create && <LinkButton {...create} />}
    </div>
    <div className={pageParts.contents}>
      <Views table={table} views={views} value={view.value} variant={variant} />
    </div>
  </div>
);
