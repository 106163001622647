import { IconHistoryToggle } from "@tabler/icons-react";
import isEqual from "lodash/isEqual";
import { ReactNode } from "react";

import { specialChars, tw } from "../assets";
import { Box } from "./box";
import { LinkButtonProps } from "./button";

export const settingParts = {
  sentence: tw`my-1 text-sm`,
};

export type SettingProps<D, TTo extends string = ""> = {
  title: string;
  edit?: LinkButtonProps<TTo>;
  className?: string;
  left: D | null | undefined;
  right: D | null | undefined;
  render: (item: D) => ReactNode;
  fallback?: ReactNode;
};

const SettingFallback = () => (
  <div className="flex gap-2">
    <IconHistoryToggle className="size-5 flex-none opacity-60" />
    <span>{specialChars.endash}</span>
  </div>
);

export const Setting = <D, TTo extends string>({
  title,
  edit,
  className,
  left,
  right,
  render,
  fallback = <SettingFallback />,
}: SettingProps<D, TTo>) => (
  <Box header={{ title, edit }}>
    <div className="grid grid-cols-1 gap-3 px-2 py-3 text-sm sm:grid-cols-2">
      <div className={className}>{left ? render(left) : fallback}</div>
      <div className={className}>
        {right && !isEqual(left, right) ? render(right) : null}
      </div>
    </div>
  </Box>
);
