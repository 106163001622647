import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import { Helmet, HelmetProvider } from "react-helmet-async";

import { App } from "./app";

const rootElement = document.getElementById("root")!;
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <StrictMode>
      <HelmetProvider>
        <Helmet>
          <title>Joylevel</title>
        </Helmet>
        <App />
      </HelmetProvider>
    </StrictMode>,
  );
}
