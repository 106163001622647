import { tekADCProbeNames, tekADCProbes } from "@joy/shared-calculator";

import {
  Field,
  Label,
  ListField,
  TextField,
  TextInputField,
} from "../../components";
import { GaugeConfigContentProps } from "../../data";

export const TekADCContent = ({
  form,
  validators,
}: GaugeConfigContentProps) => (
  <>
    <form.Field
      name="probeName"
      validators={{
        onChange: ({ value, fieldApi }) => {
          const probe = tekADCProbes.find((p) => p.name === value);
          if (probe) {
            fieldApi.form.setFieldValue(
              "rangeDigital.min",
              probe.rangeDigital.min.toString(),
            );
            fieldApi.form.setFieldValue(
              "rangeDigital.max",
              probe.rangeDigital.max.toString(),
            );
            fieldApi.form.setFieldValue(
              "rangeAnalog.min",
              probe.rangeAnalog.min.toString(),
            );
            fieldApi.form.setFieldValue(
              "rangeAnalog.max",
              probe.rangeAnalog.max.toString(),
            );
            fieldApi.form.setFieldValue(
              "rangeProbe.min",
              probe.rangeProbe.min.toString(),
            );
            fieldApi.form.setFieldValue(
              "rangeProbe.max",
              probe.rangeProbe.max.toString(),
            );
            fieldApi.form.setFieldValue(
              "rangeHeight.min",
              probe.rangeHeight.min.toString(),
            );
            fieldApi.form.setFieldValue(
              "rangeHeight.max",
              probe.rangeHeight.max.toString(),
            );
          }

          return undefined;
        },
      }}
      children={(field) => (
        <ListField
          field={field}
          label="Probe Type"
          options={tekADCProbeNames}
        />
      )}
    />
    <form.Subscribe
      selector={(v) => v.values.probeName}
      children={(probeName) =>
        probeName === "Custom" ? (
          <div className="m-4 rounded-sm bg-gray-100 p-1">
            {(["Digital", "Analog", "Probe", "Height"] as const).map((type) => (
              <Field key={type} kind="subgroup">
                <Label htmlFor={`range${type}.min`}>{type} Range</Label>
                <div className="flex flex-1 flex-row items-center gap-x-2 sm:col-span-2">
                  <form.Field
                    name={`range${type}.min`}
                    validators={validators.range}
                    children={(field) => (
                      <TextInputField
                        field={field}
                        placeholder="Minimum"
                        inputMode="decimal"
                      />
                    )}
                  />
                  <form.Field
                    name={`range${type}.max`}
                    validators={validators.range}
                    children={(field) => (
                      <TextInputField
                        field={field}
                        placeholder="Maximum"
                        inputMode="decimal"
                      />
                    )}
                  />
                </div>
              </Field>
            ))}
          </div>
        ) : null
      }
    />

    <Field>
      <Label htmlFor="specificGravityActual">Specific Gravity</Label>
      <div className="flex flex-1 flex-row items-center gap-x-2 sm:col-span-2">
        <form.Field
          name="specificGravityActual"
          validators={validators.specificGravity}
          children={(field) => (
            <TextInputField
              field={field}
              placeholder="Actual"
              inputMode="decimal"
            />
          )}
        />
        <form.Field
          name="specificGravityProbe"
          validators={validators.specificGravity}
          children={(field) => (
            <TextInputField
              field={field}
              placeholder="Probe"
              inputMode="decimal"
            />
          )}
        />
      </div>
    </Field>
    <form.Field
      name="offsetHeight"
      validators={validators.offset}
      children={(field) => (
        <TextField field={field} label="Pressure Offset" inputMode="decimal" />
      )}
    />
    <form.Field
      name="offsetVolume"
      validators={validators.offset}
      children={(field) => (
        <TextField field={field} label="Volume Offset" inputMode="decimal" />
      )}
    />
  </>
);
