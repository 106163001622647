import { Field, Label, TextField, TextInputField } from "../../components";
import { GaugeConfigContentProps } from "../../data";

export const CippusContent = ({
  form,
  validators,
}: GaugeConfigContentProps) => (
  <>
    <Field>
      <Label htmlFor="specificGravityActual">Specific Gravity</Label>
      <div className="flex flex-1 flex-row items-center gap-x-2 sm:col-span-2">
        <form.Field
          name="specificGravityActual"
          validators={validators.specificGravity}
          children={(field) => (
            <TextInputField
              field={field}
              placeholder="Actual"
              inputMode="decimal"
              autoFocus
            />
          )}
        />
        <form.Field
          name="specificGravityProbe"
          validators={validators.specificGravity}
          children={(field) => (
            <TextInputField
              field={field}
              placeholder="Probe"
              inputMode="decimal"
            />
          )}
        />
      </div>
    </Field>
    <form.Field
      name="offsetHeight"
      validators={validators.offset}
      children={(field) => (
        <TextField field={field} label="Pressure Offset" inputMode="decimal" />
      )}
    />
    <form.Field
      name="offsetVolume"
      validators={validators.offset}
      children={(field) => (
        <TextField field={field} label="Volume Offset" inputMode="decimal" />
      )}
    />
  </>
);
