import { IconDots } from "@tabler/icons-react";
import { useInfiniteQuery } from "@tanstack/react-query";
import clsx from "clsx";
import { useState } from "react";

import {
  Button,
  ComboInput,
  Field,
  Label,
  Menu,
  SlideContent,
  TextField,
  TextInput,
  TextInputField,
  cellKinds,
  tableParts,
  text,
} from "../../components";
import { gaugesQuery, isModelGauge, useUpdateGaugeStrapping } from "../../data";

const strapFieldProps = {
  cell: {
    className: clsx(tableParts.row, "pb-0 pt-0"),
  },
  mm: {
    kind: "simple" as const,
    inputMode: "decimal" as const,
    className: "py-0.5",
    type: "number",
    placeholder: "mm",
  },
  l: {
    kind: "simple" as const,
    inputMode: "decimal" as const,
    className: "py-0.5",
    type: "number",
    placeholder: "l",
  },
};

export const GaugeStrappingContent = ({ gaugeId }: { gaugeId: string }) => {
  const [existing, setExisting] = useState(false);
  const gauges = useInfiniteQuery(gaugesQuery(existing));
  const { data, error, form, validators } = useUpdateGaugeStrapping(gaugeId);

  return (
    <SlideContent
      title={`Update ${data.name}`}
      description={
        <>
          To calculate the volume on a gauge, the strapping table requires at
          least 2 rows. Update and save the strapping table below.
          <br />
          <br />
          Alternatively, you can{" "}
          <Button
            kind="link"
            variant="standard"
            text="copy an existing strapping table"
            onClick={() => setExisting(true)}
          />
          .
        </>
      }
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      {existing ? (
        <Field>
          <Label>Select tank model:</Label>
          <ComboInput
            options={(gauges.data || []).filter(isModelGauge)}
            accessors={[(o) => [o.model, o.name]]}
            optionKey={(o) => o.id}
            optionLabel={(o) => `${o.model} (${o.name})`}
            multiple={false}
            onChange={(value) => {
              if (value) {
                form.setFieldValue("model", value.model);
                form.setFieldValue(
                  "strappingTable",
                  value.strappingTable.map((s) => ({
                    mm: s.mm.toString(),
                    l: s.l.toString(),
                  })),
                );
                setExisting(false);
              }
            }}
          />
        </Field>
      ) : (
        <>
          <form.Field
            name="model"
            children={(field) => (
              <TextField field={field} label="Tank model" autoFocus />
            )}
          />
          <div className="px-3">
            <table className={tableParts.table}>
              <thead className={tableParts.head}>
                <tr>
                  <th className={clsx(text, cellKinds.title)}>Height</th>
                  <th className={clsx(text, cellKinds.title)}>Volume</th>
                  <th />
                </tr>
              </thead>
              <form.Field
                name="strappingTable"
                mode="array"
                children={(field) => (
                  <tbody>
                    {field.state.value.map((_, i) => (
                      <tr key={i}>
                        <td {...strapFieldProps.cell}>
                          <form.Field
                            name={`strappingTable[${i}].mm`}
                            validators={validators.strapping}
                            children={(field) => (
                              <TextInputField
                                field={field}
                                {...strapFieldProps.mm}
                              />
                            )}
                          />
                        </td>
                        <td {...strapFieldProps.cell}>
                          <form.Field
                            name={`strappingTable[${i}].l`}
                            validators={validators.strapping}
                            children={(field) => (
                              <TextInputField
                                field={field}
                                {...strapFieldProps.l}
                              />
                            )}
                          />
                        </td>
                        <td {...strapFieldProps.cell}>
                          <Menu
                            anchor="bottom end"
                            button={{
                              icon: IconDots,
                              kind: "menu",
                              variant: "standard",
                            }}
                            items={[
                              {
                                text: "Remove row",
                                onClick: () => field.removeValue(i),
                              },
                            ]}
                          />
                        </td>
                      </tr>
                    ))}
                    <tr key={field.state.value.length}>
                      <td {...strapFieldProps.cell}>
                        <TextInput
                          {...strapFieldProps.mm}
                          id="strappingTable.next.mm"
                          onBlur={(e) => {
                            if (e.target.value) {
                              const i = field.state.value.length;
                              field.pushValue({ mm: e.target.value, l: "" });
                              setTimeout(() => {
                                document
                                  .getElementById(`strappingTable[${i}].l`)
                                  ?.focus();
                              });
                            }
                          }}
                        />
                      </td>
                      <td {...strapFieldProps.cell}>
                        <TextInput
                          {...strapFieldProps.l}
                          id="strappingTable.next.l"
                          onBlur={(e) => {
                            if (e.target.value) {
                              field.pushValue({ mm: e.target.value, l: "" });
                              setTimeout(() => {
                                document
                                  .getElementById(`strappingTable.next.mm`)
                                  ?.focus();
                              });
                            }
                          }}
                        />
                      </td>
                      <td {...strapFieldProps.cell} />
                    </tr>
                  </tbody>
                )}
              />
            </table>
          </div>
        </>
      )}
    </SlideContent>
  );
};
