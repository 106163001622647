import { createFileRoute } from "@tanstack/react-router";

import { Document } from "../../components";
import { loadDocument } from "../../data";

export const Route = createFileRoute("/_unauthenticated/privacy")({
  loader: async () => loadDocument("privacy"),
  meta: () => [{ title: "Privacy Policy" }],
  component,
});

export function component() {
  const data = Route.useLoaderData();
  return <Document {...data} />;
}
