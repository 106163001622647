import { IconCurrencyDollar } from "@tabler/icons-react";

import { ListField, SlideContent, TextField } from "../../components";
import { useCreatePlan } from "../../data";

export const PlanCreateContent = () => {
  const { error, form, validators } = useCreatePlan();

  return (
    <SlideContent
      title="Add plan"
      description="Get started by filling in the information below to create your new plan."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Creating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Create", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="code"
        validators={validators.code}
        children={(field) => (
          <TextField field={field} label="Plan code" autoFocus />
        )}
      />
      <form.Field
        name="amount"
        validators={validators.amount}
        children={(field) => (
          <TextField
            field={field}
            icon={IconCurrencyDollar}
            label="Amount"
            inputMode="decimal"
          />
        )}
      />
      <form.Field
        name="prepaid"
        validators={validators.prepaid}
        children={(field) => (
          <TextField field={field} label="Prepaid Months" inputMode="numeric" />
        )}
      />
      <form.Field
        name="model"
        validators={validators.model}
        children={(field) => (
          <ListField
            field={field}
            label="Pricing Model"
            options={validators.model.onSubmit._def.values}
            optionLabel={(o) => `Per ${o}`}
          />
        )}
      />
    </SlideContent>
  );
};
