import { IconBox } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { format, parse, subDays } from "date-fns";
import { z } from "zod";

import { downloadCSV, sortField } from "@joy/shared-utils";

import { useValidators } from "../../hooks";
import { AddressLookup, addressSchema, geocode } from "../address";
import { useAuth } from "../auth";
import { Word, clampValue, warningIndex } from "../helpers";
import { request, requestFn } from "./base";
import {
  TankDocument,
  TankLevelsDocument,
  TankRolesDocument,
  TankUnit,
  TanksDocument,
  UpdateTankDocument,
} from "./operations.generated";

export const tank: Word = {
  icon: IconBox,
  article: "a",
  singular: "tank",
  plural: "tanks",
};

export const tankUnitOptions: Record<TankUnit, string> = {
  l: "Litres",
  gal: "Gallons",
};

export const tankColours = [
  { bg: "bg-gray-300", text: "text-gray-500" },
  { bg: "bg-emerald-500", text: "text-emerald-500" },
  { bg: "bg-amber-500", text: "text-amber-500" },
  { bg: "bg-red-500", text: "text-red-500" },
];

export const tankLevelStyle = (
  possible: number | null | undefined,
  warningLevels: number[] | null | undefined,
) => {
  const percent = clampValue(possible, [0, 100]);
  const height = `${percent || 0}%`;
  const colour =
    tankColours[warningIndex(percent, warningLevels || [10, 20]) || 0]!;

  return { percent, height, colour };
};

export const tanksQuery = () =>
  infiniteQueryOptions({
    queryKey: ["tanks"],
    queryFn: ({ pageParam }) =>
      request(TanksDocument, { limit: 500, cursor: pageParam || null }),
    getNextPageParam: (lastPage) => lastPage?.tanks.next,
    refetchOnMount: "always",
    initialPageParam: "",
    select: (data) =>
      data.pages.flatMap((p) =>
        p.tanks.tanks.map((t) => ({
          ...t,
          level: t.levels.levels[0],
        })),
      ),
  });

export type TankItem = ReturnType<
  NonNullable<ReturnType<typeof tanksQuery>["select"]>
>[number];

export const tankQuery = (id: string) =>
  queryOptions({
    queryKey: ["tank", id],
    queryFn: () => request(TankDocument, { id }),
    select: (data) => ({
      ...data.tank!,
      level: data.tank?.levels.levels[0],
      permissions: data.tankAccess,
    }),
  });

export const tankLevelQuery = (id: string, daysAgo?: number) =>
  infiniteQueryOptions({
    enabled: !!daysAgo,
    queryKey: ["tank-level", id, daysAgo],
    queryFn: ({ pageParam }) =>
      request(TankLevelsDocument, {
        id,
        startAt: subDays(new Date(), daysAgo || 0),
        limit: 500,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.tank?.levels.next,
    initialPageParam: "",
    select: (data) =>
      sortField(
        data.pages.flatMap((p) => p.tank?.levels.levels || []),
        { key: "updatedAt", dir: "asc" },
      ),
  });

export const tankRolesQuery = (tankId: string) =>
  infiniteQueryOptions({
    queryKey: ["tank-roles", tankId],
    queryFn: ({ pageParam }) =>
      request(TankRolesDocument, {
        tankId,
        limit: 100,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.tankRoles.next,
    initialPageParam: "",
    select: (data) =>
      data.pages.flatMap((p) => p.tankRoles.roles.map((r) => r.user)),
  });

const validation = {
  name: z.string().min(1, "Please enter a tank name"),
  activatedAt: z.coerce.date().optional().nullable(),
  unit: z.enum(["l", "gal"]).optional().nullable(),
  contents: z.string().optional(),
  location: addressSchema.optional().nullable(),
  warningLevels: z.array(z.number()).optional().nullable(),
  deliveryThreshold: z.array(z.number()).length(1).optional().nullable(),
  notificationEmails: z.array(
    z.object(
      { id: z.string(), email: z.string().email() },
      { invalid_type_error: "Please select a user" },
    ),
  ),
};

const updateTankFn = requestFn(UpdateTankDocument);

export const useUpdateTank = (tankId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(tankQuery(tankId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: updateTankFn,
    onSuccess: async (result) => {
      queryClient.setQueryData(
        tankQuery(result.updateTank.id).queryKey,
        (existing) => {
          if (!existing) return undefined;
          return {
            ...existing,
            tank: result.updateTank,
          };
        },
      );
      queryClient.setQueryData(tanksQuery().queryKey, (existing) => {
        if (!existing) return undefined;

        return {
          ...existing,
          pages: existing.pages.map((p) => ({
            ...p,
            tanks: {
              ...p.tanks,
              tanks: p.tanks.tanks.map((u) => {
                if (u.id === result.updateTank?.id) return result.updateTank;
                return u;
              }),
            },
          })),
        };
      });
      await navigate({ to: "/tanks/$tankId", params: { tankId } });
    },
  });

  const form = useForm({
    defaultValues: {
      name: data.name || "",
      activatedAt: data.activatedAt
        ? format(data.activatedAt, "yyyy-MM-dd")
        : "",
      unit: data.unit || null,
      contents: data.contents || "",
      location: data.location
        ? ({
            type: "result",
            id: "existing",
            ...data.location,
          } as AddressLookup)
        : null,
      warningLevels: [...(data.warningLevels || []), 0, 0].slice(0, 2),
      deliveryThreshold: [data.deliveryThreshold || 0],
      notificationEmails: (data.notificationEmails || []).map((email) => ({
        id: email,
        email,
      })),
    },
    onSubmit: async ({ value }) => {
      await mutateAsync({
        id: tankId,
        fields: {
          ...value,
          activatedAt: parse(value.activatedAt, "yyyy-MM-dd", 0),
          location: await geocode.retrieve(value.location),
          warningLevels: value.warningLevels.filter((v) => v > 0),
          deliveryThreshold: value.deliveryThreshold[0]
            ? value.deliveryThreshold[0]
            : null,
          notificationEmails: value.notificationEmails.map((e) => e.email),
        },
      });
    },
    onSubmitInvalid: () => reset(),
    validatorAdapter: zodValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { data, error, form, validators };
};

const downloadLevelsFn = async ({
  id,
  daysAgo,
}: {
  id: string;
  daysAgo: number;
}) => {
  const startAt = subDays(new Date(), daysAgo);
  const levels = [];
  let cursor: string | null | undefined;
  let hasMore = true;

  while (hasMore) {
    const result = await request(TankLevelsDocument, {
      id,
      startAt,
      cursor,
      limit: 500,
    });
    cursor = result.tank?.levels.next;
    hasMore = !!cursor;
    levels.push(...(result.tank?.levels.levels || []));
  }

  return sortField(levels, { key: "updatedAt", dir: "asc" });
};

export const useDownloadTankHistory = (tankId: string) => {
  const { hasTeamPermission } = useAuth();
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(tankQuery(tankId));
  const { error, mutateAsync, reset } = useMutation({
    mutationFn: downloadLevelsFn,
    onSuccess: async (levels) => {
      downloadCSV(levels, {
        cells: [
          "updatedAt",
          "value",
          "maximum",
          "percent",
          "voltage",
          "temperature",
          "signal",
          "rssi",
          "src",
        ],
        headers: {
          updatedAt: "Time",
          value: "Level",
          maximum: "SFL",
          percent: "Level Percent",
          voltage: "Battery Percent",
          temperature: "Temperature",
          signal: "Signal",
          rssi: "RSSI",
          src: "SRC",
        },
        visibility: {
          rssi: hasTeamPermission("admin"),
          src: hasTeamPermission("admin"),
        },
        file: `${data.name.toLocaleLowerCase().split(" ").filter(Boolean).join("-")}-history`,
      });
      await navigate({ to: "/tanks/$tankId", params: { tankId } });
    },
  });
  const form = useForm({
    defaultValues: {
      daysAgo: [30],
    },
    onSubmit: async ({ value }) => {
      await mutateAsync({ id: tankId, daysAgo: value.daysAgo[0]! });
    },
    onSubmitInvalid: () => reset(),
    validatorAdapter: zodValidator(),
  });

  return { data, error, form };
};
