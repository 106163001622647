import { IconCircleCheckFilled } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { Fragment, useState } from "react";
import { z } from "zod";

import {
  Button,
  Form,
  FormError,
  FormSuccess,
  LinkButton,
  Loading,
  TextField,
} from "../../../components";
import { useAuthForm } from "../../../data";

const validateSearch = z.object({
  __clerk_ticket: z.string().optional(),
});

export const Route = createFileRoute("/_unauthenticated/login/register")({
  validateSearch,
  component,
  loaderDeps: ({ search }) => ({ ticket: search.__clerk_ticket }),
  loader: async ({ context, deps }) => {
    if (deps.ticket) {
      await context.auth.client.client?.signUp.create({
        strategy: "ticket",
        ticket: deps.ticket,
      });
    }
  },
  meta: () => [{ title: "Sign Up" }],
});

export function component() {
  const [step, setStep] = useState(0);
  const { form, validators, loading, error, signUp } = useAuthForm({
    onSubmit: async ({ firstName, lastName, email, password, code }) => {
      if (signUp.verifications.emailAddress.status === "verified") {
        await signUp.update({
          firstName,
          lastName,
          password,
        });
        window.location.reload();
      } else {
        switch (step) {
          case 0:
            await signUp.create({
              emailAddress: email,
              firstName,
              lastName,
              password,
            });
            await signUp.prepareEmailAddressVerification({
              strategy: "email_code",
            });
            setStep(1);
            break;
          case 1:
            await signUp.attemptEmailAddressVerification({
              code,
            });
            window.location.reload();
            break;
        }
      }
    },
  });

  const emailVerified = signUp.verifications.emailAddress.status === "verified";

  const fields = [
    <Fragment key="step-0">
      <FormError error={error} />
      <div className="flex gap-2">
        <form.Field
          name="firstName"
          validators={validators.firstName}
          children={(field) => (
            <TextField
              fieldKind="column"
              field={field}
              label="First name"
              inputMode="text"
            />
          )}
        />
        <form.Field
          name="lastName"
          validators={validators.lastName}
          children={(field) => (
            <TextField
              fieldKind="column"
              field={field}
              label="Last name"
              inputMode="text"
            />
          )}
        />
      </div>
      {!emailVerified && (
        <form.Field
          name="email"
          validators={validators.email}
          children={(field) => (
            <TextField
              fieldKind="column"
              field={field}
              label="Email address"
              inputMode="email"
            />
          )}
        />
      )}
      <form.Field
        name="password"
        validators={validators.password}
        children={(field) => (
          <TextField
            fieldKind="column"
            field={field}
            label="Password"
            type="password"
          />
        )}
      />
      <Button kind="full" type="submit" variant="brand" text="Sign up" />
      <Loading show={loading} text="Signing up..." />
    </Fragment>,
    <Fragment key="step-1">
      <FormSuccess
        icon={IconCircleCheckFilled}
        text="Email sent! Check your inbox for the verification code."
      />
      <FormError error={error} />
      <form.Field
        name="code"
        validators={validators.code}
        children={(field) => (
          <TextField
            fieldKind="column"
            field={field}
            label="Verification code"
            inputMode="numeric"
          />
        )}
      />
      <Button kind="full" type="submit" variant="brand" text="Verify" />
      <Loading show={loading} text="Verifying..." />
    </Fragment>,
  ][step];

  return (
    <>
      <h2 className="text-2xl font-bold">Sign up to Joylevel</h2>
      <p className="mt-2 text-sm text-gray-500">
        Already have an account?{" "}
        <LinkButton
          to="/login"
          kind="link"
          variant="brand"
          text="Sign in now"
          replace
        />
      </p>
      <Form kind="column" onSubmit={form.handleSubmit}>
        {fields}
      </Form>
    </>
  );
}
