import { Clerk } from "@clerk/clerk-js/headless";
import { createContext, useContext, useEffect, useRef, useState } from "react";

import { TeamPermission, hasTeamPermission } from "@joy/shared-utils";

import { config } from "../config";

let tokenGetter: () => Promise<string | null | undefined> = () =>
  Promise.resolve(undefined);

export const getAuthToken = () => tokenGetter();

export type AuthContext = {
  client: Clerk;
  isAuthenticated: boolean;
  email: string | undefined;
  hasTeamPermission: (needed: TeamPermission) => boolean;
};

export const useSetupAuth = () => {
  const initialized = useRef(false);
  const [loading, setLoading] = useState(false);
  const [context, setContext] = useState<AuthContext>();

  useEffect(() => {
    if (initialized.current) return;
    initialized.current = true;

    const timeout = setTimeout(() => {
      setLoading(true);
    }, 1000);

    (async () => {
      const client = new Clerk(config.authId, {
        proxyUrl: is_local ? "/auth" : undefined,
      });
      await client.load();

      if (client.session) tokenGetter = client.session.getToken;

      setContext({
        client,
        isAuthenticated: !!client.user,
        email: client.user?.primaryEmailAddress?.emailAddress,
        hasTeamPermission: hasTeamPermission(
          client.user?.publicMetadata.jlteam,
        ),
      });
    })()
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        clearTimeout(timeout);
        setTimeout(() => setLoading(false), 700);
      });
  }, []);

  return { loading, context };
};

const authContext = createContext<AuthContext>({
  client: null!,
  isAuthenticated: false,
  email: undefined,
  hasTeamPermission: () => false,
});

export const AuthProvider = authContext.Provider;

export const useAuth = () => useContext(authContext);
