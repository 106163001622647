import { IconCircleCheckFilled } from "@tabler/icons-react";
import { createFileRoute } from "@tanstack/react-router";
import { Fragment, useState } from "react";

import {
  Button,
  Form,
  FormError,
  FormSuccess,
  LinkButton,
  Loading,
  TextField,
} from "../../../components";
import { useAuthForm } from "../../../data";

export const Route = createFileRoute("/_unauthenticated/login/forgot")({
  component,
  meta: () => [{ title: "Sign In" }],
});

export function component() {
  const [step, setStep] = useState(0);
  const { form, validators, loading, error, signIn } = useAuthForm({
    onSubmit: async ({ email, password, code }) => {
      switch (step) {
        case 0:
          await signIn.create({
            identifier: email,
          });
          await signIn.prepareFirstFactor({
            strategy: "reset_password_email_code",
            emailAddressId:
              signIn.supportedFirstFactors?.find(
                (f) => f.strategy === "reset_password_email_code",
              )?.emailAddressId || "",
          });
          setStep(1);
          break;
        case 1:
          await signIn.attemptFirstFactor({
            strategy: "reset_password_email_code",
            code,
          });
          setStep(2);
          break;
        case 2:
          await signIn.resetPassword({ password });
          window.location.reload();
          break;
      }
    },
  });

  const fields = [
    <Fragment key="step-0">
      <FormError error={error} />
      <form.Field
        name="email"
        validators={validators.email}
        children={(field) => (
          <TextField
            fieldKind="column"
            field={field}
            label="Email address"
            inputMode="email"
          />
        )}
      />
      <Button kind="full" type="submit" variant="brand" text="Reset password" />
      <Loading show={loading} text="Resetting..." />
    </Fragment>,
    <Fragment key="step-1">
      <FormSuccess
        icon={IconCircleCheckFilled}
        text="Email sent! Check your inbox for the verification code."
      />
      <FormError error={error} />
      <form.Field
        name="code"
        validators={validators.code}
        children={(field) => (
          <TextField
            fieldKind="column"
            field={field}
            label="Verification code"
            inputMode="numeric"
          />
        )}
      />
      <Button kind="full" type="submit" variant="brand" text="Verify" />
      <Loading show={loading} text="Verifying..." />
    </Fragment>,
    <Fragment key="step-2">
      <FormSuccess
        icon={IconCircleCheckFilled}
        text="Verified! Enter a new password to continue."
      />
      <FormError error={error} />
      <form.Field
        name="password"
        validators={validators.password}
        children={(field) => (
          <TextField
            fieldKind="column"
            field={field}
            label="Password"
            type="password"
          />
        )}
      />
      <Button kind="full" type="submit" variant="brand" text="Reset password" />
      <Loading show={loading} text="Reset password..." />
    </Fragment>,
  ][step];

  return (
    <>
      <h2 className="text-2xl font-bold">Forgot your password?</h2>
      <p className="mt-2 text-sm text-gray-500">
        Remember it now?{" "}
        <LinkButton
          to="/login"
          kind="link"
          variant="brand"
          text="Sign up now"
          replace
        />
      </p>
      <Form kind="column" onSubmit={form.handleSubmit}>
        {fields}
      </Form>
    </>
  );
}
