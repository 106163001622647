import { IconMailCheck } from "@tabler/icons-react";
import clsx from "clsx";

import { loginIcons, specialChars } from "../../assets";
import {
  FormError,
  FormSuccess,
  InlineForm,
  SlideContent,
  TextInputField,
  inlineFormStyles,
} from "../../components";
import { useAuth } from "../../data";

export const ProfileContent = () => {
  const {
    client: { user },
  } = useAuth();
  if (!user) return null;

  return (
    <SlideContent
      title="Profile"
      description="Manage Your Profile and Login Information"
    >
      <dl className={inlineFormStyles.list}>
        <div className={inlineFormStyles.row}>
          <dt className={inlineFormStyles.label}>Email address</dt>
          <dd className={inlineFormStyles.content}>
            <p className={inlineFormStyles.display}>
              {user.primaryEmailAddress?.emailAddress}
            </p>
            {user.primaryEmailAddress?.verification.status === "verified" ? (
              <FormSuccess icon={IconMailCheck} text="Verified" />
            ) : (
              <FormError error={new Error("Unverified")} />
            )}
          </dd>
        </div>
        <InlineForm
          label="Password"
          display="********"
          formOptions={{
            defaultValues: {
              currentPassword: "",
              newPassword: "",
            },
            onSubmit: ({ value }) => user.updatePassword(value),
          }}
          fields={(Field) => (
            <>
              <Field
                name="currentPassword"
                children={(field) => (
                  <TextInputField
                    field={field}
                    placeholder="Current password"
                    type="password"
                  />
                )}
              />
              <Field
                name="newPassword"
                children={(field) => (
                  <TextInputField
                    field={field}
                    placeholder="New password"
                    type="password"
                  />
                )}
              />
            </>
          )}
        />
        <div className={inlineFormStyles.row}>
          <dt className={inlineFormStyles.label}>Linked Accounts</dt>
          <dd className={inlineFormStyles.content}>
            {user.externalAccounts.length ? (
              user.externalAccounts
                .map((account) => account.provider)
                .filter((p): p is keyof typeof loginIcons =>
                  Object.hasOwn(loginIcons, p),
                )
                .map((identity) => (
                  <p
                    key={identity}
                    className={clsx(inlineFormStyles.display, "flex gap-2")}
                  >
                    <img className="size-6" src={loginIcons[identity]} />
                  </p>
                ))
            ) : (
              <p>{specialChars.endash}</p>
            )}
          </dd>
        </div>
      </dl>
      <dl className={inlineFormStyles.list}>
        <InlineForm
          label="Full name"
          display={`${user.firstName || specialChars.endash} ${user.lastName || specialChars.endash}`}
          formOptions={{
            defaultValues: {
              firstName: user.firstName || "",
              lastName: user.lastName || "",
            },
            onSubmit: ({ value }) => user.update(value),
          }}
          fields={(Field) => (
            <>
              <Field
                name="firstName"
                children={(field) => (
                  <TextInputField field={field} placeholder="First name" />
                )}
              />
              <Field
                name="lastName"
                children={(field) => (
                  <TextInputField field={field} placeholder="Last name" />
                )}
              />
            </>
          )}
        />
        <InlineForm
          label="Phone number"
          display={user.unsafeMetadata.phone || undefined}
          formOptions={{
            defaultValues: {
              phone: user.unsafeMetadata.phone || "",
            },
            onSubmit: ({ value }) =>
              user.update({
                unsafeMetadata: {
                  ...user.unsafeMetadata,
                  ...value,
                },
              }),
          }}
          fields={(Field) => (
            <Field
              name="phone"
              children={(field) => (
                <TextInputField
                  field={field}
                  placeholder="Phone number"
                  inputMode="tel"
                />
              )}
            />
          )}
        />
        <InlineForm
          label="Company"
          display={user.unsafeMetadata.company || undefined}
          formOptions={{
            defaultValues: {
              company: user.unsafeMetadata.company || "",
            },
            onSubmit: ({ value }) =>
              user.update({
                unsafeMetadata: {
                  ...user.unsafeMetadata,
                  ...value,
                },
              }),
          }}
          fields={(Field) => (
            <Field
              name="company"
              children={(field) => (
                <TextInputField field={field} placeholder="Company" />
              )}
            />
          )}
        />
        <InlineForm
          label="Title"
          display={user.unsafeMetadata.title || undefined}
          formOptions={{
            defaultValues: {
              title: user.unsafeMetadata.title || "",
            },
            onSubmit: ({ value }) =>
              user.update({
                unsafeMetadata: {
                  ...user.unsafeMetadata,
                  ...value,
                },
              }),
          }}
          fields={(Field) => (
            <Field
              name="title"
              children={(field) => (
                <TextInputField field={field} placeholder="Title" />
              )}
            />
          )}
        />
      </dl>
    </SlideContent>
  );
};
