import { InputProps, ListboxProps } from "@headlessui/react";
import { IconCloudDownload, IconSearch } from "@tabler/icons-react";
import {
  DefaultError,
  InfiniteData,
  QueryKey,
  UseInfiniteQueryOptions,
  useInfiniteQuery,
} from "@tanstack/react-query";
import { LinkProps, useNavigate } from "@tanstack/react-router";
import {
  ColumnDef,
  ColumnHelper,
  SortingState,
  Table,
  VisibilityState,
  createColumnHelper,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { ChangeEvent, ReactNode, useMemo } from "react";
import { ZodEnum, z } from "zod";

import { toTitle } from "@joy/shared-utils";

import {
  Button,
  LinkButton,
  LinkButtonProps,
  ListInputProps,
  StatProps,
  TableProps,
  TextInputProps,
  tableParts,
} from "../components";
import { Word } from "../data";
import { useDebounce } from "./debounce";
import { filterSchema, fuzzyFilter, sortSchema } from "./filter";
import { useScrollReached } from "./scroll";
import { useStorage } from "./storage";

export type SortOption = { id: string; label: ReactNode };

export const useTable = <
  TQueryFnData,
  TError = DefaultError,
  TData extends { id: string }[] = InfiniteData<TQueryFnData> &
    { id: string }[],
  TQueryKey extends QueryKey = QueryKey,
  TPageParam = unknown,
  TView extends string = "table",
  TColumnDefs extends (
    c: ColumnHelper<TData[number]>,
    viewMode: TView,
  ) => ColumnDef<TData[number], any>[] = (
    c: ColumnHelper<TData[number]>,
    viewMode: TView,
  ) => ColumnDef<TData[number]>[],
>(
  query: UseInfiniteQueryOptions<
    TQueryFnData,
    TError,
    TData,
    TQueryFnData,
    TQueryKey,
    TPageParam
  >,
  {
    columnDefs,
    initialSort = [],
    initialVisibility = {},
    select,
    download,
    create,
    statFns,
    view,
    word,
  }: {
    columnDefs: TColumnDefs;
    initialSort?: SortingState;
    initialVisibility?: VisibilityState;
    select?: (id: string) => LinkProps;
    download?: (filtered: TData) => void;
    create?: LinkProps;
    statFns?: ((filtered: TData, original: TData | undefined) => StatProps)[];
    view?: {
      options: ZodEnum<[TView, ...TView[]]>;
      optionLabel?: (view: TView) => ReactNode;
    };
    word: Word;
  },
): {
  stats: StatProps[];
  filter: TextInputProps & InputProps;
  view: ListInputProps<TView> & ListboxProps<any, TView> & { value: TView };
  sort: [
    { value: string | undefined; onChange: (id: string) => void },
    { value: "asc" | "desc"; onChange: (dir: "asc" | "desc") => void },
  ];
  create: LinkButtonProps | undefined;
  table: TableProps<TData>;
} => {
  const navigate = useNavigate();
  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    isFetchingNextPage,
  } = useInfiniteQuery(query);
  const [filterValue, setFilterValue] = useStorage({
    defaultValue: "",
    key: `${query.queryKey}-filter`,
    schema: filterSchema,
    storage: "session",
  });
  const [columnSort, setColumnSort] = useStorage({
    defaultValue: initialSort,
    key: `${query.queryKey}-sort`,
    schema: sortSchema,
    storage: "local",
  });
  const [viewMode, setViewMode] = useStorage({
    defaultValue: view?.options._def.values[0] || ("table" as TView),
    key: `${query.queryKey}-view`,
    schema: view?.options || z.enum(["table"] as [TView]),
    storage: "local",
  });
  const columns = useMemo(
    () => columnDefs(createColumnHelper<TData[number]>(), viewMode),
    [viewMode],
  );
  const table = useReactTable({
    columns,
    data: data || [],
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowId: (row) => row.id,
    enableSorting: !!select,
    enableRowSelection: !!select,
    globalFilterFn: fuzzyFilter,
    onSortingChange: setColumnSort,
    onGlobalFilterChange: setFilterValue,
    onRowSelectionChange: (selected) => {
      if (select) {
        const value = typeof selected === "function" ? selected({}) : selected;
        const id = Object.keys(value)[0];
        if (id) navigate(select(id));
      }
    },
    state: {
      rowSelection: {},
      sorting: columnSort,
      columnVisibility: initialVisibility,
      globalFilter: filterValue,
    },
  });
  const { containerRef, onScroll } = useScrollReached(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);
  const stats = useDebounce(
    () => {
      let filtered = "";
      if (filterValue) filtered = `${table.getRowCount()}/`;
      const total = Array.isArray(data) ? data.length.toLocaleString() : "1";
      const more = hasNextPage ? "+" : "";

      const filteredRows = table
        .getRowModel()
        .flatRows.map((r) => r.original) as TData;

      return [
        {
          label: toTitle(word.plural),
          value: `${filtered}${total}${more}`,
          delta: download && (
            <Button
              kind="link"
              title={`Download ${word.plural}`}
              icon={IconCloudDownload}
              onClick={() => download(filteredRows)}
            />
          ),
        },
        ...(statFns?.map((fn) => fn(filteredRows, data)) || []),
      ];
    },
    250,
    [Array.isArray(data) && data.length, hasNextPage, filterValue],
  );

  return {
    stats,
    filter: {
      className: "max-w-80",
      icon: IconSearch,
      placeholder: `Filter ${word.plural}...`,
      value: filterValue,
      onChange: (e: ChangeEvent<HTMLInputElement>) =>
        setFilterValue(e.currentTarget.value),
    },
    create: create && {
      ...create,
      icon: word.icon,
      text: `Add ${word.singular}`,
      collapseText: true,
    },
    view: {
      value: viewMode,
      onChange: setViewMode,
      options: view?.options?._def.values || (["table"] as [TView]),
      optionLabel: view?.optionLabel,
    },
    sort: [
      {
        value: columnSort[0]?.id,
        onChange: (id) =>
          setColumnSort((existing) => [{ id, desc: !!existing?.[0]?.desc }]),
      },
      {
        value: columnSort[0]?.desc ? "desc" : "asc",
        onChange: (dir) =>
          setColumnSort((existing) => [
            {
              id: existing?.[0]?.id || initialSort[0]?.id || "",
              desc: dir === "desc",
            },
          ]),
      },
    ],
    table: {
      container: { className: tableParts.card, onScroll, ref: containerRef },
      table: table as Table<TData>,
      updating: isFetching,
      loading: isFetchingNextPage || isLoading,
      empty: {
        icon: word.icon,
        title: `No ${word.plural} found.`,
        description: create && (
          <>
            To get started,{" "}
            <LinkButton
              {...create}
              kind="link"
              variant="standard"
              text={`add ${word.article} ${word.singular}`}
            />
          </>
        ),
      },
    },
  };
};
