import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

import { gql } from "./util";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  DateTime: { input: Date | string; output: Date | string };
  JSONObject: { input: Record<string, any>; output: Record<string, any> };
};

export type Account = {
  __typename?: "Account";
  config?: Maybe<AccountConfig>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: User;
  customer?: Maybe<Customer>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  plan?: Maybe<Plan>;
  product: AccountProduct;
  tanks: TankList;
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: User;
};

export type AccountTanksArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type AccountConfig =
  | ColibriConfig
  | JoyGaugeConfig
  | ProGaugeConfig
  | RmsDeltaConfig
  | SmartFillConfig;

export type AccountInput = {
  config?: InputMaybe<Scalars["JSONObject"]["input"]>;
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  planId?: InputMaybe<Scalars["ID"]["input"]>;
  product?: InputMaybe<AccountProduct>;
};

export type AccountList = {
  __typename?: "AccountList";
  accounts: Array<Account>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type AccountProduct =
  | "Colibri"
  | "JoyGauge"
  | "ProGauge"
  | "RMSDelta"
  | "SmartFill";

export type ApnSetting = {
  __typename?: "ApnSetting";
  authenticated: Scalars["Boolean"]["output"];
  gateway: Scalars["String"]["output"];
  password: Scalars["String"]["output"];
  username: Scalars["String"]["output"];
};

export type CippusConfig = {
  __typename?: "CippusConfig";
  offsetHeight: Scalars["Float"]["output"];
  offsetVolume: Scalars["Float"]["output"];
  specificGravityActual: Scalars["Float"]["output"];
  specificGravityProbe: Scalars["Float"]["output"];
};

export type CippusDetail = {
  __typename?: "CippusDetail";
  connection: Scalars["String"]["output"];
  range: Scalars["String"]["output"];
};

export type ColibriConfig = {
  __typename?: "ColibriConfig";
  url?: Maybe<Scalars["String"]["output"]>;
};

export type Comment = {
  __typename?: "Comment";
  createdAt: Scalars["DateTime"]["output"];
  createdBy: User;
  id: Scalars["ID"]["output"];
  message: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: User;
};

export type CommentEntity = "account" | "gauge" | "tank";

export type CommentInput = {
  entityId?: InputMaybe<Scalars["ID"]["input"]>;
  entityType?: InputMaybe<CommentEntity>;
  message?: InputMaybe<Scalars["String"]["input"]>;
};

export type CommentList = {
  __typename?: "CommentList";
  comments: Array<Comment>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type ControlSetting = {
  __typename?: "ControlSetting";
  bundAlarm: Scalars["Boolean"]["output"];
  crcCheck: Scalars["Boolean"]["output"];
  network?: Maybe<Scalars["String"]["output"]>;
  verboseLogging: Scalars["Boolean"]["output"];
};

export type Customer = {
  __typename?: "Customer";
  accounts: AccountList;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: User;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: User;
};

export type CustomerAccountsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type CustomerInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type CustomerList = {
  __typename?: "CustomerList";
  customers: Array<Customer>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type DeliverySetting = {
  __typename?: "DeliverySetting";
  attempts: Scalars["Int"]["output"];
  period: Scalars["Int"]["output"];
};

export type Document = {
  __typename?: "Document";
  content?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: User;
  html?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  title: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: User;
};

export type DocumentInput = {
  content?: InputMaybe<Scalars["String"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type DocumentList = {
  __typename?: "DocumentList";
  documents: Array<Document>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type DynamicAlarmPolarity = "falling" | "rising";

export type DynamicAlarmSetting = {
  __typename?: "DynamicAlarmSetting";
  enabled: Scalars["Boolean"]["output"];
  polarity?: Maybe<DynamicAlarmPolarity>;
  rate?: Maybe<Scalars["Int"]["output"]>;
};

export type FairbanksStockLevel = {
  __typename?: "FairbanksStockLevel";
  height?: Maybe<Scalars["Float"]["output"]>;
  ibank?: Maybe<Scalars["String"]["output"]>;
  tank?: Maybe<Scalars["String"]["output"]>;
  temperature?: Maybe<Scalars["Float"]["output"]>;
  time?: Maybe<Scalars["String"]["output"]>;
  volume?: Maybe<Scalars["Float"]["output"]>;
  water?: Maybe<Scalars["Float"]["output"]>;
};

export type FairbanksTank = {
  __typename?: "FairbanksTank";
  id: Scalars["String"]["output"];
  items: Array<FairbanksStockLevel>;
};

export type FairbanksUserList = {
  __typename?: "FairbanksUserList";
  next?: Maybe<Scalars["String"]["output"]>;
  users: Array<Scalars["String"]["output"]>;
};

export type Gauge = {
  __typename?: "Gauge";
  account?: Maybe<Account>;
  config: GaugeConfig;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: User;
  customer?: Maybe<Customer>;
  deactivatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  detail: GaugeDetail;
  id: Scalars["ID"]["output"];
  model?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  observations: ObservationList;
  product: GaugeProduct;
  responses: GaugeResponseList;
  seenAt?: Maybe<Scalars["DateTime"]["output"]>;
  setting?: Maybe<GaugeSetting>;
  strappingTable: Array<StrapRow>;
  tank?: Maybe<Tank>;
  technology: GaugeTechnology;
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: User;
};

export type GaugeObservationsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type GaugeResponsesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type GaugeConfig =
  | CippusConfig
  | TekAdcConfig
  | TekUltrasonicConfig
  | UnregisteredConfig;

export type GaugeDetail =
  | CippusDetail
  | TekAdcDetail
  | TekUltrasonicDetail
  | UnregisteredDetail;

export type GaugeInput = {
  accountId?: InputMaybe<Scalars["ID"]["input"]>;
  config?: InputMaybe<Scalars["JSONObject"]["input"]>;
  customerId?: InputMaybe<Scalars["ID"]["input"]>;
  model?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  product?: InputMaybe<GaugeProduct>;
  strappingTable?: InputMaybe<Array<StrapRowInput>>;
  technology?: InputMaybe<GaugeTechnology>;
};

export type GaugeList = {
  __typename?: "GaugeList";
  gauges: Array<Gauge>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type GaugeProduct =
  | "Cippus"
  | "TekADC"
  | "TekUltrasonic"
  | "Unregistered";

export type GaugeResponse = {
  __typename?: "GaugeResponse";
  id: Scalars["ID"]["output"];
  items: Scalars["String"]["output"];
  product: GaugeResponseProduct;
  settings: GaugeResponseSettings;
  timezone: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
};

export type GaugeResponseList = {
  __typename?: "GaugeResponseList";
  next?: Maybe<Scalars["String"]["output"]>;
  responses: Array<GaugeResponse>;
};

export type GaugeResponseProduct = "TekADC" | "TekUltrasonic";

export type GaugeResponseSettings =
  | TekAdcResponseSettings
  | TekUltrasonicResponseSettings;

export type GaugeSetting = {
  __typename?: "GaugeSetting";
  active: Scalars["Boolean"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  createdBy: User;
  id: Scalars["ID"]["output"];
  product: GaugeSettingProduct;
  settings: GaugeSettings;
  timezone: Scalars["String"]["output"];
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: User;
};

export type GaugeSettingInput = {
  active?: InputMaybe<Scalars["Boolean"]["input"]>;
  settings?: InputMaybe<Scalars["JSONObject"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type GaugeSettingProduct = "TekADC" | "TekUltrasonic";

export type GaugeSettings = TekAdcSettings | TekUltrasonicSettings;

export type GaugeTechnology =
  | "ADC"
  | "Pressure"
  | "Temperature"
  | "TemperatureAndPressure"
  | "Ultrasonic"
  | "Unknown";

export type Invite = {
  __typename?: "Invite";
  createdAt: Scalars["DateTime"]["output"];
  email: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  jlteam?: Maybe<TeamPermission>;
  updatedAt: Scalars["DateTime"]["output"];
};

export type InviteList = {
  __typename?: "InviteList";
  invites: Array<Invite>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type JoyGaugeConfig = {
  __typename?: "JoyGaugeConfig";
  gauges: GaugeList;
};

export type JoyGaugeConfigGaugesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type Level = {
  __typename?: "Level";
  id: Scalars["ID"]["output"];
  maximum: Scalars["Float"]["output"];
  percent: Scalars["Float"]["output"];
  rssi?: Maybe<Scalars["Float"]["output"]>;
  signal?: Maybe<Scalars["Float"]["output"]>;
  src?: Maybe<Scalars["Float"]["output"]>;
  temperature?: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  value: Scalars["Float"]["output"];
  voltage?: Maybe<Scalars["Float"]["output"]>;
};

export type LevelList = {
  __typename?: "LevelList";
  levels: Array<Level>;
  next?: Maybe<Scalars["String"]["output"]>;
};

export type LoggerSetting = {
  __typename?: "LoggerSetting";
  frequency: Scalars["Int"]["output"];
  sampling: Scalars["Int"]["output"];
};

export type MeasurementSetting = "standard" | "waveguide";

export type Mutation = {
  __typename?: "Mutation";
  createAccount: Account;
  createComment: Comment;
  createCustomer: Customer;
  createDocument: Document;
  createGauge: Gauge;
  createPlan: Plan;
  createRole: Role;
  deleteAccount: Scalars["Boolean"]["output"];
  deleteComment: Scalars["Boolean"]["output"];
  deleteCustomer: Scalars["Boolean"]["output"];
  deleteDocument: Scalars["Boolean"]["output"];
  deleteGauge: Scalars["Boolean"]["output"];
  deletePlan: Scalars["Boolean"]["output"];
  deleteRole: Scalars["Boolean"]["output"];
  deleteUser: Scalars["Boolean"]["output"];
  inviteUser?: Maybe<Invite>;
  updateAccount: Account;
  updateComment: Comment;
  updateCustomer: Customer;
  updateDocument: Document;
  updateGauge: Gauge;
  updateGaugeSetting: GaugeSetting;
  updatePlan: Plan;
  updateRole: Role;
  updateTank: Tank;
  updateUser?: Maybe<User>;
  verifyAccount: Scalars["Boolean"]["output"];
  verifyGauge: Scalars["Boolean"]["output"];
};

export type MutationCreateAccountArgs = {
  fields: AccountInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateCommentArgs = {
  fields: CommentInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateCustomerArgs = {
  fields: CustomerInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateDocumentArgs = {
  fields: DocumentInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateGaugeArgs = {
  fields: GaugeInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreatePlanArgs = {
  fields: PlanInput;
  id: Scalars["ID"]["input"];
};

export type MutationCreateRoleArgs = {
  fields: RoleInput;
  id: Scalars["ID"]["input"];
};

export type MutationDeleteAccountArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCommentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCustomerArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteDocumentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteGaugeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeletePlanArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteRoleArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteUserArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationInviteUserArgs = {
  email: Scalars["String"]["input"];
  jlteam?: InputMaybe<TeamPermission>;
};

export type MutationUpdateAccountArgs = {
  fields: AccountInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCommentArgs = {
  fields: CommentInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCustomerArgs = {
  fields: CustomerInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateDocumentArgs = {
  fields: DocumentInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateGaugeArgs = {
  fields: GaugeInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateGaugeSettingArgs = {
  fields: GaugeSettingInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdatePlanArgs = {
  fields: PlanInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateRoleArgs = {
  id: Scalars["ID"]["input"];
  permissions: Array<RolePermission>;
};

export type MutationUpdateTankArgs = {
  fields: TankInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateUserArgs = {
  fields: UserInput;
  id: Scalars["ID"]["input"];
};

export type MutationVerifyAccountArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationVerifyGaugeArgs = {
  id: Scalars["ID"]["input"];
};

export type NetworkSetting = {
  __typename?: "NetworkSetting";
  band: Scalars["String"]["output"];
  operator: Scalars["String"]["output"];
};

export type Observation = {
  __typename?: "Observation";
  id: Scalars["ID"]["output"];
  reading: Scalars["Float"]["output"];
  rssi?: Maybe<Scalars["Float"]["output"]>;
  signal?: Maybe<Scalars["Float"]["output"]>;
  sleep?: Maybe<Scalars["Float"]["output"]>;
  src?: Maybe<Scalars["Float"]["output"]>;
  temperature?: Maybe<Scalars["Float"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  voltage?: Maybe<Scalars["Float"]["output"]>;
};

export type ObservationList = {
  __typename?: "ObservationList";
  next?: Maybe<Scalars["String"]["output"]>;
  observations: Array<Observation>;
};

export type Plan = {
  __typename?: "Plan";
  accounts: AccountList;
  amount: Scalars["Int"]["output"];
  code: Scalars["String"]["output"];
  createdAt: Scalars["DateTime"]["output"];
  createdBy: User;
  id: Scalars["ID"]["output"];
  model: PlanModel;
  prepaid?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: User;
};

export type PlanAccountsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type PlanInput = {
  amount?: InputMaybe<Scalars["Int"]["input"]>;
  code?: InputMaybe<Scalars["String"]["input"]>;
  model?: InputMaybe<PlanModel>;
  prepaid?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PlanList = {
  __typename?: "PlanList";
  next?: Maybe<Scalars["String"]["output"]>;
  plans: Array<Plan>;
};

export type PlanModel = "account" | "tank";

export type ProGaugeConfig = {
  __typename?: "ProGaugeConfig";
  maximum?: Maybe<Scalars["JSONObject"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  userId?: Maybe<Scalars["String"]["output"]>;
};

export type QualitySetting = {
  __typename?: "QualitySetting";
  rssi: Scalars["Int"]["output"];
  src: Scalars["Int"]["output"];
};

export type Query = {
  __typename?: "Query";
  account?: Maybe<Account>;
  accountAccess: Array<RolePermission>;
  accounts: AccountList;
  comments: CommentList;
  customer?: Maybe<Customer>;
  customers: CustomerList;
  document?: Maybe<Document>;
  documents: DocumentList;
  fairbanksTanks: Array<FairbanksTank>;
  fairbanksUsers: FairbanksUserList;
  gauge?: Maybe<Gauge>;
  gaugeAccess: Array<RolePermission>;
  gauges: GaugeList;
  invites: InviteList;
  plan?: Maybe<Plan>;
  plans: PlanList;
  role?: Maybe<Role>;
  roles: RoleList;
  tank?: Maybe<Tank>;
  tankAccess: Array<RolePermission>;
  tankRoles: RoleList;
  tanks: TankList;
  user: User;
  userRoles: RoleList;
  users: UserList;
};

export type QueryAccountArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAccountAccessArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryAccountsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryCommentsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  entityId: Scalars["ID"]["input"];
  entityType: CommentEntity;
  limit: Scalars["Int"]["input"];
};

export type QueryCustomerArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryCustomersArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryDocumentArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryDocumentsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryFairbanksTanksArgs = {
  userId: Scalars["String"]["input"];
};

export type QueryFairbanksUsersArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryGaugeArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGaugeAccessArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGaugesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryInvitesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryPlanArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryPlansArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryRoleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryRolesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  entityId: Scalars["ID"]["input"];
  entityType: RoleEntity;
  limit: Scalars["Int"]["input"];
};

export type QueryTankArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTankAccessArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryTankRolesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  tankId: Scalars["ID"]["input"];
};

export type QueryTanksArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type QueryUserArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryUserRolesArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryUsersArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
};

export type RmsDeltaConfig = {
  __typename?: "RMSDeltaConfig";
  serialNumbers?: Maybe<Array<Scalars["String"]["output"]>>;
};

export type Range = {
  __typename?: "Range";
  max: Scalars["Float"]["output"];
  min: Scalars["Float"]["output"];
};

export type Role = {
  __typename?: "Role";
  account?: Maybe<Account>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: User;
  customer?: Maybe<Customer>;
  entityId: Scalars["ID"]["output"];
  entityType: RoleEntity;
  id: Scalars["ID"]["output"];
  permissions: Array<RolePermission>;
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: User;
  user: User;
};

export type RoleEntity = "account" | "customer";

export type RoleInput = {
  entityId: Scalars["ID"]["input"];
  entityType: RoleEntity;
  permissions: Array<RolePermission>;
  userEmail: Scalars["String"]["input"];
};

export type RoleList = {
  __typename?: "RoleList";
  next?: Maybe<Scalars["String"]["output"]>;
  roles: Array<Role>;
};

export type RolePermission = "admin" | "install" | "read" | "write";

export type ScheduleDays = {
  __typename?: "ScheduleDays";
  Friday: Scalars["Boolean"]["output"];
  Monday: Scalars["Boolean"]["output"];
  Saturday: Scalars["Boolean"]["output"];
  Sunday: Scalars["Boolean"]["output"];
  Thursday: Scalars["Boolean"]["output"];
  Tuesday: Scalars["Boolean"]["output"];
  Wednesday: Scalars["Boolean"]["output"];
};

export type ScheduleEvery = "Fortnight" | "Month" | "Week";

export type ScheduleSetting = {
  __typename?: "ScheduleSetting";
  days: ScheduleDays;
  endTime?: Maybe<Scalars["Int"]["output"]>;
  every: ScheduleEvery;
  frequency: Scalars["Int"]["output"];
  startTime: ScheduleStartTime;
};

export type ScheduleStartTime = {
  __typename?: "ScheduleStartTime";
  hour: Scalars["Int"]["output"];
  minute: Scalars["Int"]["output"];
};

export type ServerSetting = {
  __typename?: "ServerSetting";
  host: Scalars["String"]["output"];
  port: Scalars["Int"]["output"];
};

export type SmartFillConfig = {
  __typename?: "SmartFillConfig";
  reference?: Maybe<Scalars["String"]["output"]>;
  secret?: Maybe<Scalars["String"]["output"]>;
};

export type StaticAlarmPolarity = "higher" | "lower";

export type StaticAlarmSetting = {
  __typename?: "StaticAlarmSetting";
  enabled: Scalars["Boolean"]["output"];
  error?: Maybe<Scalars["Int"]["output"]>;
  polarity?: Maybe<StaticAlarmPolarity>;
  threshold?: Maybe<Scalars["Int"]["output"]>;
};

export type StrapRow = {
  __typename?: "StrapRow";
  l: Scalars["Float"]["output"];
  mm: Scalars["Float"]["output"];
};

export type StrapRowInput = {
  l: Scalars["Float"]["input"];
  mm: Scalars["Float"]["input"];
};

export type Tank = {
  __typename?: "Tank";
  account: Account;
  activatedAt?: Maybe<Scalars["DateTime"]["output"]>;
  contents?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  createdBy: User;
  deliveryThreshold?: Maybe<Scalars["Int"]["output"]>;
  gauge?: Maybe<Gauge>;
  id: Scalars["ID"]["output"];
  levels: LevelList;
  location?: Maybe<TankLocation>;
  name: Scalars["String"]["output"];
  notificationEmails?: Maybe<Array<Scalars["String"]["output"]>>;
  unit?: Maybe<TankUnit>;
  updatedAt: Scalars["DateTime"]["output"];
  updatedBy: User;
  warningLevels?: Maybe<Array<Scalars["Int"]["output"]>>;
};

export type TankLevelsArgs = {
  cursor?: InputMaybe<Scalars["String"]["input"]>;
  limit: Scalars["Int"]["input"];
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TankInput = {
  activatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  contents?: InputMaybe<Scalars["String"]["input"]>;
  deliveryThreshold?: InputMaybe<Scalars["Int"]["input"]>;
  location?: InputMaybe<TankLocationInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  notificationEmails?: InputMaybe<Array<Scalars["String"]["input"]>>;
  unit?: InputMaybe<TankUnit>;
  warningLevels?: InputMaybe<Array<Scalars["Int"]["input"]>>;
};

export type TankList = {
  __typename?: "TankList";
  next?: Maybe<Scalars["String"]["output"]>;
  tanks: Array<Tank>;
};

export type TankLocation = {
  __typename?: "TankLocation";
  display: Scalars["String"]["output"];
  latitude: Scalars["Float"]["output"];
  longitude: Scalars["Float"]["output"];
};

export type TankLocationInput = {
  display: Scalars["String"]["input"];
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
};

export type TankUnit = "gal" | "l";

export type TeamPermission = "admin" | "god" | "super";

export type TekAdcConfig = {
  __typename?: "TekADCConfig";
  offsetHeight: Scalars["Float"]["output"];
  offsetVolume: Scalars["Float"]["output"];
  rangeAnalog: Range;
  rangeDigital: Range;
  rangeHeight: Range;
  rangeProbe: Range;
  specificGravityActual: Scalars["Float"]["output"];
  specificGravityProbe: Scalars["Float"]["output"];
};

export type TekAdcDetail = {
  __typename?: "TekADCDetail";
  firmware: Version;
  hardware: Version;
  model: Scalars["String"]["output"];
};

export type TekAdcResponseSettings = {
  __typename?: "TekADCResponseSettings";
  apn?: Maybe<ApnSetting>;
  battery?: Maybe<Scalars["Int"]["output"]>;
  control?: Maybe<ControlSetting>;
  delay?: Maybe<Scalars["Int"]["output"]>;
  delivery?: Maybe<DeliverySetting>;
  dynamicAlarms?: Maybe<Array<DynamicAlarmSetting>>;
  eStop?: Maybe<Scalars["Int"]["output"]>;
  fStop?: Maybe<Scalars["Int"]["output"]>;
  fallbackPhone?: Maybe<Scalars["String"]["output"]>;
  listen?: Maybe<Scalars["Int"]["output"]>;
  logger?: Maybe<LoggerSetting>;
  network?: Maybe<NetworkSetting>;
  password?: Maybe<Scalars["String"]["output"]>;
  schedule?: Maybe<ScheduleSetting>;
  server?: Maybe<ServerSetting>;
  staticAlarms?: Maybe<Array<StaticAlarmSetting>>;
};

export type TekAdcSettings = {
  __typename?: "TekADCSettings";
  dynamicAlarms?: Maybe<Array<DynamicAlarmSetting>>;
  password?: Maybe<Scalars["String"]["output"]>;
  schedule?: Maybe<ScheduleSetting>;
  staticAlarms?: Maybe<Array<StaticAlarmSetting>>;
};

export type TekUltrasonicConfig = {
  __typename?: "TekUltrasonicConfig";
  mountToBottom: Scalars["Float"]["output"];
  offsetVolume: Scalars["Float"]["output"];
};

export type TekUltrasonicDetail = {
  __typename?: "TekUltrasonicDetail";
  firmware: Version;
  hardware: Version;
  model: Scalars["String"]["output"];
};

export type TekUltrasonicResponseSettings = {
  __typename?: "TekUltrasonicResponseSettings";
  apn?: Maybe<ApnSetting>;
  control?: Maybe<ControlSetting>;
  delay?: Maybe<Scalars["Int"]["output"]>;
  delivery?: Maybe<DeliverySetting>;
  dynamicAlarms?: Maybe<Array<DynamicAlarmSetting>>;
  fallbackPhone?: Maybe<Scalars["String"]["output"]>;
  height?: Maybe<Scalars["Int"]["output"]>;
  listen?: Maybe<Scalars["Int"]["output"]>;
  logger?: Maybe<LoggerSetting>;
  measurement?: Maybe<MeasurementSetting>;
  network?: Maybe<NetworkSetting>;
  password?: Maybe<Scalars["String"]["output"]>;
  quality?: Maybe<QualitySetting>;
  schedule?: Maybe<ScheduleSetting>;
  server?: Maybe<ServerSetting>;
  staticAlarms?: Maybe<Array<StaticAlarmSetting>>;
};

export type TekUltrasonicSettings = {
  __typename?: "TekUltrasonicSettings";
  dynamicAlarms?: Maybe<Array<DynamicAlarmSetting>>;
  measurement?: Maybe<MeasurementSetting>;
  password?: Maybe<Scalars["String"]["output"]>;
  quality?: Maybe<QualitySetting>;
  schedule?: Maybe<ScheduleSetting>;
  staticAlarms?: Maybe<Array<StaticAlarmSetting>>;
};

export type UnregisteredConfig = {
  __typename?: "UnregisteredConfig";
  config?: Maybe<Scalars["Boolean"]["output"]>;
};

export type UnregisteredDetail = {
  __typename?: "UnregisteredDetail";
  detail?: Maybe<Scalars["Boolean"]["output"]>;
};

export type User = {
  __typename?: "User";
  company?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["DateTime"]["output"];
  email: Scalars["String"]["output"];
  firstName?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  identities?: Maybe<Array<Scalars["String"]["output"]>>;
  jlteam?: Maybe<TeamPermission>;
  lastActiveAt?: Maybe<Scalars["DateTime"]["output"]>;
  lastName?: Maybe<Scalars["String"]["output"]>;
  phone?: Maybe<Scalars["String"]["output"]>;
  picture?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type UserInput = {
  company?: InputMaybe<Scalars["String"]["input"]>;
  firstName?: InputMaybe<Scalars["String"]["input"]>;
  jlteam?: InputMaybe<TeamPermission>;
  lastName?: InputMaybe<Scalars["String"]["input"]>;
  phone?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserList = {
  __typename?: "UserList";
  next?: Maybe<Scalars["String"]["output"]>;
  users: Array<User>;
};

export type Version = {
  __typename?: "Version";
  major: Scalars["Int"]["output"];
  minor: Scalars["Int"]["output"];
};

export type CreateAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: AccountInput;
}>;

export type CreateAccountMutation = {
  __typename?: "Mutation";
  createAccount: {
    __typename?: "Account";
    id: string;
    name: string;
    product: AccountProduct;
    createdAt: Date | string;
    updatedAt: Date | string;
    customer?: {
      __typename?: "Customer";
      id: string;
      name: string;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    plan?: {
      __typename?: "Plan";
      id: string;
      code: string;
      amount: number;
      prepaid?: number | null;
      model: PlanModel;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    config?:
      | { __typename: "ColibriConfig"; url?: string | null }
      | {
          __typename: "JoyGaugeConfig";
          gauges: {
            __typename?: "GaugeList";
            gauges: Array<{
              __typename?: "Gauge";
              id: string;
              name: string;
              technology: GaugeTechnology;
              model?: string | null;
            }>;
          };
        }
      | {
          __typename: "ProGaugeConfig";
          userId?: string | null;
          timezone?: string | null;
          maximum?: Record<string, any> | null;
        }
      | { __typename: "RMSDeltaConfig"; serialNumbers?: Array<string> | null }
      | {
          __typename: "SmartFillConfig";
          reference?: string | null;
          secret?: string | null;
        }
      | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeleteAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteAccountMutation = {
  __typename?: "Mutation";
  deleteAccount: boolean;
};

export type AccountFragmentFragment = {
  __typename?: "Account";
  id: string;
  name: string;
  product: AccountProduct;
  createdAt: Date | string;
  updatedAt: Date | string;
  config?:
    | { __typename: "ColibriConfig"; url?: string | null }
    | {
        __typename: "JoyGaugeConfig";
        gauges: {
          __typename?: "GaugeList";
          gauges: Array<{
            __typename?: "Gauge";
            id: string;
            name: string;
            technology: GaugeTechnology;
            model?: string | null;
          }>;
        };
      }
    | {
        __typename: "ProGaugeConfig";
        userId?: string | null;
        timezone?: string | null;
        maximum?: Record<string, any> | null;
      }
    | { __typename: "RMSDeltaConfig"; serialNumbers?: Array<string> | null }
    | {
        __typename: "SmartFillConfig";
        reference?: string | null;
        secret?: string | null;
      }
    | null;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type AccountQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type AccountQuery = {
  __typename?: "Query";
  accountAccess: Array<RolePermission>;
  account?: {
    __typename?: "Account";
    id: string;
    name: string;
    product: AccountProduct;
    createdAt: Date | string;
    updatedAt: Date | string;
    customer?: {
      __typename?: "Customer";
      id: string;
      name: string;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    plan?: {
      __typename?: "Plan";
      id: string;
      code: string;
      amount: number;
      prepaid?: number | null;
      model: PlanModel;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    config?:
      | { __typename: "ColibriConfig"; url?: string | null }
      | {
          __typename: "JoyGaugeConfig";
          gauges: {
            __typename?: "GaugeList";
            gauges: Array<{
              __typename?: "Gauge";
              id: string;
              name: string;
              technology: GaugeTechnology;
              model?: string | null;
            }>;
          };
        }
      | {
          __typename: "ProGaugeConfig";
          userId?: string | null;
          timezone?: string | null;
          maximum?: Record<string, any> | null;
        }
      | { __typename: "RMSDeltaConfig"; serialNumbers?: Array<string> | null }
      | {
          __typename: "SmartFillConfig";
          reference?: string | null;
          secret?: string | null;
        }
      | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type AccountsQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AccountsQuery = {
  __typename?: "Query";
  accounts: {
    __typename?: "AccountList";
    next?: string | null;
    accounts: Array<{
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
      createdAt: Date | string;
      updatedAt: Date | string;
      customer?: {
        __typename?: "Customer";
        id: string;
        name: string;
        createdAt: Date | string;
        updatedAt: Date | string;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      } | null;
      plan?: {
        __typename?: "Plan";
        id: string;
        code: string;
        amount: number;
        prepaid?: number | null;
        model: PlanModel;
        createdAt: Date | string;
        updatedAt: Date | string;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      } | null;
      config?:
        | { __typename: "ColibriConfig"; url?: string | null }
        | {
            __typename: "JoyGaugeConfig";
            gauges: {
              __typename?: "GaugeList";
              gauges: Array<{
                __typename?: "Gauge";
                id: string;
                name: string;
                technology: GaugeTechnology;
                model?: string | null;
              }>;
            };
          }
        | {
            __typename: "ProGaugeConfig";
            userId?: string | null;
            timezone?: string | null;
            maximum?: Record<string, any> | null;
          }
        | { __typename: "RMSDeltaConfig"; serialNumbers?: Array<string> | null }
        | {
            __typename: "SmartFillConfig";
            reference?: string | null;
            secret?: string | null;
          }
        | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type AccountTanksQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type AccountTanksQuery = {
  __typename?: "Query";
  account?: {
    __typename?: "Account";
    tanks: {
      __typename?: "TankList";
      next?: string | null;
      tanks: Array<{
        __typename?: "Tank";
        id: string;
        name: string;
        unit?: TankUnit | null;
        contents?: string | null;
        activatedAt?: Date | string | null;
        warningLevels?: Array<number> | null;
        deliveryThreshold?: number | null;
        notificationEmails?: Array<string> | null;
        createdAt: Date | string;
        updatedAt: Date | string;
        levels: {
          __typename?: "LevelList";
          levels: Array<{
            __typename?: "Level";
            id: string;
            value: number;
            maximum: number;
            percent: number;
            signal?: number | null;
            temperature?: number | null;
            voltage?: number | null;
            rssi?: number | null;
            src?: number | null;
            updatedAt: Date | string;
          }>;
        };
        location?: {
          __typename?: "TankLocation";
          display: string;
          latitude: number;
          longitude: number;
        } | null;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      }>;
    };
  } | null;
};

export type UpdateAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: AccountInput;
}>;

export type UpdateAccountMutation = {
  __typename?: "Mutation";
  updateAccount: {
    __typename?: "Account";
    id: string;
    name: string;
    product: AccountProduct;
    createdAt: Date | string;
    updatedAt: Date | string;
    customer?: {
      __typename?: "Customer";
      id: string;
      name: string;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    plan?: {
      __typename?: "Plan";
      id: string;
      code: string;
      amount: number;
      prepaid?: number | null;
      model: PlanModel;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    config?:
      | { __typename: "ColibriConfig"; url?: string | null }
      | {
          __typename: "JoyGaugeConfig";
          gauges: {
            __typename?: "GaugeList";
            gauges: Array<{
              __typename?: "Gauge";
              id: string;
              name: string;
              technology: GaugeTechnology;
              model?: string | null;
            }>;
          };
        }
      | {
          __typename: "ProGaugeConfig";
          userId?: string | null;
          timezone?: string | null;
          maximum?: Record<string, any> | null;
        }
      | { __typename: "RMSDeltaConfig"; serialNumbers?: Array<string> | null }
      | {
          __typename: "SmartFillConfig";
          reference?: string | null;
          secret?: string | null;
        }
      | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type VerifyAccountMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type VerifyAccountMutation = {
  __typename?: "Mutation";
  verifyAccount: boolean;
};

export type CreateCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: CommentInput;
}>;

export type CreateCommentMutation = {
  __typename?: "Mutation";
  createComment: {
    __typename?: "Comment";
    id: string;
    message: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    createdBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeleteCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteCommentMutation = {
  __typename?: "Mutation";
  deleteComment: boolean;
};

export type CommentFragmentFragment = {
  __typename?: "Comment";
  id: string;
  message: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  createdBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type CommentsQueryVariables = Exact<{
  entityId: Scalars["ID"]["input"];
  entityType: CommentEntity;
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CommentsQuery = {
  __typename?: "Query";
  comments: {
    __typename?: "CommentList";
    next?: string | null;
    comments: Array<{
      __typename?: "Comment";
      id: string;
      message: string;
      createdAt: Date | string;
      updatedAt: Date | string;
      createdBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdateCommentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: CommentInput;
}>;

export type UpdateCommentMutation = {
  __typename?: "Mutation";
  updateComment: {
    __typename?: "Comment";
    id: string;
    message: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    createdBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type CustomerAccountsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CustomerAccountsQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    accounts: {
      __typename?: "AccountList";
      next?: string | null;
      accounts: Array<{
        __typename?: "Account";
        id: string;
        name: string;
        product: AccountProduct;
        createdAt: Date | string;
        updatedAt: Date | string;
        customer?: {
          __typename?: "Customer";
          id: string;
          name: string;
          createdAt: Date | string;
          updatedAt: Date | string;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        plan?: {
          __typename?: "Plan";
          id: string;
          code: string;
          amount: number;
          prepaid?: number | null;
          model: PlanModel;
          createdAt: Date | string;
          updatedAt: Date | string;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        config?:
          | { __typename: "ColibriConfig"; url?: string | null }
          | {
              __typename: "JoyGaugeConfig";
              gauges: {
                __typename?: "GaugeList";
                gauges: Array<{
                  __typename?: "Gauge";
                  id: string;
                  name: string;
                  technology: GaugeTechnology;
                  model?: string | null;
                }>;
              };
            }
          | {
              __typename: "ProGaugeConfig";
              userId?: string | null;
              timezone?: string | null;
              maximum?: Record<string, any> | null;
            }
          | {
              __typename: "RMSDeltaConfig";
              serialNumbers?: Array<string> | null;
            }
          | {
              __typename: "SmartFillConfig";
              reference?: string | null;
              secret?: string | null;
            }
          | null;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      }>;
    };
  } | null;
};

export type CreateCustomerMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: CustomerInput;
}>;

export type CreateCustomerMutation = {
  __typename?: "Mutation";
  createCustomer: {
    __typename?: "Customer";
    id: string;
    name: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeleteCustomerMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteCustomerMutation = {
  __typename?: "Mutation";
  deleteCustomer: boolean;
};

export type CustomerFragmentFragment = {
  __typename?: "Customer";
  id: string;
  name: string;
  createdAt: Date | string;
  updatedAt: Date | string;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type CustomerQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type CustomerQuery = {
  __typename?: "Query";
  customer?: {
    __typename?: "Customer";
    id: string;
    name: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type CustomersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type CustomersQuery = {
  __typename?: "Query";
  customers: {
    __typename?: "CustomerList";
    next?: string | null;
    customers: Array<{
      __typename?: "Customer";
      id: string;
      name: string;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdateCustomerMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: CustomerInput;
}>;

export type UpdateCustomerMutation = {
  __typename?: "Mutation";
  updateCustomer: {
    __typename?: "Customer";
    id: string;
    name: string;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type CreateDocumentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: DocumentInput;
}>;

export type CreateDocumentMutation = {
  __typename?: "Mutation";
  createDocument: {
    __typename?: "Document";
    id: string;
    title: string;
    content?: string | null;
    html?: string | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeleteDocumentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteDocumentMutation = {
  __typename?: "Mutation";
  deleteDocument: boolean;
};

export type DocumentFragmentFragment = {
  __typename?: "Document";
  id: string;
  title: string;
  content?: string | null;
  html?: string | null;
  createdAt: Date | string;
  updatedAt: Date | string;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type DocumentQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DocumentQuery = {
  __typename?: "Query";
  document?: {
    __typename?: "Document";
    id: string;
    title: string;
    content?: string | null;
    html?: string | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type DocumentsQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type DocumentsQuery = {
  __typename?: "Query";
  documents: {
    __typename?: "DocumentList";
    next?: string | null;
    documents: Array<{
      __typename?: "Document";
      id: string;
      title: string;
      content?: string | null;
      html?: string | null;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdateDocumentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: DocumentInput;
}>;

export type UpdateDocumentMutation = {
  __typename?: "Mutation";
  updateDocument: {
    __typename?: "Document";
    id: string;
    title: string;
    content?: string | null;
    html?: string | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type FairbanksTanksQueryVariables = Exact<{
  userId: Scalars["String"]["input"];
}>;

export type FairbanksTanksQuery = {
  __typename?: "Query";
  fairbanksTanks: Array<{
    __typename?: "FairbanksTank";
    id: string;
    items: Array<{
      __typename?: "FairbanksStockLevel";
      ibank?: string | null;
      time?: string | null;
      tank?: string | null;
      volume?: number | null;
      temperature?: number | null;
      water?: number | null;
      height?: number | null;
    }>;
  }>;
};

export type FairbanksUsersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type FairbanksUsersQuery = {
  __typename?: "Query";
  fairbanksUsers: {
    __typename?: "FairbanksUserList";
    next?: string | null;
    users: Array<string>;
  };
};

export type CreateGaugeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: GaugeInput;
}>;

export type CreateGaugeMutation = {
  __typename?: "Mutation";
  createGauge: {
    __typename?: "Gauge";
    id: string;
    name: string;
    model?: string | null;
    product: GaugeProduct;
    technology: GaugeTechnology;
    seenAt?: Date | string | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    observations: {
      __typename?: "ObservationList";
      observations: Array<{
        __typename?: "Observation";
        id: string;
        reading: number;
        temperature?: number | null;
        voltage?: number | null;
        sleep?: number | null;
        signal?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
    tank?: { __typename?: "Tank"; id: string; name: string } | null;
    account?: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
    } | null;
    customer?: { __typename?: "Customer"; id: string; name: string } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    strappingTable: Array<{ __typename?: "StrapRow"; mm: number; l: number }>;
    detail:
      | { __typename: "CippusDetail"; connection: string; range: string }
      | {
          __typename: "TekADCDetail";
          model: string;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | {
          __typename: "TekUltrasonicDetail";
          model: string;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | { __typename: "UnregisteredDetail"; detail?: boolean | null };
    config:
      | {
          __typename: "CippusConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
        }
      | {
          __typename: "TekADCConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
          rangeDigital: { __typename?: "Range"; min: number; max: number };
          rangeAnalog: { __typename?: "Range"; min: number; max: number };
          rangeProbe: { __typename?: "Range"; min: number; max: number };
          rangeHeight: { __typename?: "Range"; min: number; max: number };
        }
      | {
          __typename: "TekUltrasonicConfig";
          mountToBottom: number;
          offsetVolume: number;
        }
      | { __typename: "UnregisteredConfig"; config?: boolean | null };
  };
};

export type DeleteGaugeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteGaugeMutation = {
  __typename?: "Mutation";
  deleteGauge: boolean;
};

export type GaugeDetailFragment = {
  __typename?: "Gauge";
  strappingTable: Array<{ __typename?: "StrapRow"; mm: number; l: number }>;
  detail:
    | { __typename: "CippusDetail"; connection: string; range: string }
    | {
        __typename: "TekADCDetail";
        model: string;
        hardware: { __typename?: "Version"; major: number; minor: number };
        firmware: { __typename?: "Version"; major: number; minor: number };
      }
    | {
        __typename: "TekUltrasonicDetail";
        model: string;
        hardware: { __typename?: "Version"; major: number; minor: number };
        firmware: { __typename?: "Version"; major: number; minor: number };
      }
    | { __typename: "UnregisteredDetail"; detail?: boolean | null };
  config:
    | {
        __typename: "CippusConfig";
        offsetHeight: number;
        offsetVolume: number;
        specificGravityProbe: number;
        specificGravityActual: number;
      }
    | {
        __typename: "TekADCConfig";
        offsetHeight: number;
        offsetVolume: number;
        specificGravityProbe: number;
        specificGravityActual: number;
        rangeDigital: { __typename?: "Range"; min: number; max: number };
        rangeAnalog: { __typename?: "Range"; min: number; max: number };
        rangeProbe: { __typename?: "Range"; min: number; max: number };
        rangeHeight: { __typename?: "Range"; min: number; max: number };
      }
    | {
        __typename: "TekUltrasonicConfig";
        mountToBottom: number;
        offsetVolume: number;
      }
    | { __typename: "UnregisteredConfig"; config?: boolean | null };
};

export type GaugeFragmentFragment = {
  __typename?: "Gauge";
  id: string;
  name: string;
  model?: string | null;
  product: GaugeProduct;
  technology: GaugeTechnology;
  seenAt?: Date | string | null;
  createdAt: Date | string;
  updatedAt: Date | string;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type GaugeResponseFragmentFragment = {
  __typename?: "GaugeResponse";
  id: string;
  timezone: string;
  items: string;
  product: GaugeResponseProduct;
  updatedAt: Date | string;
  settings:
    | {
        __typename: "TekADCResponseSettings";
        listen?: number | null;
        fallbackPhone?: string | null;
        password?: string | null;
        battery?: number | null;
        fStop?: number | null;
        eStop?: number | null;
        delay?: number | null;
        logger?: {
          __typename?: "LoggerSetting";
          frequency: number;
          sampling: number;
        } | null;
        schedule?: {
          __typename?: "ScheduleSetting";
          endTime?: number | null;
          frequency: number;
          every: ScheduleEvery;
          days: {
            __typename?: "ScheduleDays";
            Monday: boolean;
            Tuesday: boolean;
            Wednesday: boolean;
            Thursday: boolean;
            Friday: boolean;
            Saturday: boolean;
            Sunday: boolean;
          };
          startTime: {
            __typename?: "ScheduleStartTime";
            hour: number;
            minute: number;
          };
        } | null;
        control?: {
          __typename?: "ControlSetting";
          network?: string | null;
          bundAlarm: boolean;
          crcCheck: boolean;
          verboseLogging: boolean;
        } | null;
        staticAlarms?: Array<{
          __typename?: "StaticAlarmSetting";
          enabled: boolean;
          polarity?: StaticAlarmPolarity | null;
          threshold?: number | null;
          error?: number | null;
        }> | null;
        dynamicAlarms?: Array<{
          __typename?: "DynamicAlarmSetting";
          enabled: boolean;
          polarity?: DynamicAlarmPolarity | null;
          rate?: number | null;
        }> | null;
        apn?: {
          __typename?: "ApnSetting";
          gateway: string;
          username: string;
          password: string;
          authenticated: boolean;
        } | null;
        server?: {
          __typename?: "ServerSetting";
          host: string;
          port: number;
        } | null;
        network?: {
          __typename?: "NetworkSetting";
          operator: string;
          band: string;
        } | null;
        delivery?: {
          __typename?: "DeliverySetting";
          attempts: number;
          period: number;
        } | null;
      }
    | {
        __typename: "TekUltrasonicResponseSettings";
        listen?: number | null;
        fallbackPhone?: string | null;
        password?: string | null;
        measurement?: MeasurementSetting | null;
        height?: number | null;
        delay?: number | null;
        logger?: {
          __typename?: "LoggerSetting";
          frequency: number;
          sampling: number;
        } | null;
        schedule?: {
          __typename?: "ScheduleSetting";
          endTime?: number | null;
          frequency: number;
          every: ScheduleEvery;
          days: {
            __typename?: "ScheduleDays";
            Monday: boolean;
            Tuesday: boolean;
            Wednesday: boolean;
            Thursday: boolean;
            Friday: boolean;
            Saturday: boolean;
            Sunday: boolean;
          };
          startTime: {
            __typename?: "ScheduleStartTime";
            hour: number;
            minute: number;
          };
        } | null;
        control?: {
          __typename?: "ControlSetting";
          network?: string | null;
          bundAlarm: boolean;
          crcCheck: boolean;
          verboseLogging: boolean;
        } | null;
        staticAlarms?: Array<{
          __typename?: "StaticAlarmSetting";
          enabled: boolean;
          polarity?: StaticAlarmPolarity | null;
          threshold?: number | null;
          error?: number | null;
        }> | null;
        dynamicAlarms?: Array<{
          __typename?: "DynamicAlarmSetting";
          enabled: boolean;
          polarity?: DynamicAlarmPolarity | null;
          rate?: number | null;
        }> | null;
        apn?: {
          __typename?: "ApnSetting";
          gateway: string;
          username: string;
          password: string;
          authenticated: boolean;
        } | null;
        server?: {
          __typename?: "ServerSetting";
          host: string;
          port: number;
        } | null;
        network?: {
          __typename?: "NetworkSetting";
          operator: string;
          band: string;
        } | null;
        delivery?: {
          __typename?: "DeliverySetting";
          attempts: number;
          period: number;
        } | null;
        quality?: {
          __typename?: "QualitySetting";
          rssi: number;
          src: number;
        } | null;
      };
};

export type GaugeSettingFragmentFragment = {
  __typename?: "GaugeSetting";
  id: string;
  active: boolean;
  timezone: string;
  product: GaugeSettingProduct;
  updatedAt: Date | string;
  settings:
    | {
        __typename: "TekADCSettings";
        password?: string | null;
        schedule?: {
          __typename?: "ScheduleSetting";
          endTime?: number | null;
          frequency: number;
          every: ScheduleEvery;
          days: {
            __typename?: "ScheduleDays";
            Monday: boolean;
            Tuesday: boolean;
            Wednesday: boolean;
            Thursday: boolean;
            Friday: boolean;
            Saturday: boolean;
            Sunday: boolean;
          };
          startTime: {
            __typename?: "ScheduleStartTime";
            hour: number;
            minute: number;
          };
        } | null;
        staticAlarms?: Array<{
          __typename?: "StaticAlarmSetting";
          enabled: boolean;
          polarity?: StaticAlarmPolarity | null;
          threshold?: number | null;
          error?: number | null;
        }> | null;
        dynamicAlarms?: Array<{
          __typename?: "DynamicAlarmSetting";
          enabled: boolean;
          polarity?: DynamicAlarmPolarity | null;
          rate?: number | null;
        }> | null;
      }
    | {
        __typename: "TekUltrasonicSettings";
        password?: string | null;
        measurement?: MeasurementSetting | null;
        schedule?: {
          __typename?: "ScheduleSetting";
          endTime?: number | null;
          frequency: number;
          every: ScheduleEvery;
          days: {
            __typename?: "ScheduleDays";
            Monday: boolean;
            Tuesday: boolean;
            Wednesday: boolean;
            Thursday: boolean;
            Friday: boolean;
            Saturday: boolean;
            Sunday: boolean;
          };
          startTime: {
            __typename?: "ScheduleStartTime";
            hour: number;
            minute: number;
          };
        } | null;
        staticAlarms?: Array<{
          __typename?: "StaticAlarmSetting";
          enabled: boolean;
          polarity?: StaticAlarmPolarity | null;
          threshold?: number | null;
          error?: number | null;
        }> | null;
        dynamicAlarms?: Array<{
          __typename?: "DynamicAlarmSetting";
          enabled: boolean;
          polarity?: DynamicAlarmPolarity | null;
          rate?: number | null;
        }> | null;
        quality?: {
          __typename?: "QualitySetting";
          rssi: number;
          src: number;
        } | null;
      };
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type GaugeQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GaugeQuery = {
  __typename?: "Query";
  gaugeAccess: Array<RolePermission>;
  gauge?: {
    __typename?: "Gauge";
    id: string;
    name: string;
    model?: string | null;
    product: GaugeProduct;
    technology: GaugeTechnology;
    seenAt?: Date | string | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    observations: {
      __typename?: "ObservationList";
      observations: Array<{
        __typename?: "Observation";
        id: string;
        reading: number;
        temperature?: number | null;
        voltage?: number | null;
        sleep?: number | null;
        signal?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
    tank?: { __typename?: "Tank"; id: string; name: string } | null;
    account?: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
    } | null;
    customer?: { __typename?: "Customer"; id: string; name: string } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    strappingTable: Array<{ __typename?: "StrapRow"; mm: number; l: number }>;
    detail:
      | { __typename: "CippusDetail"; connection: string; range: string }
      | {
          __typename: "TekADCDetail";
          model: string;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | {
          __typename: "TekUltrasonicDetail";
          model: string;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | { __typename: "UnregisteredDetail"; detail?: boolean | null };
    config:
      | {
          __typename: "CippusConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
        }
      | {
          __typename: "TekADCConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
          rangeDigital: { __typename?: "Range"; min: number; max: number };
          rangeAnalog: { __typename?: "Range"; min: number; max: number };
          rangeProbe: { __typename?: "Range"; min: number; max: number };
          rangeHeight: { __typename?: "Range"; min: number; max: number };
        }
      | {
          __typename: "TekUltrasonicConfig";
          mountToBottom: number;
          offsetVolume: number;
        }
      | { __typename: "UnregisteredConfig"; config?: boolean | null };
  } | null;
};

export type GaugesQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GaugesQuery = {
  __typename?: "Query";
  gauges: {
    __typename?: "GaugeList";
    next?: string | null;
    gauges: Array<{
      __typename?: "Gauge";
      id: string;
      name: string;
      model?: string | null;
      product: GaugeProduct;
      technology: GaugeTechnology;
      seenAt?: Date | string | null;
      createdAt: Date | string;
      updatedAt: Date | string;
      observations: {
        __typename?: "ObservationList";
        observations: Array<{
          __typename?: "Observation";
          id: string;
          reading: number;
          temperature?: number | null;
          voltage?: number | null;
          sleep?: number | null;
          signal?: number | null;
          rssi?: number | null;
          src?: number | null;
          updatedAt: Date | string;
        }>;
      };
      account?: {
        __typename?: "Account";
        id: string;
        name: string;
        product: AccountProduct;
      } | null;
      customer?: { __typename?: "Customer"; id: string; name: string } | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
      strappingTable: Array<{ __typename?: "StrapRow"; mm: number; l: number }>;
      detail:
        | { __typename: "CippusDetail"; connection: string; range: string }
        | {
            __typename: "TekADCDetail";
            model: string;
            hardware: { __typename?: "Version"; major: number; minor: number };
            firmware: { __typename?: "Version"; major: number; minor: number };
          }
        | {
            __typename: "TekUltrasonicDetail";
            model: string;
            hardware: { __typename?: "Version"; major: number; minor: number };
            firmware: { __typename?: "Version"; major: number; minor: number };
          }
        | { __typename: "UnregisteredDetail"; detail?: boolean | null };
      config:
        | {
            __typename: "CippusConfig";
            offsetHeight: number;
            offsetVolume: number;
            specificGravityProbe: number;
            specificGravityActual: number;
          }
        | {
            __typename: "TekADCConfig";
            offsetHeight: number;
            offsetVolume: number;
            specificGravityProbe: number;
            specificGravityActual: number;
            rangeDigital: { __typename?: "Range"; min: number; max: number };
            rangeAnalog: { __typename?: "Range"; min: number; max: number };
            rangeProbe: { __typename?: "Range"; min: number; max: number };
            rangeHeight: { __typename?: "Range"; min: number; max: number };
          }
        | {
            __typename: "TekUltrasonicConfig";
            mountToBottom: number;
            offsetVolume: number;
          }
        | { __typename: "UnregisteredConfig"; config?: boolean | null };
    }>;
  };
};

export type GaugeObservationsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GaugeObservationsQuery = {
  __typename?: "Query";
  gauge?: {
    __typename?: "Gauge";
    observations: {
      __typename?: "ObservationList";
      next?: string | null;
      observations: Array<{
        __typename?: "Observation";
        id: string;
        reading: number;
        temperature?: number | null;
        voltage?: number | null;
        sleep?: number | null;
        signal?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
  } | null;
};

export type GaugeSettingsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GaugeSettingsQuery = {
  __typename?: "Query";
  gaugeAccess: Array<RolePermission>;
  gauge?: {
    __typename?: "Gauge";
    id: string;
    name: string;
    product: GaugeProduct;
    seenAt?: Date | string | null;
    deactivatedAt?: Date | string | null;
    setting?: {
      __typename?: "GaugeSetting";
      id: string;
      active: boolean;
      timezone: string;
      product: GaugeSettingProduct;
      updatedAt: Date | string;
      settings:
        | {
            __typename: "TekADCSettings";
            password?: string | null;
            schedule?: {
              __typename?: "ScheduleSetting";
              endTime?: number | null;
              frequency: number;
              every: ScheduleEvery;
              days: {
                __typename?: "ScheduleDays";
                Monday: boolean;
                Tuesday: boolean;
                Wednesday: boolean;
                Thursday: boolean;
                Friday: boolean;
                Saturday: boolean;
                Sunday: boolean;
              };
              startTime: {
                __typename?: "ScheduleStartTime";
                hour: number;
                minute: number;
              };
            } | null;
            staticAlarms?: Array<{
              __typename?: "StaticAlarmSetting";
              enabled: boolean;
              polarity?: StaticAlarmPolarity | null;
              threshold?: number | null;
              error?: number | null;
            }> | null;
            dynamicAlarms?: Array<{
              __typename?: "DynamicAlarmSetting";
              enabled: boolean;
              polarity?: DynamicAlarmPolarity | null;
              rate?: number | null;
            }> | null;
          }
        | {
            __typename: "TekUltrasonicSettings";
            password?: string | null;
            measurement?: MeasurementSetting | null;
            schedule?: {
              __typename?: "ScheduleSetting";
              endTime?: number | null;
              frequency: number;
              every: ScheduleEvery;
              days: {
                __typename?: "ScheduleDays";
                Monday: boolean;
                Tuesday: boolean;
                Wednesday: boolean;
                Thursday: boolean;
                Friday: boolean;
                Saturday: boolean;
                Sunday: boolean;
              };
              startTime: {
                __typename?: "ScheduleStartTime";
                hour: number;
                minute: number;
              };
            } | null;
            staticAlarms?: Array<{
              __typename?: "StaticAlarmSetting";
              enabled: boolean;
              polarity?: StaticAlarmPolarity | null;
              threshold?: number | null;
              error?: number | null;
            }> | null;
            dynamicAlarms?: Array<{
              __typename?: "DynamicAlarmSetting";
              enabled: boolean;
              polarity?: DynamicAlarmPolarity | null;
              rate?: number | null;
            }> | null;
            quality?: {
              __typename?: "QualitySetting";
              rssi: number;
              src: number;
            } | null;
          };
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    responses: {
      __typename?: "GaugeResponseList";
      responses: Array<{
        __typename?: "GaugeResponse";
        id: string;
        timezone: string;
        items: string;
        product: GaugeResponseProduct;
        updatedAt: Date | string;
        settings:
          | {
              __typename: "TekADCResponseSettings";
              listen?: number | null;
              fallbackPhone?: string | null;
              password?: string | null;
              battery?: number | null;
              fStop?: number | null;
              eStop?: number | null;
              delay?: number | null;
              logger?: {
                __typename?: "LoggerSetting";
                frequency: number;
                sampling: number;
              } | null;
              schedule?: {
                __typename?: "ScheduleSetting";
                endTime?: number | null;
                frequency: number;
                every: ScheduleEvery;
                days: {
                  __typename?: "ScheduleDays";
                  Monday: boolean;
                  Tuesday: boolean;
                  Wednesday: boolean;
                  Thursday: boolean;
                  Friday: boolean;
                  Saturday: boolean;
                  Sunday: boolean;
                };
                startTime: {
                  __typename?: "ScheduleStartTime";
                  hour: number;
                  minute: number;
                };
              } | null;
              control?: {
                __typename?: "ControlSetting";
                network?: string | null;
                bundAlarm: boolean;
                crcCheck: boolean;
                verboseLogging: boolean;
              } | null;
              staticAlarms?: Array<{
                __typename?: "StaticAlarmSetting";
                enabled: boolean;
                polarity?: StaticAlarmPolarity | null;
                threshold?: number | null;
                error?: number | null;
              }> | null;
              dynamicAlarms?: Array<{
                __typename?: "DynamicAlarmSetting";
                enabled: boolean;
                polarity?: DynamicAlarmPolarity | null;
                rate?: number | null;
              }> | null;
              apn?: {
                __typename?: "ApnSetting";
                gateway: string;
                username: string;
                password: string;
                authenticated: boolean;
              } | null;
              server?: {
                __typename?: "ServerSetting";
                host: string;
                port: number;
              } | null;
              network?: {
                __typename?: "NetworkSetting";
                operator: string;
                band: string;
              } | null;
              delivery?: {
                __typename?: "DeliverySetting";
                attempts: number;
                period: number;
              } | null;
            }
          | {
              __typename: "TekUltrasonicResponseSettings";
              listen?: number | null;
              fallbackPhone?: string | null;
              password?: string | null;
              measurement?: MeasurementSetting | null;
              height?: number | null;
              delay?: number | null;
              logger?: {
                __typename?: "LoggerSetting";
                frequency: number;
                sampling: number;
              } | null;
              schedule?: {
                __typename?: "ScheduleSetting";
                endTime?: number | null;
                frequency: number;
                every: ScheduleEvery;
                days: {
                  __typename?: "ScheduleDays";
                  Monday: boolean;
                  Tuesday: boolean;
                  Wednesday: boolean;
                  Thursday: boolean;
                  Friday: boolean;
                  Saturday: boolean;
                  Sunday: boolean;
                };
                startTime: {
                  __typename?: "ScheduleStartTime";
                  hour: number;
                  minute: number;
                };
              } | null;
              control?: {
                __typename?: "ControlSetting";
                network?: string | null;
                bundAlarm: boolean;
                crcCheck: boolean;
                verboseLogging: boolean;
              } | null;
              staticAlarms?: Array<{
                __typename?: "StaticAlarmSetting";
                enabled: boolean;
                polarity?: StaticAlarmPolarity | null;
                threshold?: number | null;
                error?: number | null;
              }> | null;
              dynamicAlarms?: Array<{
                __typename?: "DynamicAlarmSetting";
                enabled: boolean;
                polarity?: DynamicAlarmPolarity | null;
                rate?: number | null;
              }> | null;
              apn?: {
                __typename?: "ApnSetting";
                gateway: string;
                username: string;
                password: string;
                authenticated: boolean;
              } | null;
              server?: {
                __typename?: "ServerSetting";
                host: string;
                port: number;
              } | null;
              network?: {
                __typename?: "NetworkSetting";
                operator: string;
                band: string;
              } | null;
              delivery?: {
                __typename?: "DeliverySetting";
                attempts: number;
                period: number;
              } | null;
              quality?: {
                __typename?: "QualitySetting";
                rssi: number;
                src: number;
              } | null;
            };
      }>;
    };
    observations: {
      __typename?: "ObservationList";
      observations: Array<{
        __typename?: "Observation";
        id: string;
        reading: number;
        temperature?: number | null;
        voltage?: number | null;
        sleep?: number | null;
        signal?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
  } | null;
};

export type UpdateGaugeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: GaugeInput;
}>;

export type UpdateGaugeMutation = {
  __typename?: "Mutation";
  updateGauge: {
    __typename?: "Gauge";
    id: string;
    name: string;
    model?: string | null;
    product: GaugeProduct;
    technology: GaugeTechnology;
    seenAt?: Date | string | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    observations: {
      __typename?: "ObservationList";
      observations: Array<{
        __typename?: "Observation";
        id: string;
        reading: number;
        temperature?: number | null;
        voltage?: number | null;
        sleep?: number | null;
        signal?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
    tank?: { __typename?: "Tank"; id: string; name: string } | null;
    account?: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
    } | null;
    customer?: { __typename?: "Customer"; id: string; name: string } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    strappingTable: Array<{ __typename?: "StrapRow"; mm: number; l: number }>;
    detail:
      | { __typename: "CippusDetail"; connection: string; range: string }
      | {
          __typename: "TekADCDetail";
          model: string;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | {
          __typename: "TekUltrasonicDetail";
          model: string;
          hardware: { __typename?: "Version"; major: number; minor: number };
          firmware: { __typename?: "Version"; major: number; minor: number };
        }
      | { __typename: "UnregisteredDetail"; detail?: boolean | null };
    config:
      | {
          __typename: "CippusConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
        }
      | {
          __typename: "TekADCConfig";
          offsetHeight: number;
          offsetVolume: number;
          specificGravityProbe: number;
          specificGravityActual: number;
          rangeDigital: { __typename?: "Range"; min: number; max: number };
          rangeAnalog: { __typename?: "Range"; min: number; max: number };
          rangeProbe: { __typename?: "Range"; min: number; max: number };
          rangeHeight: { __typename?: "Range"; min: number; max: number };
        }
      | {
          __typename: "TekUltrasonicConfig";
          mountToBottom: number;
          offsetVolume: number;
        }
      | { __typename: "UnregisteredConfig"; config?: boolean | null };
  };
};

export type UpdateGaugeSettingMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: GaugeSettingInput;
}>;

export type UpdateGaugeSettingMutation = {
  __typename?: "Mutation";
  updateGaugeSetting: {
    __typename?: "GaugeSetting";
    id: string;
    active: boolean;
    timezone: string;
    product: GaugeSettingProduct;
    updatedAt: Date | string;
    settings:
      | {
          __typename: "TekADCSettings";
          password?: string | null;
          schedule?: {
            __typename?: "ScheduleSetting";
            endTime?: number | null;
            frequency: number;
            every: ScheduleEvery;
            days: {
              __typename?: "ScheduleDays";
              Monday: boolean;
              Tuesday: boolean;
              Wednesday: boolean;
              Thursday: boolean;
              Friday: boolean;
              Saturday: boolean;
              Sunday: boolean;
            };
            startTime: {
              __typename?: "ScheduleStartTime";
              hour: number;
              minute: number;
            };
          } | null;
          staticAlarms?: Array<{
            __typename?: "StaticAlarmSetting";
            enabled: boolean;
            polarity?: StaticAlarmPolarity | null;
            threshold?: number | null;
            error?: number | null;
          }> | null;
          dynamicAlarms?: Array<{
            __typename?: "DynamicAlarmSetting";
            enabled: boolean;
            polarity?: DynamicAlarmPolarity | null;
            rate?: number | null;
          }> | null;
        }
      | {
          __typename: "TekUltrasonicSettings";
          password?: string | null;
          measurement?: MeasurementSetting | null;
          schedule?: {
            __typename?: "ScheduleSetting";
            endTime?: number | null;
            frequency: number;
            every: ScheduleEvery;
            days: {
              __typename?: "ScheduleDays";
              Monday: boolean;
              Tuesday: boolean;
              Wednesday: boolean;
              Thursday: boolean;
              Friday: boolean;
              Saturday: boolean;
              Sunday: boolean;
            };
            startTime: {
              __typename?: "ScheduleStartTime";
              hour: number;
              minute: number;
            };
          } | null;
          staticAlarms?: Array<{
            __typename?: "StaticAlarmSetting";
            enabled: boolean;
            polarity?: StaticAlarmPolarity | null;
            threshold?: number | null;
            error?: number | null;
          }> | null;
          dynamicAlarms?: Array<{
            __typename?: "DynamicAlarmSetting";
            enabled: boolean;
            polarity?: DynamicAlarmPolarity | null;
            rate?: number | null;
          }> | null;
          quality?: {
            __typename?: "QualitySetting";
            rssi: number;
            src: number;
          } | null;
        };
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type VerifyGaugeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type VerifyGaugeMutation = {
  __typename?: "Mutation";
  verifyGauge: boolean;
};

export type InviteUserMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  jlteam?: InputMaybe<TeamPermission>;
}>;

export type InviteUserMutation = {
  __typename?: "Mutation";
  inviteUser?: {
    __typename?: "Invite";
    id: string;
    email: string;
    jlteam?: TeamPermission | null;
    createdAt: Date | string;
    updatedAt: Date | string;
  } | null;
};

export type InviteFragmentFragment = {
  __typename?: "Invite";
  id: string;
  email: string;
  jlteam?: TeamPermission | null;
  createdAt: Date | string;
  updatedAt: Date | string;
};

export type InvitesQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type InvitesQuery = {
  __typename?: "Query";
  invites: {
    __typename?: "InviteList";
    next?: string | null;
    invites: Array<{
      __typename?: "Invite";
      id: string;
      email: string;
      jlteam?: TeamPermission | null;
      createdAt: Date | string;
      updatedAt: Date | string;
    }>;
  };
};

export type LevelFragmentFragment = {
  __typename?: "Level";
  id: string;
  value: number;
  maximum: number;
  percent: number;
  signal?: number | null;
  temperature?: number | null;
  voltage?: number | null;
  rssi?: number | null;
  src?: number | null;
  updatedAt: Date | string;
};

export type ObservationFragmentFragment = {
  __typename?: "Observation";
  id: string;
  reading: number;
  temperature?: number | null;
  voltage?: number | null;
  sleep?: number | null;
  signal?: number | null;
  rssi?: number | null;
  src?: number | null;
  updatedAt: Date | string;
};

export type PlanAccountsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PlanAccountsQuery = {
  __typename?: "Query";
  plan?: {
    __typename?: "Plan";
    accounts: {
      __typename?: "AccountList";
      next?: string | null;
      accounts: Array<{
        __typename?: "Account";
        id: string;
        name: string;
        product: AccountProduct;
        createdAt: Date | string;
        updatedAt: Date | string;
        customer?: {
          __typename?: "Customer";
          id: string;
          name: string;
          createdAt: Date | string;
          updatedAt: Date | string;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        plan?: {
          __typename?: "Plan";
          id: string;
          code: string;
          amount: number;
          prepaid?: number | null;
          model: PlanModel;
          createdAt: Date | string;
          updatedAt: Date | string;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        config?:
          | { __typename: "ColibriConfig"; url?: string | null }
          | {
              __typename: "JoyGaugeConfig";
              gauges: {
                __typename?: "GaugeList";
                gauges: Array<{
                  __typename?: "Gauge";
                  id: string;
                  name: string;
                  technology: GaugeTechnology;
                  model?: string | null;
                }>;
              };
            }
          | {
              __typename: "ProGaugeConfig";
              userId?: string | null;
              timezone?: string | null;
              maximum?: Record<string, any> | null;
            }
          | {
              __typename: "RMSDeltaConfig";
              serialNumbers?: Array<string> | null;
            }
          | {
              __typename: "SmartFillConfig";
              reference?: string | null;
              secret?: string | null;
            }
          | null;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      }>;
    };
  } | null;
};

export type CreatePlanMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: PlanInput;
}>;

export type CreatePlanMutation = {
  __typename?: "Mutation";
  createPlan: {
    __typename?: "Plan";
    id: string;
    code: string;
    amount: number;
    prepaid?: number | null;
    model: PlanModel;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeletePlanMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeletePlanMutation = {
  __typename?: "Mutation";
  deletePlan: boolean;
};

export type PlanFragmentFragment = {
  __typename?: "Plan";
  id: string;
  code: string;
  amount: number;
  prepaid?: number | null;
  model: PlanModel;
  createdAt: Date | string;
  updatedAt: Date | string;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type PlanQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type PlanQuery = {
  __typename?: "Query";
  plan?: {
    __typename?: "Plan";
    id: string;
    code: string;
    amount: number;
    prepaid?: number | null;
    model: PlanModel;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type PlansQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PlansQuery = {
  __typename?: "Query";
  plans: {
    __typename?: "PlanList";
    next?: string | null;
    plans: Array<{
      __typename?: "Plan";
      id: string;
      code: string;
      amount: number;
      prepaid?: number | null;
      model: PlanModel;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdatePlanMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: PlanInput;
}>;

export type UpdatePlanMutation = {
  __typename?: "Mutation";
  updatePlan: {
    __typename?: "Plan";
    id: string;
    code: string;
    amount: number;
    prepaid?: number | null;
    model: PlanModel;
    createdAt: Date | string;
    updatedAt: Date | string;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type CreateRoleMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: RoleInput;
}>;

export type CreateRoleMutation = {
  __typename?: "Mutation";
  createRole: {
    __typename?: "Role";
    id: string;
    entityType: RoleEntity;
    entityId: string;
    permissions: Array<RolePermission>;
    createdAt: Date | string;
    user: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    createdBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type DeleteRoleMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteRoleMutation = {
  __typename?: "Mutation";
  deleteRole: boolean;
};

export type UserRolesQueryVariables = Exact<{
  userId: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UserRolesQuery = {
  __typename?: "Query";
  userRoles: {
    __typename?: "RoleList";
    next?: string | null;
    roles: Array<{
      __typename?: "Role";
      id: string;
      entityType: RoleEntity;
      entityId: string;
      permissions: Array<RolePermission>;
      createdAt: Date | string;
      account?: {
        __typename?: "Account";
        id: string;
        name: string;
        product: AccountProduct;
        createdAt: Date | string;
        updatedAt: Date | string;
        customer?: {
          __typename?: "Customer";
          id: string;
          name: string;
          createdAt: Date | string;
          updatedAt: Date | string;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        plan?: {
          __typename?: "Plan";
          id: string;
          code: string;
          amount: number;
          prepaid?: number | null;
          model: PlanModel;
          createdAt: Date | string;
          updatedAt: Date | string;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        config?:
          | { __typename: "ColibriConfig"; url?: string | null }
          | {
              __typename: "JoyGaugeConfig";
              gauges: {
                __typename?: "GaugeList";
                gauges: Array<{
                  __typename?: "Gauge";
                  id: string;
                  name: string;
                  technology: GaugeTechnology;
                  model?: string | null;
                }>;
              };
            }
          | {
              __typename: "ProGaugeConfig";
              userId?: string | null;
              timezone?: string | null;
              maximum?: Record<string, any> | null;
            }
          | {
              __typename: "RMSDeltaConfig";
              serialNumbers?: Array<string> | null;
            }
          | {
              __typename: "SmartFillConfig";
              reference?: string | null;
              secret?: string | null;
            }
          | null;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      } | null;
      customer?: {
        __typename?: "Customer";
        id: string;
        name: string;
        createdAt: Date | string;
        updatedAt: Date | string;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      } | null;
      user: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
      createdBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type RoleFragmentFragment = {
  __typename?: "Role";
  id: string;
  entityType: RoleEntity;
  entityId: string;
  permissions: Array<RolePermission>;
  createdAt: Date | string;
  user: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
  createdBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type RoleQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type RoleQuery = {
  __typename?: "Query";
  role?: {
    __typename?: "Role";
    id: string;
    entityType: RoleEntity;
    entityId: string;
    permissions: Array<RolePermission>;
    createdAt: Date | string;
    account?: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
      createdAt: Date | string;
      updatedAt: Date | string;
      config?:
        | { __typename: "ColibriConfig"; url?: string | null }
        | {
            __typename: "JoyGaugeConfig";
            gauges: {
              __typename?: "GaugeList";
              gauges: Array<{
                __typename?: "Gauge";
                id: string;
                name: string;
                technology: GaugeTechnology;
                model?: string | null;
              }>;
            };
          }
        | {
            __typename: "ProGaugeConfig";
            userId?: string | null;
            timezone?: string | null;
            maximum?: Record<string, any> | null;
          }
        | { __typename: "RMSDeltaConfig"; serialNumbers?: Array<string> | null }
        | {
            __typename: "SmartFillConfig";
            reference?: string | null;
            secret?: string | null;
          }
        | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    customer?: {
      __typename?: "Customer";
      id: string;
      name: string;
      createdAt: Date | string;
      updatedAt: Date | string;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    } | null;
    user: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    createdBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type RolesQueryVariables = Exact<{
  entityId: Scalars["ID"]["input"];
  entityType: RoleEntity;
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RolesQuery = {
  __typename?: "Query";
  roles: {
    __typename?: "RoleList";
    next?: string | null;
    roles: Array<{
      __typename?: "Role";
      id: string;
      entityType: RoleEntity;
      entityId: string;
      permissions: Array<RolePermission>;
      createdAt: Date | string;
      account?: {
        __typename?: "Account";
        id: string;
        name: string;
        product: AccountProduct;
        createdAt: Date | string;
        updatedAt: Date | string;
        customer?: {
          __typename?: "Customer";
          id: string;
          name: string;
          createdAt: Date | string;
          updatedAt: Date | string;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        plan?: {
          __typename?: "Plan";
          id: string;
          code: string;
          amount: number;
          prepaid?: number | null;
          model: PlanModel;
          createdAt: Date | string;
          updatedAt: Date | string;
          updatedBy: {
            __typename?: "User";
            id: string;
            email: string;
            firstName?: string | null;
            lastName?: string | null;
            picture?: string | null;
          };
        } | null;
        config?:
          | { __typename: "ColibriConfig"; url?: string | null }
          | {
              __typename: "JoyGaugeConfig";
              gauges: {
                __typename?: "GaugeList";
                gauges: Array<{
                  __typename?: "Gauge";
                  id: string;
                  name: string;
                  technology: GaugeTechnology;
                  model?: string | null;
                }>;
              };
            }
          | {
              __typename: "ProGaugeConfig";
              userId?: string | null;
              timezone?: string | null;
              maximum?: Record<string, any> | null;
            }
          | {
              __typename: "RMSDeltaConfig";
              serialNumbers?: Array<string> | null;
            }
          | {
              __typename: "SmartFillConfig";
              reference?: string | null;
              secret?: string | null;
            }
          | null;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      } | null;
      customer?: {
        __typename?: "Customer";
        id: string;
        name: string;
        createdAt: Date | string;
        updatedAt: Date | string;
        updatedBy: {
          __typename?: "User";
          id: string;
          email: string;
          firstName?: string | null;
          lastName?: string | null;
          picture?: string | null;
        };
      } | null;
      user: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
      createdBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdateRoleMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  permissions: Array<RolePermission> | RolePermission;
}>;

export type UpdateRoleMutation = {
  __typename?: "Mutation";
  updateRole: {
    __typename?: "Role";
    id: string;
    entityType: RoleEntity;
    entityId: string;
    permissions: Array<RolePermission>;
    createdAt: Date | string;
    user: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
    createdBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type TankFragmentFragment = {
  __typename?: "Tank";
  id: string;
  name: string;
  unit?: TankUnit | null;
  contents?: string | null;
  activatedAt?: Date | string | null;
  warningLevels?: Array<number> | null;
  deliveryThreshold?: number | null;
  notificationEmails?: Array<string> | null;
  createdAt: Date | string;
  updatedAt: Date | string;
  location?: {
    __typename?: "TankLocation";
    display: string;
    latitude: number;
    longitude: number;
  } | null;
  updatedBy: {
    __typename?: "User";
    id: string;
    email: string;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
  };
};

export type TankQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type TankQuery = {
  __typename?: "Query";
  tankAccess: Array<RolePermission>;
  tank?: {
    __typename?: "Tank";
    id: string;
    name: string;
    unit?: TankUnit | null;
    contents?: string | null;
    activatedAt?: Date | string | null;
    warningLevels?: Array<number> | null;
    deliveryThreshold?: number | null;
    notificationEmails?: Array<string> | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    levels: {
      __typename?: "LevelList";
      levels: Array<{
        __typename?: "Level";
        id: string;
        value: number;
        maximum: number;
        percent: number;
        signal?: number | null;
        temperature?: number | null;
        voltage?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
    gauge?: { __typename?: "Gauge"; id: string; name: string } | null;
    account: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
    };
    location?: {
      __typename?: "TankLocation";
      display: string;
      latitude: number;
      longitude: number;
    } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  } | null;
};

export type TankLevelsQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  startAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TankLevelsQuery = {
  __typename?: "Query";
  tank?: {
    __typename?: "Tank";
    levels: {
      __typename?: "LevelList";
      next?: string | null;
      levels: Array<{
        __typename?: "Level";
        id: string;
        value: number;
        maximum: number;
        percent: number;
        signal?: number | null;
        temperature?: number | null;
        voltage?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
  } | null;
};

export type TanksQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TanksQuery = {
  __typename?: "Query";
  tanks: {
    __typename?: "TankList";
    next?: string | null;
    tanks: Array<{
      __typename?: "Tank";
      id: string;
      name: string;
      unit?: TankUnit | null;
      contents?: string | null;
      activatedAt?: Date | string | null;
      warningLevels?: Array<number> | null;
      deliveryThreshold?: number | null;
      notificationEmails?: Array<string> | null;
      createdAt: Date | string;
      updatedAt: Date | string;
      levels: {
        __typename?: "LevelList";
        levels: Array<{
          __typename?: "Level";
          id: string;
          value: number;
          maximum: number;
          percent: number;
          signal?: number | null;
          temperature?: number | null;
          voltage?: number | null;
          rssi?: number | null;
          src?: number | null;
          updatedAt: Date | string;
        }>;
      };
      account: {
        __typename?: "Account";
        name: string;
        product: AccountProduct;
        customer?: { __typename?: "Customer"; name: string } | null;
        plan?: { __typename?: "Plan"; code: string } | null;
      };
      location?: {
        __typename?: "TankLocation";
        display: string;
        latitude: number;
        longitude: number;
      } | null;
      updatedBy: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type TankRolesQueryVariables = Exact<{
  tankId: Scalars["ID"]["input"];
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type TankRolesQuery = {
  __typename?: "Query";
  tankRoles: {
    __typename?: "RoleList";
    next?: string | null;
    roles: Array<{
      __typename?: "Role";
      user: {
        __typename?: "User";
        id: string;
        email: string;
        firstName?: string | null;
        lastName?: string | null;
        picture?: string | null;
      };
    }>;
  };
};

export type UpdateTankMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: TankInput;
}>;

export type UpdateTankMutation = {
  __typename?: "Mutation";
  updateTank: {
    __typename?: "Tank";
    id: string;
    name: string;
    unit?: TankUnit | null;
    contents?: string | null;
    activatedAt?: Date | string | null;
    warningLevels?: Array<number> | null;
    deliveryThreshold?: number | null;
    notificationEmails?: Array<string> | null;
    createdAt: Date | string;
    updatedAt: Date | string;
    levels: {
      __typename?: "LevelList";
      levels: Array<{
        __typename?: "Level";
        id: string;
        value: number;
        maximum: number;
        percent: number;
        signal?: number | null;
        temperature?: number | null;
        voltage?: number | null;
        rssi?: number | null;
        src?: number | null;
        updatedAt: Date | string;
      }>;
    };
    gauge?: { __typename?: "Gauge"; id: string; name: string } | null;
    account: {
      __typename?: "Account";
      id: string;
      name: string;
      product: AccountProduct;
    };
    location?: {
      __typename?: "TankLocation";
      display: string;
      latitude: number;
      longitude: number;
    } | null;
    updatedBy: {
      __typename?: "User";
      id: string;
      email: string;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
    };
  };
};

export type ActorFragmentFragment = {
  __typename?: "User";
  id: string;
  email: string;
  firstName?: string | null;
  lastName?: string | null;
  picture?: string | null;
};

export type DeleteUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteUserMutation = {
  __typename?: "Mutation";
  deleteUser: boolean;
};

export type UserFragmentFragment = {
  __typename?: "User";
  id: string;
  email: string;
  jlteam?: TeamPermission | null;
  firstName?: string | null;
  lastName?: string | null;
  picture?: string | null;
  identities?: Array<string> | null;
  phone?: string | null;
  company?: string | null;
  title?: string | null;
  createdAt: Date | string;
  lastActiveAt?: Date | string | null;
};

export type UserQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type UserQuery = {
  __typename?: "Query";
  user: {
    __typename?: "User";
    id: string;
    email: string;
    jlteam?: TeamPermission | null;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
    identities?: Array<string> | null;
    phone?: string | null;
    company?: string | null;
    title?: string | null;
    createdAt: Date | string;
    lastActiveAt?: Date | string | null;
  };
};

export type UsersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  cursor?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UsersQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UserList";
    next?: string | null;
    users: Array<{
      __typename?: "User";
      id: string;
      email: string;
      jlteam?: TeamPermission | null;
      firstName?: string | null;
      lastName?: string | null;
      picture?: string | null;
      identities?: Array<string> | null;
      phone?: string | null;
      company?: string | null;
      title?: string | null;
      createdAt: Date | string;
      lastActiveAt?: Date | string | null;
    }>;
  };
};

export type UpdateUserMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  fields: UserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  updateUser?: {
    __typename?: "User";
    id: string;
    email: string;
    jlteam?: TeamPermission | null;
    firstName?: string | null;
    lastName?: string | null;
    picture?: string | null;
    identities?: Array<string> | null;
    phone?: string | null;
    company?: string | null;
    title?: string | null;
    createdAt: Date | string;
    lastActiveAt?: Date | string | null;
  } | null;
};

export const ActorFragmentFragmentDoc = gql`
  fragment ActorFragment on User {
    id
    email
    firstName
    lastName
    picture
  }
` as unknown as DocumentNode<ActorFragmentFragment, unknown>;
export const AccountFragmentFragmentDoc = gql`
  fragment AccountFragment on Account {
    id
    name
    product
    config {
      __typename
      ... on ColibriConfig {
        url
      }
      ... on JoyGaugeConfig {
        gauges(limit: 500) {
          gauges {
            id
            name
            technology
            model
          }
        }
      }
      ... on ProGaugeConfig {
        userId
        timezone
        maximum
      }
      ... on RMSDeltaConfig {
        serialNumbers
      }
      ... on SmartFillConfig {
        reference
        secret
      }
    }
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<AccountFragmentFragment, unknown>;
export const CommentFragmentFragmentDoc = gql`
  fragment CommentFragment on Comment {
    id
    message
    createdAt
    createdBy {
      ...ActorFragment
    }
    updatedAt
  }
` as unknown as DocumentNode<CommentFragmentFragment, unknown>;
export const CustomerFragmentFragmentDoc = gql`
  fragment CustomerFragment on Customer {
    id
    name
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<CustomerFragmentFragment, unknown>;
export const DocumentFragmentFragmentDoc = gql`
  fragment DocumentFragment on Document {
    id
    title
    content
    html
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<DocumentFragmentFragment, unknown>;
export const GaugeDetailFragmentDoc = gql`
  fragment GaugeDetail on Gauge {
    strappingTable {
      mm
      l
    }
    detail {
      __typename
      ... on UnregisteredDetail {
        detail
      }
      ... on CippusDetail {
        connection
        range
      }
      ... on TekUltrasonicDetail {
        model
        hardware {
          major
          minor
        }
        firmware {
          major
          minor
        }
      }
      ... on TekADCDetail {
        model
        hardware {
          major
          minor
        }
        firmware {
          major
          minor
        }
      }
    }
    config {
      __typename
      ... on UnregisteredConfig {
        config
      }
      ... on CippusConfig {
        offsetHeight
        offsetVolume
        specificGravityProbe
        specificGravityActual
      }
      ... on TekUltrasonicConfig {
        mountToBottom
        offsetVolume
      }
      ... on TekADCConfig {
        rangeDigital {
          min
          max
        }
        rangeAnalog {
          min
          max
        }
        rangeProbe {
          min
          max
        }
        rangeHeight {
          min
          max
        }
        offsetHeight
        offsetVolume
        specificGravityProbe
        specificGravityActual
      }
    }
  }
` as unknown as DocumentNode<GaugeDetailFragment, unknown>;
export const GaugeFragmentFragmentDoc = gql`
  fragment GaugeFragment on Gauge {
    id
    name
    model
    product
    technology
    seenAt
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<GaugeFragmentFragment, unknown>;
export const GaugeResponseFragmentFragmentDoc = gql`
  fragment GaugeResponseFragment on GaugeResponse {
    id
    timezone
    items
    product
    settings {
      __typename
      ... on TekUltrasonicResponseSettings {
        logger {
          frequency
          sampling
        }
        listen
        schedule {
          days {
            Monday
            Tuesday
            Wednesday
            Thursday
            Friday
            Saturday
            Sunday
          }
          startTime {
            hour
            minute
          }
          endTime
          frequency
          every
        }
        control {
          network
          bundAlarm
          crcCheck
          verboseLogging
        }
        staticAlarms {
          enabled
          polarity
          threshold
          error
        }
        dynamicAlarms {
          enabled
          polarity
          rate
        }
        fallbackPhone
        password
        apn {
          gateway
          username
          password
          authenticated
        }
        server {
          host
          port
        }
        measurement
        height
        network {
          operator
          band
        }
        delivery {
          attempts
          period
        }
        delay
        quality {
          rssi
          src
        }
      }
      ... on TekADCResponseSettings {
        logger {
          frequency
          sampling
        }
        listen
        schedule {
          days {
            Monday
            Tuesday
            Wednesday
            Thursday
            Friday
            Saturday
            Sunday
          }
          startTime {
            hour
            minute
          }
          endTime
          frequency
          every
        }
        control {
          network
          bundAlarm
          crcCheck
          verboseLogging
        }
        staticAlarms {
          enabled
          polarity
          threshold
          error
        }
        dynamicAlarms {
          enabled
          polarity
          rate
        }
        fallbackPhone
        password
        apn {
          gateway
          username
          password
          authenticated
        }
        server {
          host
          port
        }
        battery
        fStop
        eStop
        network {
          operator
          band
        }
        delivery {
          attempts
          period
        }
        delay
      }
    }
    updatedAt
  }
` as unknown as DocumentNode<GaugeResponseFragmentFragment, unknown>;
export const GaugeSettingFragmentFragmentDoc = gql`
  fragment GaugeSettingFragment on GaugeSetting {
    id
    active
    timezone
    product
    settings {
      __typename
      ... on TekUltrasonicSettings {
        password
        measurement
        schedule {
          days {
            Monday
            Tuesday
            Wednesday
            Thursday
            Friday
            Saturday
            Sunday
          }
          startTime {
            hour
            minute
          }
          endTime
          frequency
          every
        }
        staticAlarms {
          enabled
          polarity
          threshold
          error
        }
        dynamicAlarms {
          enabled
          polarity
          rate
        }
        quality {
          rssi
          src
        }
      }
      ... on TekADCSettings {
        password
        schedule {
          days {
            Monday
            Tuesday
            Wednesday
            Thursday
            Friday
            Saturday
            Sunday
          }
          startTime {
            hour
            minute
          }
          endTime
          frequency
          every
        }
        staticAlarms {
          enabled
          polarity
          threshold
          error
        }
        dynamicAlarms {
          enabled
          polarity
          rate
        }
      }
    }
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<GaugeSettingFragmentFragment, unknown>;
export const InviteFragmentFragmentDoc = gql`
  fragment InviteFragment on Invite {
    id
    email
    jlteam
    createdAt
    updatedAt
  }
` as unknown as DocumentNode<InviteFragmentFragment, unknown>;
export const LevelFragmentFragmentDoc = gql`
  fragment LevelFragment on Level {
    id
    value
    maximum
    percent
    signal
    temperature
    voltage
    rssi
    src
    updatedAt
  }
` as unknown as DocumentNode<LevelFragmentFragment, unknown>;
export const ObservationFragmentFragmentDoc = gql`
  fragment ObservationFragment on Observation {
    id
    reading
    temperature
    voltage
    sleep
    signal
    rssi
    src
    updatedAt
  }
` as unknown as DocumentNode<ObservationFragmentFragment, unknown>;
export const PlanFragmentFragmentDoc = gql`
  fragment PlanFragment on Plan {
    id
    code
    amount
    prepaid
    model
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<PlanFragmentFragment, unknown>;
export const RoleFragmentFragmentDoc = gql`
  fragment RoleFragment on Role {
    id
    entityType
    entityId
    user {
      ...ActorFragment
    }
    permissions
    createdAt
    createdBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<RoleFragmentFragment, unknown>;
export const TankFragmentFragmentDoc = gql`
  fragment TankFragment on Tank {
    id
    name
    unit
    contents
    location {
      display
      latitude
      longitude
    }
    activatedAt
    warningLevels
    deliveryThreshold
    notificationEmails
    createdAt
    updatedAt
    updatedBy {
      ...ActorFragment
    }
  }
` as unknown as DocumentNode<TankFragmentFragment, unknown>;
export const UserFragmentFragmentDoc = gql`
  fragment UserFragment on User {
    id
    email
    jlteam
    firstName
    lastName
    picture
    identities
    phone
    company
    title
    createdAt
    lastActiveAt
  }
` as unknown as DocumentNode<UserFragmentFragment, unknown>;
export const CreateAccountDocument = gql`
  mutation createAccount($id: ID!, $fields: AccountInput!) {
    createAccount(id: $id, fields: $fields) {
      ...AccountFragment
      customer {
        ...CustomerFragment
      }
      plan {
        ...PlanFragment
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<
  CreateAccountMutation,
  CreateAccountMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation deleteAccount($id: ID!) {
    deleteAccount(id: $id)
  }
` as unknown as DocumentNode<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const AccountDocument = gql`
  query account($id: ID!) {
    account(id: $id) {
      ...AccountFragment
      customer {
        ...CustomerFragment
      }
      plan {
        ...PlanFragment
      }
    }
    accountAccess(id: $id)
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<AccountQuery, AccountQueryVariables>;
export const AccountsDocument = gql`
  query accounts($limit: Int!, $cursor: String) {
    accounts(limit: $limit, cursor: $cursor) {
      next
      accounts {
        ...AccountFragment
        customer {
          ...CustomerFragment
        }
        plan {
          ...PlanFragment
        }
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<AccountsQuery, AccountsQueryVariables>;
export const AccountTanksDocument = gql`
  query accountTanks($id: ID!, $limit: Int!, $cursor: String) {
    account(id: $id) {
      tanks(limit: $limit, cursor: $cursor) {
        next
        tanks {
          ...TankFragment
          levels(limit: 1) {
            levels {
              ...LevelFragment
            }
          }
        }
      }
    }
  }
  ${TankFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${LevelFragmentFragmentDoc}
` as unknown as DocumentNode<AccountTanksQuery, AccountTanksQueryVariables>;
export const UpdateAccountDocument = gql`
  mutation updateAccount($id: ID!, $fields: AccountInput!) {
    updateAccount(id: $id, fields: $fields) {
      ...AccountFragment
      customer {
        ...CustomerFragment
      }
      plan {
        ...PlanFragment
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateAccountMutation,
  UpdateAccountMutationVariables
>;
export const VerifyAccountDocument = gql`
  mutation verifyAccount($id: ID!) {
    verifyAccount(id: $id)
  }
` as unknown as DocumentNode<
  VerifyAccountMutation,
  VerifyAccountMutationVariables
>;
export const CreateCommentDocument = gql`
  mutation createComment($id: ID!, $fields: CommentInput!) {
    createComment(id: $id, fields: $fields) {
      ...CommentFragment
    }
  }
  ${CommentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  CreateCommentMutation,
  CreateCommentMutationVariables
>;
export const DeleteCommentDocument = gql`
  mutation deleteComment($id: ID!) {
    deleteComment(id: $id)
  }
` as unknown as DocumentNode<
  DeleteCommentMutation,
  DeleteCommentMutationVariables
>;
export const CommentsDocument = gql`
  query comments(
    $entityId: ID!
    $entityType: CommentEntity!
    $limit: Int!
    $cursor: String
  ) {
    comments(
      entityId: $entityId
      entityType: $entityType
      limit: $limit
      cursor: $cursor
    ) {
      next
      comments {
        ...CommentFragment
      }
    }
  }
  ${CommentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<CommentsQuery, CommentsQueryVariables>;
export const UpdateCommentDocument = gql`
  mutation updateComment($id: ID!, $fields: CommentInput!) {
    updateComment(id: $id, fields: $fields) {
      ...CommentFragment
    }
  }
  ${CommentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateCommentMutation,
  UpdateCommentMutationVariables
>;
export const CustomerAccountsDocument = gql`
  query customerAccounts($id: ID!, $limit: Int!, $cursor: String) {
    customer(id: $id) {
      accounts(limit: $limit, cursor: $cursor) {
        next
        accounts {
          ...AccountFragment
          customer {
            ...CustomerFragment
          }
          plan {
            ...PlanFragment
          }
        }
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<
  CustomerAccountsQuery,
  CustomerAccountsQueryVariables
>;
export const CreateCustomerDocument = gql`
  mutation createCustomer($id: ID!, $fields: CustomerInput!) {
    createCustomer(id: $id, fields: $fields) {
      ...CustomerFragment
    }
  }
  ${CustomerFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  CreateCustomerMutation,
  CreateCustomerMutationVariables
>;
export const DeleteCustomerDocument = gql`
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id)
  }
` as unknown as DocumentNode<
  DeleteCustomerMutation,
  DeleteCustomerMutationVariables
>;
export const CustomerDocument = gql`
  query customer($id: ID!) {
    customer(id: $id) {
      ...CustomerFragment
    }
  }
  ${CustomerFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<CustomerQuery, CustomerQueryVariables>;
export const CustomersDocument = gql`
  query customers($limit: Int!, $cursor: String) {
    customers(limit: $limit, cursor: $cursor) {
      next
      customers {
        ...CustomerFragment
      }
    }
  }
  ${CustomerFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<CustomersQuery, CustomersQueryVariables>;
export const UpdateCustomerDocument = gql`
  mutation updateCustomer($id: ID!, $fields: CustomerInput!) {
    updateCustomer(id: $id, fields: $fields) {
      ...CustomerFragment
    }
  }
  ${CustomerFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateCustomerMutation,
  UpdateCustomerMutationVariables
>;
export const CreateDocumentDocument = gql`
  mutation createDocument($id: ID!, $fields: DocumentInput!) {
    createDocument(id: $id, fields: $fields) {
      ...DocumentFragment
    }
  }
  ${DocumentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  CreateDocumentMutation,
  CreateDocumentMutationVariables
>;
export const DeleteDocumentDocument = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id)
  }
` as unknown as DocumentNode<
  DeleteDocumentMutation,
  DeleteDocumentMutationVariables
>;
export const DocumentDocument = gql`
  query document($id: ID!) {
    document(id: $id) {
      ...DocumentFragment
    }
  }
  ${DocumentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<DocumentQuery, DocumentQueryVariables>;
export const DocumentsDocument = gql`
  query documents($limit: Int!, $cursor: String) {
    documents(limit: $limit, cursor: $cursor) {
      next
      documents {
        ...DocumentFragment
      }
    }
  }
  ${DocumentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<DocumentsQuery, DocumentsQueryVariables>;
export const UpdateDocumentDocument = gql`
  mutation updateDocument($id: ID!, $fields: DocumentInput!) {
    updateDocument(id: $id, fields: $fields) {
      ...DocumentFragment
    }
  }
  ${DocumentFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateDocumentMutation,
  UpdateDocumentMutationVariables
>;
export const FairbanksTanksDocument = gql`
  query fairbanksTanks($userId: String!) {
    fairbanksTanks(userId: $userId) {
      id
      items {
        ibank
        time
        tank
        volume
        temperature
        water
        height
      }
    }
  }
` as unknown as DocumentNode<FairbanksTanksQuery, FairbanksTanksQueryVariables>;
export const FairbanksUsersDocument = gql`
  query fairbanksUsers($limit: Int!, $cursor: String) {
    fairbanksUsers(limit: $limit, cursor: $cursor) {
      next
      users
    }
  }
` as unknown as DocumentNode<FairbanksUsersQuery, FairbanksUsersQueryVariables>;
export const CreateGaugeDocument = gql`
  mutation createGauge($id: ID!, $fields: GaugeInput!) {
    createGauge(id: $id, fields: $fields) {
      ...GaugeFragment
      ...GaugeDetail
      observations(limit: 1) {
        observations {
          ...ObservationFragment
        }
      }
      tank {
        id
        name
      }
      account {
        id
        name
        product
      }
      customer {
        id
        name
      }
    }
  }
  ${GaugeFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${GaugeDetailFragmentDoc}
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<CreateGaugeMutation, CreateGaugeMutationVariables>;
export const DeleteGaugeDocument = gql`
  mutation deleteGauge($id: ID!) {
    deleteGauge(id: $id)
  }
` as unknown as DocumentNode<DeleteGaugeMutation, DeleteGaugeMutationVariables>;
export const GaugeDocument = gql`
  query gauge($id: ID!) {
    gauge(id: $id) {
      ...GaugeFragment
      ...GaugeDetail
      observations(limit: 1) {
        observations {
          ...ObservationFragment
        }
      }
      tank {
        id
        name
      }
      account {
        id
        name
        product
      }
      customer {
        id
        name
      }
    }
    gaugeAccess(id: $id)
  }
  ${GaugeFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${GaugeDetailFragmentDoc}
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<GaugeQuery, GaugeQueryVariables>;
export const GaugesDocument = gql`
  query gauges($limit: Int!, $cursor: String) {
    gauges(limit: $limit, cursor: $cursor) {
      next
      gauges {
        ...GaugeFragment
        ...GaugeDetail
        observations(limit: 1) {
          observations {
            ...ObservationFragment
          }
        }
        account {
          id
          name
          product
        }
        customer {
          id
          name
        }
      }
    }
  }
  ${GaugeFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${GaugeDetailFragmentDoc}
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<GaugesQuery, GaugesQueryVariables>;
export const GaugeObservationsDocument = gql`
  query gaugeObservations(
    $id: ID!
    $limit: Int!
    $startAt: DateTime
    $cursor: String
  ) {
    gauge(id: $id) {
      observations(limit: $limit, cursor: $cursor, startAt: $startAt) {
        next
        observations {
          ...ObservationFragment
        }
      }
    }
  }
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<
  GaugeObservationsQuery,
  GaugeObservationsQueryVariables
>;
export const GaugeSettingsDocument = gql`
  query gaugeSettings($id: ID!) {
    gauge(id: $id) {
      id
      name
      product
      seenAt
      deactivatedAt
      setting {
        ...GaugeSettingFragment
      }
      responses(limit: 1) {
        responses {
          ...GaugeResponseFragment
        }
      }
      observations(limit: 1) {
        observations {
          ...ObservationFragment
        }
      }
    }
    gaugeAccess(id: $id)
  }
  ${GaugeSettingFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${GaugeResponseFragmentFragmentDoc}
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<GaugeSettingsQuery, GaugeSettingsQueryVariables>;
export const UpdateGaugeDocument = gql`
  mutation updateGauge($id: ID!, $fields: GaugeInput!) {
    updateGauge(id: $id, fields: $fields) {
      ...GaugeFragment
      ...GaugeDetail
      observations(limit: 1) {
        observations {
          ...ObservationFragment
        }
      }
      tank {
        id
        name
      }
      account {
        id
        name
        product
      }
      customer {
        id
        name
      }
    }
  }
  ${GaugeFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${GaugeDetailFragmentDoc}
  ${ObservationFragmentFragmentDoc}
` as unknown as DocumentNode<UpdateGaugeMutation, UpdateGaugeMutationVariables>;
export const UpdateGaugeSettingDocument = gql`
  mutation updateGaugeSetting($id: ID!, $fields: GaugeSettingInput!) {
    updateGaugeSetting(id: $id, fields: $fields) {
      ...GaugeSettingFragment
    }
  }
  ${GaugeSettingFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<
  UpdateGaugeSettingMutation,
  UpdateGaugeSettingMutationVariables
>;
export const VerifyGaugeDocument = gql`
  mutation verifyGauge($id: ID!) {
    verifyGauge(id: $id)
  }
` as unknown as DocumentNode<VerifyGaugeMutation, VerifyGaugeMutationVariables>;
export const InviteUserDocument = gql`
  mutation inviteUser($email: String!, $jlteam: TeamPermission) {
    inviteUser(email: $email, jlteam: $jlteam) {
      ...InviteFragment
    }
  }
  ${InviteFragmentFragmentDoc}
` as unknown as DocumentNode<InviteUserMutation, InviteUserMutationVariables>;
export const InvitesDocument = gql`
  query invites($limit: Int!, $cursor: String) {
    invites(limit: $limit, cursor: $cursor) {
      next
      invites {
        ...InviteFragment
      }
    }
  }
  ${InviteFragmentFragmentDoc}
` as unknown as DocumentNode<InvitesQuery, InvitesQueryVariables>;
export const PlanAccountsDocument = gql`
  query planAccounts($id: ID!, $limit: Int!, $cursor: String) {
    plan(id: $id) {
      accounts(limit: $limit, cursor: $cursor) {
        next
        accounts {
          ...AccountFragment
          customer {
            ...CustomerFragment
          }
          plan {
            ...PlanFragment
          }
        }
      }
    }
  }
  ${AccountFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<PlanAccountsQuery, PlanAccountsQueryVariables>;
export const CreatePlanDocument = gql`
  mutation createPlan($id: ID!, $fields: PlanInput!) {
    createPlan(id: $id, fields: $fields) {
      ...PlanFragment
    }
  }
  ${PlanFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<CreatePlanMutation, CreatePlanMutationVariables>;
export const DeletePlanDocument = gql`
  mutation deletePlan($id: ID!) {
    deletePlan(id: $id)
  }
` as unknown as DocumentNode<DeletePlanMutation, DeletePlanMutationVariables>;
export const PlanDocument = gql`
  query plan($id: ID!) {
    plan(id: $id) {
      ...PlanFragment
    }
  }
  ${PlanFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<PlanQuery, PlanQueryVariables>;
export const PlansDocument = gql`
  query plans($limit: Int!, $cursor: String) {
    plans(limit: $limit, cursor: $cursor) {
      next
      plans {
        ...PlanFragment
      }
    }
  }
  ${PlanFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<PlansQuery, PlansQueryVariables>;
export const UpdatePlanDocument = gql`
  mutation updatePlan($id: ID!, $fields: PlanInput!) {
    updatePlan(id: $id, fields: $fields) {
      ...PlanFragment
    }
  }
  ${PlanFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const CreateRoleDocument = gql`
  mutation createRole($id: ID!, $fields: RoleInput!) {
    createRole(id: $id, fields: $fields) {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<CreateRoleMutation, CreateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
  mutation deleteRole($id: ID!) {
    deleteRole(id: $id)
  }
` as unknown as DocumentNode<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const UserRolesDocument = gql`
  query userRoles($userId: ID!, $limit: Int!, $cursor: String) {
    userRoles(userId: $userId, limit: $limit, cursor: $cursor) {
      next
      roles {
        ...RoleFragment
        account {
          ...AccountFragment
          customer {
            ...CustomerFragment
          }
          plan {
            ...PlanFragment
          }
        }
        customer {
          ...CustomerFragment
        }
      }
    }
  }
  ${RoleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${AccountFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<UserRolesQuery, UserRolesQueryVariables>;
export const RoleDocument = gql`
  query role($id: ID!) {
    role(id: $id) {
      ...RoleFragment
      account {
        ...AccountFragment
      }
      customer {
        ...CustomerFragment
      }
    }
  }
  ${RoleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${AccountFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
` as unknown as DocumentNode<RoleQuery, RoleQueryVariables>;
export const RolesDocument = gql`
  query roles(
    $entityId: ID!
    $entityType: RoleEntity!
    $limit: Int!
    $cursor: String
  ) {
    roles(
      entityId: $entityId
      entityType: $entityType
      limit: $limit
      cursor: $cursor
    ) {
      next
      roles {
        ...RoleFragment
        account {
          ...AccountFragment
          customer {
            ...CustomerFragment
          }
          plan {
            ...PlanFragment
          }
        }
        customer {
          ...CustomerFragment
        }
      }
    }
  }
  ${RoleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${AccountFragmentFragmentDoc}
  ${CustomerFragmentFragmentDoc}
  ${PlanFragmentFragmentDoc}
` as unknown as DocumentNode<RolesQuery, RolesQueryVariables>;
export const UpdateRoleDocument = gql`
  mutation updateRole($id: ID!, $permissions: [RolePermission!]!) {
    updateRole(id: $id, permissions: $permissions) {
      ...RoleFragment
    }
  }
  ${RoleFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const TankDocument = gql`
  query tank($id: ID!) {
    tank(id: $id) {
      ...TankFragment
      levels(limit: 1) {
        levels {
          ...LevelFragment
        }
      }
      gauge {
        id
        name
      }
      account {
        id
        name
        product
      }
    }
    tankAccess(id: $id)
  }
  ${TankFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${LevelFragmentFragmentDoc}
` as unknown as DocumentNode<TankQuery, TankQueryVariables>;
export const TankLevelsDocument = gql`
  query tankLevels(
    $id: ID!
    $limit: Int!
    $startAt: DateTime
    $cursor: String
  ) {
    tank(id: $id) {
      levels(limit: $limit, cursor: $cursor, startAt: $startAt) {
        next
        levels {
          ...LevelFragment
        }
      }
    }
  }
  ${LevelFragmentFragmentDoc}
` as unknown as DocumentNode<TankLevelsQuery, TankLevelsQueryVariables>;
export const TanksDocument = gql`
  query tanks($limit: Int!, $cursor: String) {
    tanks(limit: $limit, cursor: $cursor) {
      next
      tanks {
        ...TankFragment
        levels(limit: 1) {
          levels {
            ...LevelFragment
          }
        }
        account {
          name
          product
          customer {
            name
          }
          plan {
            code
          }
        }
      }
    }
  }
  ${TankFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${LevelFragmentFragmentDoc}
` as unknown as DocumentNode<TanksQuery, TanksQueryVariables>;
export const TankRolesDocument = gql`
  query tankRoles($tankId: ID!, $limit: Int!, $cursor: String) {
    tankRoles(tankId: $tankId, limit: $limit, cursor: $cursor) {
      roles {
        user {
          ...ActorFragment
        }
      }
      next
    }
  }
  ${ActorFragmentFragmentDoc}
` as unknown as DocumentNode<TankRolesQuery, TankRolesQueryVariables>;
export const UpdateTankDocument = gql`
  mutation updateTank($id: ID!, $fields: TankInput!) {
    updateTank(id: $id, fields: $fields) {
      ...TankFragment
      levels(limit: 1) {
        levels {
          ...LevelFragment
        }
      }
      gauge {
        id
        name
      }
      account {
        id
        name
        product
      }
    }
  }
  ${TankFragmentFragmentDoc}
  ${ActorFragmentFragmentDoc}
  ${LevelFragmentFragmentDoc}
` as unknown as DocumentNode<UpdateTankMutation, UpdateTankMutationVariables>;
export const DeleteUserDocument = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id)
  }
` as unknown as DocumentNode<DeleteUserMutation, DeleteUserMutationVariables>;
export const UserDocument = gql`
  query user($id: ID!) {
    user(id: $id) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
` as unknown as DocumentNode<UserQuery, UserQueryVariables>;
export const UsersDocument = gql`
  query users($limit: Int!, $cursor: String) {
    users(limit: $limit, cursor: $cursor) {
      next
      users {
        ...UserFragment
      }
    }
  }
  ${UserFragmentFragmentDoc}
` as unknown as DocumentNode<UsersQuery, UsersQueryVariables>;
export const UpdateUserDocument = gql`
  mutation updateUser($id: ID!, $fields: UserInput!) {
    updateUser(id: $id, fields: $fields) {
      ...UserFragment
    }
  }
  ${UserFragmentFragmentDoc}
` as unknown as DocumentNode<UpdateUserMutation, UpdateUserMutationVariables>;
