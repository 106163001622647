export const isNullOrUndefined = <E>(
  e: E | null | undefined,
): e is null | undefined => e === null || e === undefined;

export const isNotNullOrUndefined = <E>(e: E | null | undefined): e is E =>
  e !== null && e !== undefined;

export const isNotNullOrUndefinedOrError = <E>(
  e: E | Error | null | undefined,
): e is E => e !== null && e !== undefined && !(e instanceof Error);

export const chunk = <T>(array: T[] | readonly T[], size: number): T[][] => {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const unique = <T>(array: T[]): T[] => Array.from(new Set(array));

export const range = (start: number, end: number) =>
  Array.from({ length: end - start + 1 }, (_, i) => start + i);

export const mapObject = <K extends string, V, R>(
  obj: Record<K, V>,
  fn: (value: V, key: K) => R,
): Record<K, R> => {
  const result = {} as Record<K, R>;
  for (const key in obj) {
    result[key] = fn(obj[key], key);
  }

  return result;
};

export const keys = <K extends string>(obj: Record<K, unknown>) => {
  return Object.keys(obj) as K[];
};

export const entries = <K extends string, V>(obj: Record<K, V>) => {
  return Object.entries(obj) as [K, V][];
};

export const chunkString = (input: string, chunkSize: number) => {
  const length = Math.ceil(input.length / chunkSize);
  return Array.from({ length }, (_, i) =>
    input.substring(i * chunkSize, i * chunkSize + chunkSize),
  );
};

export const mapOrEmpty = <I, R, F>(
  array: I[],
  fn: (i: I, idx: number) => R,
  fallback: F,
) => (array.length ? array.map(fn) : fallback);
