import { IconCurrencyDollar } from "@tabler/icons-react";

import { ListField, SlideContent, TextField } from "../../components";
import { useUpdatePlan } from "../../data";

export const PlanEditContent = ({ planId }: { planId: string }) => {
  const { data, error, form, validators } = useUpdatePlan(planId);

  return (
    <SlideContent
      title={`Update ${data?.code}`}
      description="Update and save the plan information below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Updating..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Update", type: "submit", variant: "action" },
      ]}
    >
      <form.Field
        name="code"
        validators={validators.code}
        children={(field) => (
          <TextField field={field} label="Plan code" autoFocus />
        )}
      />
      <form.Field
        name="amount"
        validators={validators.amount}
        children={(field) => (
          <TextField
            field={field}
            icon={IconCurrencyDollar}
            label="Amount"
            inputMode="decimal"
          />
        )}
      />
      <form.Field
        name="prepaid"
        validators={validators.prepaid}
        children={(field) => (
          <TextField field={field} label="Prepaid Months" inputMode="numeric" />
        )}
      />
      <form.Field
        name="model"
        validators={validators.model}
        children={(field) => (
          <ListField
            field={field}
            label="Pricing Model"
            options={validators.model.onSubmit._def.values}
            optionLabel={(o) => `Per ${o}`}
          />
        )}
      />
    </SlideContent>
  );
};
