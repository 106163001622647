import { useInfiniteQuery, useQuery } from "@tanstack/react-query";
import { Fragment } from "react";

import {
  ComboField,
  Field,
  FieldError,
  Label,
  TextInputField,
} from "../../components";
import { AccountConfigContentProps, displayVolume } from "../../data";
import {
  fairbanksTanksQuery,
  fairbanksUsersQuery,
} from "../../data/api/fairbanks";

const ProGaugeTanks = ({
  form,
  validators,
  userId,
}: AccountConfigContentProps & { userId: string | undefined }) => {
  const fairbanksTanks = useQuery(fairbanksTanksQuery(userId));

  if (fairbanksTanks.data?.length)
    return fairbanksTanks.data.map(({ id, volume }) => (
      <Fragment key={id}>
        <p className="col-span-5 text-sm sm:col-span-1">
          Tank <strong>{id}</strong>
        </p>
        <p className="col-span-1 truncate text-right text-sm">
          {displayVolume(volume, "l")} /
        </p>
        <form.Field
          name={`maximum.${id}`}
          validators={validators.maximumLevel}
          children={(field) => (
            <div className="col-span-4 sm:col-span-3">
              <TextInputField field={field} inputMode="decimal" />
              <FieldError field={field} />
            </div>
          )}
        />
      </Fragment>
    ));

  let text = "Select a User ID to continue...";
  if (userId) text = `No tanks found for "${userId}".`;
  if (fairbanksTanks.isLoading) text = "Loading tanks...";
  if (fairbanksTanks.isError) text = "Failed to load tanks.";

  return (
    <p className="col-span-5 text-sm font-semibold italic text-gray-400">
      {text}
    </p>
  );
};

export const ProGaugeContent = ({
  data,
  form,
  validators,
}: AccountConfigContentProps) => {
  const fairbanksUsers = useInfiniteQuery(fairbanksUsersQuery());

  return (
    <>
      <form.Field
        name="userId"
        validators={validators.userId}
        children={(field) => (
          <ComboField
            field={field}
            label="User ID"
            options={fairbanksUsers.data || []}
            accessors={[(o) => o.value]}
            optionKey={(o) => o.value}
            optionLabel={(o) => o.value}
            optionCreate={(value) => ({ value })}
            immediate
          />
        )}
      />
      <form.Field
        name="timezone"
        validators={validators.timezone}
        children={(field) => (
          <ComboField
            field={field}
            label="Timezone"
            options={Intl.supportedValuesOf("timeZone").map((value) => ({
              value,
            }))}
            accessors={[(o) => o.value]}
            optionKey={(o) => o.value}
            optionLabel={(o) => o.value}
            immediate
          />
        )}
      />
      <form.Subscribe
        selector={(v) => v.values.userId?.value}
        children={(userId) => (
          <Field>
            <Label>Safe Fill Levels</Label>
            <div className="grid grid-cols-5 items-center gap-2 pt-1.5 sm:col-span-2 sm:col-start-2">
              <ProGaugeTanks
                data={data}
                form={form}
                validators={validators}
                userId={userId}
              />
            </div>
          </Field>
        )}
      />
    </>
  );
};
