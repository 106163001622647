import { tw } from "../../assets";

export const cellKinds = {
  stacks: tw`w-full max-w-0 truncate lg:w-[unset] lg:min-w-64`,
  squash: tw`max-w-24 truncate sm:max-w-none`,
  appears: tw`hidden md:table-cell`,
  appearsxl: tw`hidden xl:table-cell`,
  title: tw`group px-5 py-4 text-left text-sm font-semibold`,
};

export const tableParts = {
  table: tw`min-w-full`,
  card: tw`max-h-full overflow-scroll`,
  head: tw`sticky top-0 z-10 bg-white/95`,
  row: tw`whitespace-nowrap border-b border-b-gray-200 px-3 py-4 text-sm text-gray-600 first:text-gray-900`,
};
