import { IconBox, IconUsers } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { brandIcons } from "../../../assets";
import { EntityUpdated, ItemHeader, NotFound, Tabs } from "../../../components";
import { accountQuery, singleItemLoader, useAuth } from "../../../data";
import {
  AccountActionPanel,
  AccountInfoPanel,
  AccountTanksPanel,
  AccountUsersPanel,
} from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["add", "edit", "config", "delete"]).optional(),
  comment: z.string().optional(),
  role: z.string().optional(),
});

export const Route = createFileRoute("/_authenticated/accounts/$accountId")({
  validateSearch,
  component,
  notFoundComponent,
  loader: ({ context, params }) =>
    singleItemLoader(context.queryClient, accountQuery(params.accountId)),
  meta: ({ loaderData }) => [
    {
      title: loaderData.name,
    },
  ],
});

export function notFoundComponent() {
  const { hasTeamPermission } = useAuth();

  return hasTeamPermission("admin") ? (
    <NotFound
      item="Account"
      action={{ to: "/accounts", search: { action: "create" } }}
      actionLabel="Add account"
    />
  ) : (
    <NotFound
      item="Account"
      fix="Head back to the home page to find what you're looking for"
      action={{ to: "/" }}
      actionLabel="Go to home page"
    />
  );
}

export function component() {
  const accountId = Route.useParams({ select: (p) => p.accountId });
  const { data } = useSuspenseQuery(accountQuery(accountId));

  return (
    <>
      <ItemHeader
        image={brandIcons[data.product]}
        title={data.name}
        subtitle={<EntityUpdated {...data} />}
      />
      <Tabs
        name="account"
        tabs={[
          {
            name: "Tanks",
            icon: IconBox,
            panel: <AccountTanksPanel accountId={accountId} />,
          },
          {
            name: "Users",
            icon: IconUsers,
            panel: <AccountUsersPanel accountId={accountId} />,
          },
        ]}
        info={<AccountInfoPanel accountId={accountId} />}
      />
      <AccountActionPanel />
    </>
  );
}
