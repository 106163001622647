import { IconBuildingFactory } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { EntityUpdated, ItemHeader, NotFound, Tabs } from "../../../components";
import { planQuery, singleItemLoader } from "../../../data";
import {
  PlanAccountsPanel,
  PlanActionPanel,
  PlanInfoPanel,
} from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["edit", "delete"]).optional(),
});

export const Route = createFileRoute("/_admin/plans/$planId")({
  validateSearch,
  loader: ({ context, params }) =>
    singleItemLoader(context.queryClient, planQuery(params.planId)),
  component,
  notFoundComponent: () => (
    <NotFound
      item="Plan"
      action={{ to: "/plans", search: { action: "create" } }}
      actionLabel="Add plan"
    />
  ),
  meta: ({ loaderData }) => [
    {
      title: loaderData.code,
    },
  ],
});

export function component() {
  const planId = Route.useParams({ select: (p) => p.planId });
  const { data } = useSuspenseQuery(planQuery(planId));

  return (
    <>
      <ItemHeader title={data.code} subtitle={<EntityUpdated {...data} />} />
      <Tabs
        name="plan"
        tabs={[
          {
            name: "Accounts",
            icon: IconBuildingFactory,
            panel: <PlanAccountsPanel planId={planId} />,
          },
        ]}
        info={<PlanInfoPanel planId={planId} />}
      />
      <PlanActionPanel />
    </>
  );
}
