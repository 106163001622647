import { IconBox } from "@tabler/icons-react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";

import { brandIcons } from "../../../assets";
import { ItemHeader, NotFound, Tabs, Time } from "../../../components";
import { singleItemLoader, tankQuery } from "../../../data";
import {
  TankActionPanel,
  TankInfoPanel,
  TankLevelsPanel,
} from "../../../panels";

const validateSearch = z.object({
  action: z.enum(["edit", "delete", "download"]).optional(),
  comment: z.string().optional(),
});

export const Route = createFileRoute("/_authenticated/tanks/$tankId")({
  validateSearch,
  loader: ({ context, params }) =>
    singleItemLoader(context.queryClient, tankQuery(params.tankId)),
  component,
  notFoundComponent: () => (
    <NotFound
      item="Tank"
      fix="Head back to the home page to find what you're looking for"
      action={{ to: "/" }}
      actionLabel="Go to home page"
    />
  ),
  meta: ({ loaderData }) => [
    {
      title: loaderData.name,
    },
  ],
});

export function component() {
  const tankId = Route.useParams({ select: (p) => p.tankId });
  const { data } = useSuspenseQuery(tankQuery(tankId));

  return (
    <>
      <ItemHeader
        image={brandIcons[data.account.product]}
        title={data.name}
        subtitle={<Time date={data.level?.updatedAt} prefix="Last active " />}
      />
      <Tabs
        name="tank"
        tabs={[
          {
            icon: IconBox,
            name: "Tank",
            panel: <TankLevelsPanel tankId={tankId} />,
          },
        ]}
        info={<TankInfoPanel tankId={tankId} />}
      />
      <TankActionPanel />
    </>
  );
}
