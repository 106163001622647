import { useInfiniteQuery, useQuery } from "@tanstack/react-query";

import {
  CheckInputField,
  ComboField,
  Field,
  Label,
  SlideContent,
  TextField,
} from "../../components";
import {
  RoleEntity,
  accountsQuery,
  customersQuery,
  permissionFields,
  useAuth,
  useCreateRole,
  userQuery,
  usersQuery,
} from "../../data";

const entityTypeLabel = {
  account: "Account",
  customer: "Customer",
};

export const RoleAddContent = ({
  entityId,
  entityType,
  userId,
}: {
  entityId?: string;
  entityType?: RoleEntity;
  userId?: string;
}) => {
  const { hasTeamPermission } = useAuth();
  const user = useQuery(userQuery(userId));
  const accounts = useInfiniteQuery(accountsQuery(!!userId));
  const customers = useInfiniteQuery(customersQuery(!!userId));
  const users = useInfiniteQuery(
    usersQuery(hasTeamPermission("admin") && !userId),
  );
  const { error, form, validators } = useCreateRole({
    entityId,
    entityType,
    userEmail: user.data?.email,
  });
  const entityTypeOptions = {
    account: accounts.data || [],
    customer: customers.data || [],
  };

  return (
    <SlideContent
      title={`Add role`}
      description="Create a new role below."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Adding..." }}
      buttons={[
        { text: "Cancel", variant: "cancel", type: "reset" },
        { text: "Add", type: "submit", variant: "action" },
      ]}
    >
      {userId ? (
        <form.Field
          name="entity"
          validators={validators.entity}
          children={(field) =>
            field.form.getFieldValue("entityType") ? (
              <ComboField
                field={field}
                label={entityTypeLabel[field.form.getFieldValue("entityType")]}
                options={
                  entityTypeOptions[field.form.getFieldValue("entityType")]
                }
                accessors={[(o) => o.name]}
                optionKey={(o) => o.id}
                optionLabel={(o) => o.name}
                autoFocus
              />
            ) : null
          }
        />
      ) : hasTeamPermission("admin") ? (
        <form.Field
          name="user"
          validators={validators.user}
          children={(field) => (
            <ComboField
              field={field}
              label="User"
              options={users.data || []}
              accessors={[(o) => o.email]}
              optionKey={(o) => o.id}
              optionLabel={(o) => o.email}
              optionCreate={(value) => ({
                id: value,
                email: value,
              })}
              autoFocus
            />
          )}
        />
      ) : (
        <form.Field
          name="user.email"
          validators={validators.email}
          children={(field) => (
            <TextField
              field={field}
              label="Email address"
              inputMode="email"
              autoFocus
            />
          )}
        />
      )}
      <Field>
        <Label>Permissions</Label>
        <div className="flex flex-col gap-2 pt-1.5 sm:col-span-2 sm:col-start-2">
          {permissionFields.map(({ name, validators, label, description }) => (
            <form.Field
              key={name}
              name={name}
              validators={validators}
              children={(field) => (
                <CheckInputField
                  field={field}
                  label={label}
                  description={description(entityType)}
                />
              )}
            />
          ))}
        </div>
      </Field>
    </SlideContent>
  );
};
