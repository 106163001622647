import { useMatch } from "@tanstack/react-router";

import { Actions } from "../../components";
import { PlanDeleteContent } from "./delete";
import { PlanEditContent } from "./edit";

export const PlanActionPanel = () => {
  const { Modal, Slide, props } = useMatch({
    from: "/_admin/plans/$planId",
    select: (s) => {
      const props = {
        planId: s.params.planId,
      };

      switch (s.search.action) {
        case "delete":
          return {
            Modal: PlanDeleteContent,
            props,
          };
        case "edit":
          return {
            Slide: PlanEditContent,
            props,
          };
      }

      return { props };
    },
  });

  return (
    <Actions
      modal={Modal && <Modal {...props} />}
      slide={Slide && <Slide {...props} />}
    />
  );
};
