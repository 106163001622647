import { Button, Dialog, DialogBackdrop, DialogPanel } from "@headlessui/react";
import {
  IconAlignLeft,
  IconLogout,
  IconMessageReport,
  IconX,
} from "@tabler/icons-react";
import { Link, LinkProps } from "@tanstack/react-router";
import clsx from "clsx";
import { ReactNode, SetStateAction } from "react";
import { z } from "zod";

import { toTitle } from "@joy/shared-utils";

import { tw } from "../../assets";
import {
  AuthContext,
  account,
  customer,
  document,
  gauge,
  plan,
  tank,
  useAuth,
  user,
} from "../../data";
import { useGlobalPanel, useStorage } from "../../hooks";
import { Avatar } from "../avatar";
import { transitions } from "../transitions";

export type Pages = (LinkProps & {
  Icon: (props: { className: string }) => ReactNode;
  label: string;
  admin?: boolean;
})[];

const pages: Pages = [
  { Icon: tank.icon, label: toTitle(tank.plural), to: "/tanks" },
  { Icon: gauge.icon, label: toTitle(gauge.plural), to: "/gauges" },
  {
    Icon: account.icon,
    label: toTitle(account.plural),
    to: "/accounts",
  },
  {
    Icon: customer.icon,
    label: toTitle(customer.plural),
    to: "/customers",
    admin: true,
  },
  { Icon: plan.icon, label: toTitle(plan.plural), to: "/plans", admin: true },
  {
    Icon: document.icon,
    label: toTitle(document.plural),
    to: "/documents",
    admin: true,
  },
  { Icon: user.icon, label: toTitle(user.plural), to: "/users", admin: true },
];

type SidebarContentProps = {
  user: AuthContext["client"]["user"];
  hasTeamPermission: AuthContext["hasTeamPermission"];
  logout: () => void;
  openFeedback: () => void;
  openProfile: () => void;
};

const sidebarItem = tw`aria-page:bg-emerald-900 aria-page:text-gray-100 flex items-center gap-x-2 overflow-hidden rounded-md p-2 text-gray-400 transition hover:bg-emerald-900 hover:text-gray-100`;

const SidebarContent = ({
  user,
  hasTeamPermission,
  logout,
  openFeedback,
  openProfile,
}: SidebarContentProps) => (
  <>
    <nav aria-label="pages" className="flex flex-1 flex-col gap-y-2">
      {pages.map(({ Icon, label, admin, ...props }) =>
        !admin || hasTeamPermission("admin") ? (
          <Link key={label} {...props} className={sidebarItem}>
            <Icon className="size-6 flex-none" aria-label={label} />
            <span className="truncate text-sm font-semibold">{label}</span>
          </Link>
        ) : null,
      )}
    </nav>
    <nav aria-label="account" className="my-2 flex flex-col gap-y-2">
      <Button className={sidebarItem} onClick={openFeedback}>
        <IconMessageReport className="size-6 flex-none" strokeWidth={2.4} />
        <span className="truncate text-sm font-semibold">
          Support & Feedback
        </span>
      </Button>
      <Button
        onClick={openProfile}
        className={clsx(sidebarItem, "py-1.5 pl-1")}
      >
        <Avatar user={user} image={user?.imageUrl} />
        <span className="truncate text-sm font-semibold">
          {user?.primaryEmailAddress?.emailAddress || "My Profile"}
        </span>
      </Button>
      <Button className={sidebarItem} onClick={logout}>
        <IconLogout className="size-6 flex-none" />
        <span className="truncate text-sm font-semibold">Sign out</span>
      </Button>
    </nav>
  </>
);

const expandedSchema = z.boolean();

export type SidebarProps = {
  open: boolean;
  setOpen: (open: SetStateAction<boolean>) => void;
};

export const Sidebar = ({ open, setOpen }: SidebarProps) => {
  const { isAuthenticated, hasTeamPermission, client } = useAuth();
  const { captureScreenshot, setPanel } = useGlobalPanel();
  const [expanded, setExpanded] = useStorage({
    defaultValue: false,
    key: "sidebar-expanded",
    schema: expandedSchema,
    storage: "local",
  });

  if (!isAuthenticated) return null;

  const contentOptions = {
    hasTeamPermission,
    setOpen,
    openFeedback: () => {
      setOpen(false);
      captureScreenshot();
      setPanel("feedback");
    },
    openProfile: () => {
      setOpen(false);
      setPanel("profile");
    },
    logout: client.signOut,
    user: client.user,
  };

  return (
    <>
      {/* Static Desktop Sidebar */}
      <aside
        aria-expanded={expanded}
        className="relative hidden h-full w-64 max-w-14 flex-col gap-y-5 overflow-y-auto overflow-x-hidden bg-emerald-950 p-2 transition-all aria-expanded:max-w-64 lg:flex"
      >
        <button
          className="flex size-10 shrink-0 items-center justify-center text-gray-100"
          onClick={() => setExpanded((o) => !o)}
        >
          <IconAlignLeft size={30} aria-label="Toggle Sidebar" />
          <div className="sr-only">Toggle Sidebar</div>
        </button>
        <SidebarContent {...contentOptions} />
      </aside>

      {/* Overlay Mobile Sidebar */}
      <Dialog className="relative z-50 lg:hidden" open={open} onClose={setOpen}>
        <DialogBackdrop
          transition
          className={clsx("fixed inset-0 bg-gray-900/80", transitions.fade)}
        />
        <div className="fixed inset-0 flex">
          <DialogPanel
            transition
            className={clsx(
              "group relative mr-16 flex w-full max-w-64 flex-1",
              transitions.sidebar,
            )}
          >
            <div
              className={clsx(
                "absolute left-full top-0 flex w-16 justify-center pt-5 duration-100 group-data-[closed]:opacity-0",
              )}
            >
              <button
                type="button"
                className="-m-2.5 p-2.5"
                onClick={() => setOpen(false)}
              >
                <span className="sr-only">Close sidebar</span>
                <IconX size={24} className="text-gray-100" />
              </button>
            </div>
            <aside
              aria-expanded={open}
              className="flex flex-1 flex-col gap-y-5 overflow-y-auto overflow-x-hidden bg-emerald-950 p-4 shadow-inner transition-all"
            >
              <SidebarContent {...contentOptions} />
            </aside>
          </DialogPanel>
        </div>
      </Dialog>
    </>
  );
};
