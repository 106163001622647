/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from "./../routes/__root";
import { Route as AuthenticatedImport } from "./../routes/_authenticated";
import { Route as AdminImport } from "./../routes/_admin";
import { Route as IndexImport } from "./../routes/index";
import { Route as UnauthenticatedTermsImport } from "./../routes/_unauthenticated/terms";
import { Route as UnauthenticatedPrivacyImport } from "./../routes/_unauthenticated/privacy";
import { Route as UnauthenticatedLoginRouteImport } from "./../routes/_unauthenticated/login/route";
import { Route as AuthenticatedTanksRouteImport } from "./../routes/_authenticated/tanks/route";
import { Route as AuthenticatedGaugesRouteImport } from "./../routes/_authenticated/gauges/route";
import { Route as AuthenticatedAccountsRouteImport } from "./../routes/_authenticated/accounts/route";
import { Route as AdminUsersRouteImport } from "./../routes/_admin/users/route";
import { Route as AdminPlansRouteImport } from "./../routes/_admin/plans/route";
import { Route as AdminDocumentsRouteImport } from "./../routes/_admin/documents/route";
import { Route as AdminCustomersRouteImport } from "./../routes/_admin/customers/route";
import { Route as UnauthenticatedLoginIndexImport } from "./../routes/_unauthenticated/login/index";
import { Route as AuthenticatedTanksIndexImport } from "./../routes/_authenticated/tanks/index";
import { Route as AuthenticatedGaugesIndexImport } from "./../routes/_authenticated/gauges/index";
import { Route as AuthenticatedAccountsIndexImport } from "./../routes/_authenticated/accounts/index";
import { Route as AdminUsersIndexImport } from "./../routes/_admin/users/index";
import { Route as AdminPlansIndexImport } from "./../routes/_admin/plans/index";
import { Route as AdminDocumentsIndexImport } from "./../routes/_admin/documents/index";
import { Route as AdminCustomersIndexImport } from "./../routes/_admin/customers/index";
import { Route as UnauthenticatedLoginRegisterImport } from "./../routes/_unauthenticated/login/register";
import { Route as UnauthenticatedLoginForgotImport } from "./../routes/_unauthenticated/login/forgot";
import { Route as AuthenticatedTanksTankIdImport } from "./../routes/_authenticated/tanks/$tankId";
import { Route as AuthenticatedGaugesGaugeIdImport } from "./../routes/_authenticated/gauges/$gaugeId";
import { Route as AuthenticatedAccountsAccountIdImport } from "./../routes/_authenticated/accounts/$accountId";
import { Route as AdminUsersUserIdImport } from "./../routes/_admin/users/$userId";
import { Route as AdminPlansPlanIdImport } from "./../routes/_admin/plans/$planId";
import { Route as AdminDocumentsDocumentIdImport } from "./../routes/_admin/documents/$documentId";
import { Route as AdminCustomersCustomerIdImport } from "./../routes/_admin/customers/$customerId";

// Create/Update Routes

const AuthenticatedRoute = AuthenticatedImport.update({
  id: "/_authenticated",
  getParentRoute: () => rootRoute,
} as any);

const AdminRoute = AdminImport.update({
  id: "/_admin",
  getParentRoute: () => rootRoute,
} as any);

const IndexRoute = IndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => rootRoute,
} as any);

const UnauthenticatedTermsRoute = UnauthenticatedTermsImport.update({
  id: "/_unauthenticated/terms",
  path: "/terms",
  getParentRoute: () => rootRoute,
} as any);

const UnauthenticatedPrivacyRoute = UnauthenticatedPrivacyImport.update({
  id: "/_unauthenticated/privacy",
  path: "/privacy",
  getParentRoute: () => rootRoute,
} as any);

const UnauthenticatedLoginRouteRoute = UnauthenticatedLoginRouteImport.update({
  id: "/_unauthenticated/login",
  path: "/login",
  getParentRoute: () => rootRoute,
} as any);

const AuthenticatedTanksRouteRoute = AuthenticatedTanksRouteImport.update({
  id: "/tanks",
  path: "/tanks",
  getParentRoute: () => AuthenticatedRoute,
} as any);

const AuthenticatedGaugesRouteRoute = AuthenticatedGaugesRouteImport.update({
  id: "/gauges",
  path: "/gauges",
  getParentRoute: () => AuthenticatedRoute,
} as any);

const AuthenticatedAccountsRouteRoute = AuthenticatedAccountsRouteImport.update(
  {
    id: "/accounts",
    path: "/accounts",
    getParentRoute: () => AuthenticatedRoute,
  } as any,
);

const AdminUsersRouteRoute = AdminUsersRouteImport.update({
  id: "/users",
  path: "/users",
  getParentRoute: () => AdminRoute,
} as any);

const AdminPlansRouteRoute = AdminPlansRouteImport.update({
  id: "/plans",
  path: "/plans",
  getParentRoute: () => AdminRoute,
} as any);

const AdminDocumentsRouteRoute = AdminDocumentsRouteImport.update({
  id: "/documents",
  path: "/documents",
  getParentRoute: () => AdminRoute,
} as any);

const AdminCustomersRouteRoute = AdminCustomersRouteImport.update({
  id: "/customers",
  path: "/customers",
  getParentRoute: () => AdminRoute,
} as any);

const UnauthenticatedLoginIndexRoute = UnauthenticatedLoginIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => UnauthenticatedLoginRouteRoute,
} as any);

const AuthenticatedTanksIndexRoute = AuthenticatedTanksIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AuthenticatedTanksRouteRoute,
} as any);

const AuthenticatedGaugesIndexRoute = AuthenticatedGaugesIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AuthenticatedGaugesRouteRoute,
} as any);

const AuthenticatedAccountsIndexRoute = AuthenticatedAccountsIndexImport.update(
  {
    id: "/",
    path: "/",
    getParentRoute: () => AuthenticatedAccountsRouteRoute,
  } as any,
);

const AdminUsersIndexRoute = AdminUsersIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AdminUsersRouteRoute,
} as any);

const AdminPlansIndexRoute = AdminPlansIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AdminPlansRouteRoute,
} as any);

const AdminDocumentsIndexRoute = AdminDocumentsIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AdminDocumentsRouteRoute,
} as any);

const AdminCustomersIndexRoute = AdminCustomersIndexImport.update({
  id: "/",
  path: "/",
  getParentRoute: () => AdminCustomersRouteRoute,
} as any);

const UnauthenticatedLoginRegisterRoute =
  UnauthenticatedLoginRegisterImport.update({
    id: "/register",
    path: "/register",
    getParentRoute: () => UnauthenticatedLoginRouteRoute,
  } as any);

const UnauthenticatedLoginForgotRoute = UnauthenticatedLoginForgotImport.update(
  {
    id: "/forgot",
    path: "/forgot",
    getParentRoute: () => UnauthenticatedLoginRouteRoute,
  } as any,
);

const AuthenticatedTanksTankIdRoute = AuthenticatedTanksTankIdImport.update({
  id: "/$tankId",
  path: "/$tankId",
  getParentRoute: () => AuthenticatedTanksRouteRoute,
} as any);

const AuthenticatedGaugesGaugeIdRoute = AuthenticatedGaugesGaugeIdImport.update(
  {
    id: "/$gaugeId",
    path: "/$gaugeId",
    getParentRoute: () => AuthenticatedGaugesRouteRoute,
  } as any,
);

const AuthenticatedAccountsAccountIdRoute =
  AuthenticatedAccountsAccountIdImport.update({
    id: "/$accountId",
    path: "/$accountId",
    getParentRoute: () => AuthenticatedAccountsRouteRoute,
  } as any);

const AdminUsersUserIdRoute = AdminUsersUserIdImport.update({
  id: "/$userId",
  path: "/$userId",
  getParentRoute: () => AdminUsersRouteRoute,
} as any);

const AdminPlansPlanIdRoute = AdminPlansPlanIdImport.update({
  id: "/$planId",
  path: "/$planId",
  getParentRoute: () => AdminPlansRouteRoute,
} as any);

const AdminDocumentsDocumentIdRoute = AdminDocumentsDocumentIdImport.update({
  id: "/$documentId",
  path: "/$documentId",
  getParentRoute: () => AdminDocumentsRouteRoute,
} as any);

const AdminCustomersCustomerIdRoute = AdminCustomersCustomerIdImport.update({
  id: "/$customerId",
  path: "/$customerId",
  getParentRoute: () => AdminCustomersRouteRoute,
} as any);

// Populate the FileRoutesByPath interface

declare module "@tanstack/react-router" {
  interface FileRoutesByPath {
    "/": {
      id: "/";
      path: "/";
      fullPath: "/";
      preLoaderRoute: typeof IndexImport;
      parentRoute: typeof rootRoute;
    };
    "/_admin": {
      id: "/_admin";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof AdminImport;
      parentRoute: typeof rootRoute;
    };
    "/_authenticated": {
      id: "/_authenticated";
      path: "";
      fullPath: "";
      preLoaderRoute: typeof AuthenticatedImport;
      parentRoute: typeof rootRoute;
    };
    "/_admin/customers": {
      id: "/_admin/customers";
      path: "/customers";
      fullPath: "/customers";
      preLoaderRoute: typeof AdminCustomersRouteImport;
      parentRoute: typeof AdminImport;
    };
    "/_admin/documents": {
      id: "/_admin/documents";
      path: "/documents";
      fullPath: "/documents";
      preLoaderRoute: typeof AdminDocumentsRouteImport;
      parentRoute: typeof AdminImport;
    };
    "/_admin/plans": {
      id: "/_admin/plans";
      path: "/plans";
      fullPath: "/plans";
      preLoaderRoute: typeof AdminPlansRouteImport;
      parentRoute: typeof AdminImport;
    };
    "/_admin/users": {
      id: "/_admin/users";
      path: "/users";
      fullPath: "/users";
      preLoaderRoute: typeof AdminUsersRouteImport;
      parentRoute: typeof AdminImport;
    };
    "/_authenticated/accounts": {
      id: "/_authenticated/accounts";
      path: "/accounts";
      fullPath: "/accounts";
      preLoaderRoute: typeof AuthenticatedAccountsRouteImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/gauges": {
      id: "/_authenticated/gauges";
      path: "/gauges";
      fullPath: "/gauges";
      preLoaderRoute: typeof AuthenticatedGaugesRouteImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_authenticated/tanks": {
      id: "/_authenticated/tanks";
      path: "/tanks";
      fullPath: "/tanks";
      preLoaderRoute: typeof AuthenticatedTanksRouteImport;
      parentRoute: typeof AuthenticatedImport;
    };
    "/_unauthenticated/login": {
      id: "/_unauthenticated/login";
      path: "/login";
      fullPath: "/login";
      preLoaderRoute: typeof UnauthenticatedLoginRouteImport;
      parentRoute: typeof rootRoute;
    };
    "/_unauthenticated/privacy": {
      id: "/_unauthenticated/privacy";
      path: "/privacy";
      fullPath: "/privacy";
      preLoaderRoute: typeof UnauthenticatedPrivacyImport;
      parentRoute: typeof rootRoute;
    };
    "/_unauthenticated/terms": {
      id: "/_unauthenticated/terms";
      path: "/terms";
      fullPath: "/terms";
      preLoaderRoute: typeof UnauthenticatedTermsImport;
      parentRoute: typeof rootRoute;
    };
    "/_admin/customers/$customerId": {
      id: "/_admin/customers/$customerId";
      path: "/$customerId";
      fullPath: "/customers/$customerId";
      preLoaderRoute: typeof AdminCustomersCustomerIdImport;
      parentRoute: typeof AdminCustomersRouteImport;
    };
    "/_admin/documents/$documentId": {
      id: "/_admin/documents/$documentId";
      path: "/$documentId";
      fullPath: "/documents/$documentId";
      preLoaderRoute: typeof AdminDocumentsDocumentIdImport;
      parentRoute: typeof AdminDocumentsRouteImport;
    };
    "/_admin/plans/$planId": {
      id: "/_admin/plans/$planId";
      path: "/$planId";
      fullPath: "/plans/$planId";
      preLoaderRoute: typeof AdminPlansPlanIdImport;
      parentRoute: typeof AdminPlansRouteImport;
    };
    "/_admin/users/$userId": {
      id: "/_admin/users/$userId";
      path: "/$userId";
      fullPath: "/users/$userId";
      preLoaderRoute: typeof AdminUsersUserIdImport;
      parentRoute: typeof AdminUsersRouteImport;
    };
    "/_authenticated/accounts/$accountId": {
      id: "/_authenticated/accounts/$accountId";
      path: "/$accountId";
      fullPath: "/accounts/$accountId";
      preLoaderRoute: typeof AuthenticatedAccountsAccountIdImport;
      parentRoute: typeof AuthenticatedAccountsRouteImport;
    };
    "/_authenticated/gauges/$gaugeId": {
      id: "/_authenticated/gauges/$gaugeId";
      path: "/$gaugeId";
      fullPath: "/gauges/$gaugeId";
      preLoaderRoute: typeof AuthenticatedGaugesGaugeIdImport;
      parentRoute: typeof AuthenticatedGaugesRouteImport;
    };
    "/_authenticated/tanks/$tankId": {
      id: "/_authenticated/tanks/$tankId";
      path: "/$tankId";
      fullPath: "/tanks/$tankId";
      preLoaderRoute: typeof AuthenticatedTanksTankIdImport;
      parentRoute: typeof AuthenticatedTanksRouteImport;
    };
    "/_unauthenticated/login/forgot": {
      id: "/_unauthenticated/login/forgot";
      path: "/forgot";
      fullPath: "/login/forgot";
      preLoaderRoute: typeof UnauthenticatedLoginForgotImport;
      parentRoute: typeof UnauthenticatedLoginRouteImport;
    };
    "/_unauthenticated/login/register": {
      id: "/_unauthenticated/login/register";
      path: "/register";
      fullPath: "/login/register";
      preLoaderRoute: typeof UnauthenticatedLoginRegisterImport;
      parentRoute: typeof UnauthenticatedLoginRouteImport;
    };
    "/_admin/customers/": {
      id: "/_admin/customers/";
      path: "/";
      fullPath: "/customers/";
      preLoaderRoute: typeof AdminCustomersIndexImport;
      parentRoute: typeof AdminCustomersRouteImport;
    };
    "/_admin/documents/": {
      id: "/_admin/documents/";
      path: "/";
      fullPath: "/documents/";
      preLoaderRoute: typeof AdminDocumentsIndexImport;
      parentRoute: typeof AdminDocumentsRouteImport;
    };
    "/_admin/plans/": {
      id: "/_admin/plans/";
      path: "/";
      fullPath: "/plans/";
      preLoaderRoute: typeof AdminPlansIndexImport;
      parentRoute: typeof AdminPlansRouteImport;
    };
    "/_admin/users/": {
      id: "/_admin/users/";
      path: "/";
      fullPath: "/users/";
      preLoaderRoute: typeof AdminUsersIndexImport;
      parentRoute: typeof AdminUsersRouteImport;
    };
    "/_authenticated/accounts/": {
      id: "/_authenticated/accounts/";
      path: "/";
      fullPath: "/accounts/";
      preLoaderRoute: typeof AuthenticatedAccountsIndexImport;
      parentRoute: typeof AuthenticatedAccountsRouteImport;
    };
    "/_authenticated/gauges/": {
      id: "/_authenticated/gauges/";
      path: "/";
      fullPath: "/gauges/";
      preLoaderRoute: typeof AuthenticatedGaugesIndexImport;
      parentRoute: typeof AuthenticatedGaugesRouteImport;
    };
    "/_authenticated/tanks/": {
      id: "/_authenticated/tanks/";
      path: "/";
      fullPath: "/tanks/";
      preLoaderRoute: typeof AuthenticatedTanksIndexImport;
      parentRoute: typeof AuthenticatedTanksRouteImport;
    };
    "/_unauthenticated/login/": {
      id: "/_unauthenticated/login/";
      path: "/";
      fullPath: "/login/";
      preLoaderRoute: typeof UnauthenticatedLoginIndexImport;
      parentRoute: typeof UnauthenticatedLoginRouteImport;
    };
  }
}

// Create and export the route tree

interface AdminCustomersRouteRouteChildren {
  AdminCustomersCustomerIdRoute: typeof AdminCustomersCustomerIdRoute;
  AdminCustomersIndexRoute: typeof AdminCustomersIndexRoute;
}

const AdminCustomersRouteRouteChildren: AdminCustomersRouteRouteChildren = {
  AdminCustomersCustomerIdRoute: AdminCustomersCustomerIdRoute,
  AdminCustomersIndexRoute: AdminCustomersIndexRoute,
};

const AdminCustomersRouteRouteWithChildren =
  AdminCustomersRouteRoute._addFileChildren(AdminCustomersRouteRouteChildren);

interface AdminDocumentsRouteRouteChildren {
  AdminDocumentsDocumentIdRoute: typeof AdminDocumentsDocumentIdRoute;
  AdminDocumentsIndexRoute: typeof AdminDocumentsIndexRoute;
}

const AdminDocumentsRouteRouteChildren: AdminDocumentsRouteRouteChildren = {
  AdminDocumentsDocumentIdRoute: AdminDocumentsDocumentIdRoute,
  AdminDocumentsIndexRoute: AdminDocumentsIndexRoute,
};

const AdminDocumentsRouteRouteWithChildren =
  AdminDocumentsRouteRoute._addFileChildren(AdminDocumentsRouteRouteChildren);

interface AdminPlansRouteRouteChildren {
  AdminPlansPlanIdRoute: typeof AdminPlansPlanIdRoute;
  AdminPlansIndexRoute: typeof AdminPlansIndexRoute;
}

const AdminPlansRouteRouteChildren: AdminPlansRouteRouteChildren = {
  AdminPlansPlanIdRoute: AdminPlansPlanIdRoute,
  AdminPlansIndexRoute: AdminPlansIndexRoute,
};

const AdminPlansRouteRouteWithChildren = AdminPlansRouteRoute._addFileChildren(
  AdminPlansRouteRouteChildren,
);

interface AdminUsersRouteRouteChildren {
  AdminUsersUserIdRoute: typeof AdminUsersUserIdRoute;
  AdminUsersIndexRoute: typeof AdminUsersIndexRoute;
}

const AdminUsersRouteRouteChildren: AdminUsersRouteRouteChildren = {
  AdminUsersUserIdRoute: AdminUsersUserIdRoute,
  AdminUsersIndexRoute: AdminUsersIndexRoute,
};

const AdminUsersRouteRouteWithChildren = AdminUsersRouteRoute._addFileChildren(
  AdminUsersRouteRouteChildren,
);

interface AdminRouteChildren {
  AdminCustomersRouteRoute: typeof AdminCustomersRouteRouteWithChildren;
  AdminDocumentsRouteRoute: typeof AdminDocumentsRouteRouteWithChildren;
  AdminPlansRouteRoute: typeof AdminPlansRouteRouteWithChildren;
  AdminUsersRouteRoute: typeof AdminUsersRouteRouteWithChildren;
}

const AdminRouteChildren: AdminRouteChildren = {
  AdminCustomersRouteRoute: AdminCustomersRouteRouteWithChildren,
  AdminDocumentsRouteRoute: AdminDocumentsRouteRouteWithChildren,
  AdminPlansRouteRoute: AdminPlansRouteRouteWithChildren,
  AdminUsersRouteRoute: AdminUsersRouteRouteWithChildren,
};

const AdminRouteWithChildren = AdminRoute._addFileChildren(AdminRouteChildren);

interface AuthenticatedAccountsRouteRouteChildren {
  AuthenticatedAccountsAccountIdRoute: typeof AuthenticatedAccountsAccountIdRoute;
  AuthenticatedAccountsIndexRoute: typeof AuthenticatedAccountsIndexRoute;
}

const AuthenticatedAccountsRouteRouteChildren: AuthenticatedAccountsRouteRouteChildren =
  {
    AuthenticatedAccountsAccountIdRoute: AuthenticatedAccountsAccountIdRoute,
    AuthenticatedAccountsIndexRoute: AuthenticatedAccountsIndexRoute,
  };

const AuthenticatedAccountsRouteRouteWithChildren =
  AuthenticatedAccountsRouteRoute._addFileChildren(
    AuthenticatedAccountsRouteRouteChildren,
  );

interface AuthenticatedGaugesRouteRouteChildren {
  AuthenticatedGaugesGaugeIdRoute: typeof AuthenticatedGaugesGaugeIdRoute;
  AuthenticatedGaugesIndexRoute: typeof AuthenticatedGaugesIndexRoute;
}

const AuthenticatedGaugesRouteRouteChildren: AuthenticatedGaugesRouteRouteChildren =
  {
    AuthenticatedGaugesGaugeIdRoute: AuthenticatedGaugesGaugeIdRoute,
    AuthenticatedGaugesIndexRoute: AuthenticatedGaugesIndexRoute,
  };

const AuthenticatedGaugesRouteRouteWithChildren =
  AuthenticatedGaugesRouteRoute._addFileChildren(
    AuthenticatedGaugesRouteRouteChildren,
  );

interface AuthenticatedTanksRouteRouteChildren {
  AuthenticatedTanksTankIdRoute: typeof AuthenticatedTanksTankIdRoute;
  AuthenticatedTanksIndexRoute: typeof AuthenticatedTanksIndexRoute;
}

const AuthenticatedTanksRouteRouteChildren: AuthenticatedTanksRouteRouteChildren =
  {
    AuthenticatedTanksTankIdRoute: AuthenticatedTanksTankIdRoute,
    AuthenticatedTanksIndexRoute: AuthenticatedTanksIndexRoute,
  };

const AuthenticatedTanksRouteRouteWithChildren =
  AuthenticatedTanksRouteRoute._addFileChildren(
    AuthenticatedTanksRouteRouteChildren,
  );

interface AuthenticatedRouteChildren {
  AuthenticatedAccountsRouteRoute: typeof AuthenticatedAccountsRouteRouteWithChildren;
  AuthenticatedGaugesRouteRoute: typeof AuthenticatedGaugesRouteRouteWithChildren;
  AuthenticatedTanksRouteRoute: typeof AuthenticatedTanksRouteRouteWithChildren;
}

const AuthenticatedRouteChildren: AuthenticatedRouteChildren = {
  AuthenticatedAccountsRouteRoute: AuthenticatedAccountsRouteRouteWithChildren,
  AuthenticatedGaugesRouteRoute: AuthenticatedGaugesRouteRouteWithChildren,
  AuthenticatedTanksRouteRoute: AuthenticatedTanksRouteRouteWithChildren,
};

const AuthenticatedRouteWithChildren = AuthenticatedRoute._addFileChildren(
  AuthenticatedRouteChildren,
);

interface UnauthenticatedLoginRouteRouteChildren {
  UnauthenticatedLoginForgotRoute: typeof UnauthenticatedLoginForgotRoute;
  UnauthenticatedLoginRegisterRoute: typeof UnauthenticatedLoginRegisterRoute;
  UnauthenticatedLoginIndexRoute: typeof UnauthenticatedLoginIndexRoute;
}

const UnauthenticatedLoginRouteRouteChildren: UnauthenticatedLoginRouteRouteChildren =
  {
    UnauthenticatedLoginForgotRoute: UnauthenticatedLoginForgotRoute,
    UnauthenticatedLoginRegisterRoute: UnauthenticatedLoginRegisterRoute,
    UnauthenticatedLoginIndexRoute: UnauthenticatedLoginIndexRoute,
  };

const UnauthenticatedLoginRouteRouteWithChildren =
  UnauthenticatedLoginRouteRoute._addFileChildren(
    UnauthenticatedLoginRouteRouteChildren,
  );

export interface FileRoutesByFullPath {
  "/": typeof IndexRoute;
  "": typeof AuthenticatedRouteWithChildren;
  "/customers": typeof AdminCustomersRouteRouteWithChildren;
  "/documents": typeof AdminDocumentsRouteRouteWithChildren;
  "/plans": typeof AdminPlansRouteRouteWithChildren;
  "/users": typeof AdminUsersRouteRouteWithChildren;
  "/accounts": typeof AuthenticatedAccountsRouteRouteWithChildren;
  "/gauges": typeof AuthenticatedGaugesRouteRouteWithChildren;
  "/tanks": typeof AuthenticatedTanksRouteRouteWithChildren;
  "/login": typeof UnauthenticatedLoginRouteRouteWithChildren;
  "/privacy": typeof UnauthenticatedPrivacyRoute;
  "/terms": typeof UnauthenticatedTermsRoute;
  "/customers/$customerId": typeof AdminCustomersCustomerIdRoute;
  "/documents/$documentId": typeof AdminDocumentsDocumentIdRoute;
  "/plans/$planId": typeof AdminPlansPlanIdRoute;
  "/users/$userId": typeof AdminUsersUserIdRoute;
  "/accounts/$accountId": typeof AuthenticatedAccountsAccountIdRoute;
  "/gauges/$gaugeId": typeof AuthenticatedGaugesGaugeIdRoute;
  "/tanks/$tankId": typeof AuthenticatedTanksTankIdRoute;
  "/login/forgot": typeof UnauthenticatedLoginForgotRoute;
  "/login/register": typeof UnauthenticatedLoginRegisterRoute;
  "/customers/": typeof AdminCustomersIndexRoute;
  "/documents/": typeof AdminDocumentsIndexRoute;
  "/plans/": typeof AdminPlansIndexRoute;
  "/users/": typeof AdminUsersIndexRoute;
  "/accounts/": typeof AuthenticatedAccountsIndexRoute;
  "/gauges/": typeof AuthenticatedGaugesIndexRoute;
  "/tanks/": typeof AuthenticatedTanksIndexRoute;
  "/login/": typeof UnauthenticatedLoginIndexRoute;
}

export interface FileRoutesByTo {
  "/": typeof IndexRoute;
  "": typeof AuthenticatedRouteWithChildren;
  "/privacy": typeof UnauthenticatedPrivacyRoute;
  "/terms": typeof UnauthenticatedTermsRoute;
  "/customers/$customerId": typeof AdminCustomersCustomerIdRoute;
  "/documents/$documentId": typeof AdminDocumentsDocumentIdRoute;
  "/plans/$planId": typeof AdminPlansPlanIdRoute;
  "/users/$userId": typeof AdminUsersUserIdRoute;
  "/accounts/$accountId": typeof AuthenticatedAccountsAccountIdRoute;
  "/gauges/$gaugeId": typeof AuthenticatedGaugesGaugeIdRoute;
  "/tanks/$tankId": typeof AuthenticatedTanksTankIdRoute;
  "/login/forgot": typeof UnauthenticatedLoginForgotRoute;
  "/login/register": typeof UnauthenticatedLoginRegisterRoute;
  "/customers": typeof AdminCustomersIndexRoute;
  "/documents": typeof AdminDocumentsIndexRoute;
  "/plans": typeof AdminPlansIndexRoute;
  "/users": typeof AdminUsersIndexRoute;
  "/accounts": typeof AuthenticatedAccountsIndexRoute;
  "/gauges": typeof AuthenticatedGaugesIndexRoute;
  "/tanks": typeof AuthenticatedTanksIndexRoute;
  "/login": typeof UnauthenticatedLoginIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  "/": typeof IndexRoute;
  "/_admin": typeof AdminRouteWithChildren;
  "/_authenticated": typeof AuthenticatedRouteWithChildren;
  "/_admin/customers": typeof AdminCustomersRouteRouteWithChildren;
  "/_admin/documents": typeof AdminDocumentsRouteRouteWithChildren;
  "/_admin/plans": typeof AdminPlansRouteRouteWithChildren;
  "/_admin/users": typeof AdminUsersRouteRouteWithChildren;
  "/_authenticated/accounts": typeof AuthenticatedAccountsRouteRouteWithChildren;
  "/_authenticated/gauges": typeof AuthenticatedGaugesRouteRouteWithChildren;
  "/_authenticated/tanks": typeof AuthenticatedTanksRouteRouteWithChildren;
  "/_unauthenticated/login": typeof UnauthenticatedLoginRouteRouteWithChildren;
  "/_unauthenticated/privacy": typeof UnauthenticatedPrivacyRoute;
  "/_unauthenticated/terms": typeof UnauthenticatedTermsRoute;
  "/_admin/customers/$customerId": typeof AdminCustomersCustomerIdRoute;
  "/_admin/documents/$documentId": typeof AdminDocumentsDocumentIdRoute;
  "/_admin/plans/$planId": typeof AdminPlansPlanIdRoute;
  "/_admin/users/$userId": typeof AdminUsersUserIdRoute;
  "/_authenticated/accounts/$accountId": typeof AuthenticatedAccountsAccountIdRoute;
  "/_authenticated/gauges/$gaugeId": typeof AuthenticatedGaugesGaugeIdRoute;
  "/_authenticated/tanks/$tankId": typeof AuthenticatedTanksTankIdRoute;
  "/_unauthenticated/login/forgot": typeof UnauthenticatedLoginForgotRoute;
  "/_unauthenticated/login/register": typeof UnauthenticatedLoginRegisterRoute;
  "/_admin/customers/": typeof AdminCustomersIndexRoute;
  "/_admin/documents/": typeof AdminDocumentsIndexRoute;
  "/_admin/plans/": typeof AdminPlansIndexRoute;
  "/_admin/users/": typeof AdminUsersIndexRoute;
  "/_authenticated/accounts/": typeof AuthenticatedAccountsIndexRoute;
  "/_authenticated/gauges/": typeof AuthenticatedGaugesIndexRoute;
  "/_authenticated/tanks/": typeof AuthenticatedTanksIndexRoute;
  "/_unauthenticated/login/": typeof UnauthenticatedLoginIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | "/"
    | ""
    | "/customers"
    | "/documents"
    | "/plans"
    | "/users"
    | "/accounts"
    | "/gauges"
    | "/tanks"
    | "/login"
    | "/privacy"
    | "/terms"
    | "/customers/$customerId"
    | "/documents/$documentId"
    | "/plans/$planId"
    | "/users/$userId"
    | "/accounts/$accountId"
    | "/gauges/$gaugeId"
    | "/tanks/$tankId"
    | "/login/forgot"
    | "/login/register"
    | "/customers/"
    | "/documents/"
    | "/plans/"
    | "/users/"
    | "/accounts/"
    | "/gauges/"
    | "/tanks/"
    | "/login/";
  fileRoutesByTo: FileRoutesByTo;
  to:
    | "/"
    | ""
    | "/privacy"
    | "/terms"
    | "/customers/$customerId"
    | "/documents/$documentId"
    | "/plans/$planId"
    | "/users/$userId"
    | "/accounts/$accountId"
    | "/gauges/$gaugeId"
    | "/tanks/$tankId"
    | "/login/forgot"
    | "/login/register"
    | "/customers"
    | "/documents"
    | "/plans"
    | "/users"
    | "/accounts"
    | "/gauges"
    | "/tanks"
    | "/login";
  id:
    | "__root__"
    | "/"
    | "/_admin"
    | "/_authenticated"
    | "/_admin/customers"
    | "/_admin/documents"
    | "/_admin/plans"
    | "/_admin/users"
    | "/_authenticated/accounts"
    | "/_authenticated/gauges"
    | "/_authenticated/tanks"
    | "/_unauthenticated/login"
    | "/_unauthenticated/privacy"
    | "/_unauthenticated/terms"
    | "/_admin/customers/$customerId"
    | "/_admin/documents/$documentId"
    | "/_admin/plans/$planId"
    | "/_admin/users/$userId"
    | "/_authenticated/accounts/$accountId"
    | "/_authenticated/gauges/$gaugeId"
    | "/_authenticated/tanks/$tankId"
    | "/_unauthenticated/login/forgot"
    | "/_unauthenticated/login/register"
    | "/_admin/customers/"
    | "/_admin/documents/"
    | "/_admin/plans/"
    | "/_admin/users/"
    | "/_authenticated/accounts/"
    | "/_authenticated/gauges/"
    | "/_authenticated/tanks/"
    | "/_unauthenticated/login/";
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute;
  AdminRoute: typeof AdminRouteWithChildren;
  AuthenticatedRoute: typeof AuthenticatedRouteWithChildren;
  UnauthenticatedLoginRouteRoute: typeof UnauthenticatedLoginRouteRouteWithChildren;
  UnauthenticatedPrivacyRoute: typeof UnauthenticatedPrivacyRoute;
  UnauthenticatedTermsRoute: typeof UnauthenticatedTermsRoute;
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  AdminRoute: AdminRouteWithChildren,
  AuthenticatedRoute: AuthenticatedRouteWithChildren,
  UnauthenticatedLoginRouteRoute: UnauthenticatedLoginRouteRouteWithChildren,
  UnauthenticatedPrivacyRoute: UnauthenticatedPrivacyRoute,
  UnauthenticatedTermsRoute: UnauthenticatedTermsRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/_admin",
        "/_authenticated",
        "/_unauthenticated/login",
        "/_unauthenticated/privacy",
        "/_unauthenticated/terms"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/_admin": {
      "filePath": "_admin.tsx",
      "children": [
        "/_admin/customers",
        "/_admin/documents",
        "/_admin/plans",
        "/_admin/users"
      ]
    },
    "/_authenticated": {
      "filePath": "_authenticated.tsx",
      "children": [
        "/_authenticated/accounts",
        "/_authenticated/gauges",
        "/_authenticated/tanks"
      ]
    },
    "/_admin/customers": {
      "filePath": "_admin/customers/route.tsx",
      "parent": "/_admin",
      "children": [
        "/_admin/customers/$customerId",
        "/_admin/customers/"
      ]
    },
    "/_admin/documents": {
      "filePath": "_admin/documents/route.tsx",
      "parent": "/_admin",
      "children": [
        "/_admin/documents/$documentId",
        "/_admin/documents/"
      ]
    },
    "/_admin/plans": {
      "filePath": "_admin/plans/route.tsx",
      "parent": "/_admin",
      "children": [
        "/_admin/plans/$planId",
        "/_admin/plans/"
      ]
    },
    "/_admin/users": {
      "filePath": "_admin/users/route.tsx",
      "parent": "/_admin",
      "children": [
        "/_admin/users/$userId",
        "/_admin/users/"
      ]
    },
    "/_authenticated/accounts": {
      "filePath": "_authenticated/accounts/route.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/accounts/$accountId",
        "/_authenticated/accounts/"
      ]
    },
    "/_authenticated/gauges": {
      "filePath": "_authenticated/gauges/route.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/gauges/$gaugeId",
        "/_authenticated/gauges/"
      ]
    },
    "/_authenticated/tanks": {
      "filePath": "_authenticated/tanks/route.tsx",
      "parent": "/_authenticated",
      "children": [
        "/_authenticated/tanks/$tankId",
        "/_authenticated/tanks/"
      ]
    },
    "/_unauthenticated/login": {
      "filePath": "_unauthenticated/login/route.tsx",
      "children": [
        "/_unauthenticated/login/forgot",
        "/_unauthenticated/login/register",
        "/_unauthenticated/login/"
      ]
    },
    "/_unauthenticated/privacy": {
      "filePath": "_unauthenticated/privacy.tsx"
    },
    "/_unauthenticated/terms": {
      "filePath": "_unauthenticated/terms.tsx"
    },
    "/_admin/customers/$customerId": {
      "filePath": "_admin/customers/$customerId.tsx",
      "parent": "/_admin/customers"
    },
    "/_admin/documents/$documentId": {
      "filePath": "_admin/documents/$documentId.tsx",
      "parent": "/_admin/documents"
    },
    "/_admin/plans/$planId": {
      "filePath": "_admin/plans/$planId.tsx",
      "parent": "/_admin/plans"
    },
    "/_admin/users/$userId": {
      "filePath": "_admin/users/$userId.tsx",
      "parent": "/_admin/users"
    },
    "/_authenticated/accounts/$accountId": {
      "filePath": "_authenticated/accounts/$accountId.tsx",
      "parent": "/_authenticated/accounts"
    },
    "/_authenticated/gauges/$gaugeId": {
      "filePath": "_authenticated/gauges/$gaugeId.tsx",
      "parent": "/_authenticated/gauges"
    },
    "/_authenticated/tanks/$tankId": {
      "filePath": "_authenticated/tanks/$tankId.tsx",
      "parent": "/_authenticated/tanks"
    },
    "/_unauthenticated/login/forgot": {
      "filePath": "_unauthenticated/login/forgot.tsx",
      "parent": "/_unauthenticated/login"
    },
    "/_unauthenticated/login/register": {
      "filePath": "_unauthenticated/login/register.tsx",
      "parent": "/_unauthenticated/login"
    },
    "/_admin/customers/": {
      "filePath": "_admin/customers/index.tsx",
      "parent": "/_admin/customers"
    },
    "/_admin/documents/": {
      "filePath": "_admin/documents/index.tsx",
      "parent": "/_admin/documents"
    },
    "/_admin/plans/": {
      "filePath": "_admin/plans/index.tsx",
      "parent": "/_admin/plans"
    },
    "/_admin/users/": {
      "filePath": "_admin/users/index.tsx",
      "parent": "/_admin/users"
    },
    "/_authenticated/accounts/": {
      "filePath": "_authenticated/accounts/index.tsx",
      "parent": "/_authenticated/accounts"
    },
    "/_authenticated/gauges/": {
      "filePath": "_authenticated/gauges/index.tsx",
      "parent": "/_authenticated/gauges"
    },
    "/_authenticated/tanks/": {
      "filePath": "_authenticated/tanks/index.tsx",
      "parent": "/_authenticated/tanks"
    },
    "/_unauthenticated/login/": {
      "filePath": "_unauthenticated/login/index.tsx",
      "parent": "/_unauthenticated/login"
    }
  }
}
ROUTE_MANIFEST_END */
