import { IconGauge } from "@tabler/icons-react";
import { useForm } from "@tanstack/react-form";
import {
  infiniteQueryOptions,
  queryOptions,
  useMutation,
  useQueryClient,
  useSuspenseQuery,
} from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { zodValidator } from "@tanstack/zod-form-adapter";
import { subDays } from "date-fns";
import { z } from "zod";

import { strappingSort, tekADCProbeName } from "@joy/shared-calculator";
import { sortField } from "@joy/shared-utils";

import { useValidators } from "../../hooks";
import { Word } from "../helpers";
import { accountTanksQuery } from "./accounts";
import { request, requestFn } from "./base";
import {
  DeleteGaugeDocument,
  Gauge,
  GaugeConfig,
  GaugeDocument,
  GaugeObservationsDocument,
  GaugeProduct,
  GaugeSettingsDocument,
  GaugeTechnology,
  GaugesDocument,
  Observation,
  UpdateGaugeDocument,
  VerifyGaugeDocument,
} from "./operations.generated";
import { tankQuery } from "./tanks";

export const gauge: Word = {
  icon: IconGauge,
  article: "a",
  singular: "gauge",
  plural: "gauges",
};

export const gaugeUnit: Record<GaugeTechnology, string> = {
  Pressure: "m",
  Temperature: "m",
  TemperatureAndPressure: "m",
  Ultrasonic: "mm",
  ADC: "b",
  Unknown: "",
};

export const gaugeMax: Record<GaugeTechnology, number> = {
  Pressure: 2,
  Temperature: 2,
  TemperatureAndPressure: 2,
  Ultrasonic: 2000,
  ADC: 1024,
  Unknown: 100,
};

export type GaugeCalculatorProps<P extends GaugeProduct> = {
  observation: Observation | null | undefined;
  config: GaugeConfig & { __typename: `${P}Config` };
  strappingTable: Gauge["strappingTable"];
};

export const gaugesQuery = (enabled = true) =>
  infiniteQueryOptions({
    enabled,
    queryKey: ["gauges"],
    queryFn: ({ pageParam }) =>
      request(GaugesDocument, { limit: 100, cursor: pageParam || null }),
    getNextPageParam: (lastPage) => lastPage?.gauges.next,
    initialPageParam: "",
    select: (data) => data.pages.flatMap((p) => p.gauges.gauges),
  });

export type GaugeItem = ReturnType<
  NonNullable<ReturnType<typeof gaugesQuery>["select"]>
>[number];

export const isModelGauge = (
  gauge: GaugeItem,
): gauge is GaugeItem & { model: string } =>
  !!gauge.model && !!gauge.strappingTable.length;

export const gaugeQuery = (id: string) =>
  queryOptions({
    queryKey: ["gauge", id],
    queryFn: () => request(GaugeDocument, { id }),
    select: (data) => ({
      ...data.gauge!,
      observation: data.gauge?.observations.observations[0],
      permissions: data.gaugeAccess,
    }),
  });

export const gaugeSettingsQuery = (id: string) =>
  queryOptions({
    queryKey: ["gauge-settings", id],
    queryFn: () => request(GaugeSettingsDocument, { id }),
    select: (data) => ({
      id: data.gauge?.id,
      name: data.gauge?.name,
      product: data.gauge?.product,
      seenAt: data.gauge?.seenAt,
      deactivatedAt: data.gauge?.deactivatedAt,
      setting: data.gauge?.setting,
      response: data.gauge?.responses.responses[0],
      observation: data.gauge?.observations.observations[0],
      permissions: data.gaugeAccess,
    }),
  });

export type GaugeSettingsItem = ReturnType<
  NonNullable<ReturnType<typeof gaugeSettingsQuery>["select"]>
>;

export const gaugeObservationQuery = (id: string, daysAgo?: number) =>
  infiniteQueryOptions({
    enabled: !!daysAgo,
    queryKey: ["gauge-observation", id, daysAgo],
    queryFn: ({ pageParam }) =>
      request(GaugeObservationsDocument, {
        id,
        startAt: subDays(new Date(), daysAgo || 0),
        limit: 500,
        cursor: pageParam || null,
      }),
    getNextPageParam: (lastPage) => lastPage?.gauge?.observations.next,
    initialPageParam: "",
    select: (data) =>
      sortField(
        data.pages.flatMap((p) => p.gauge?.observations.observations || []),
        { key: "updatedAt", dir: "asc" },
      ),
  });

const validation = {
  name: z.string().min(1, "Please enter a gauge name"),
  customer: z.object(
    { id: z.string() },
    { invalid_type_error: "Please select a customer" },
  ),
  account: z
    .object(
      { id: z.string() },
      { invalid_type_error: "Please select an account" },
    )
    .optional()
    .nullable(),
};

const configValidation = {
  offset: z
    .string({ invalid_type_error: "Must be a number" })
    .regex(/^-?\d+(\.\d+)?$/, "Must be a number"),
  specificGravity: z
    .string({ invalid_type_error: "Must be a number" })
    .regex(/^\d+(\.\d+)?$/, "Must be a number"),
  range: z
    .string({ invalid_type_error: "Must be a number" })
    .regex(/^\d+(\.\d+)?$/, "Must be a number"),
  strapping: z
    .string({ invalid_type_error: "Must be a number" })
    .regex(/^\d+$/, "Must be a number"),
  strappingRow: z.object({ mm: z.number(), l: z.number() }),
};

const verifyGaugeFn = requestFn(VerifyGaugeDocument);

const updateGaugeFn = requestFn(UpdateGaugeDocument);

const useGaugeMutation = (gaugeId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(gaugeQuery(gaugeId));
  const queryClient = useQueryClient();
  const verify = useMutation({
    mutationFn: verifyGaugeFn,
    onSuccess: async () => {
      queryClient.invalidateQueries(gaugeQuery(gaugeId));
      queryClient.invalidateQueries(tankQuery(gaugeId));
      if (data?.account?.id)
        queryClient.invalidateQueries(accountTanksQuery(data?.account?.id));

      await navigate({ to: "/gauges/$gaugeId", params: { gaugeId } });
    },
  });
  const update = useMutation({
    mutationFn: updateGaugeFn,
    onSuccess: async (result) => {
      queryClient.invalidateQueries(gaugeSettingsQuery(gaugeId));
      queryClient.setQueryData(
        gaugeQuery(result.updateGauge.id).queryKey,
        (existing) => {
          if (!existing) return undefined;

          return {
            ...existing,
            gauge: result.updateGauge,
          };
        },
      );
      queryClient.setQueryData(gaugesQuery().queryKey, (existing) => {
        if (!existing) return undefined;

        return {
          ...existing,
          pages: existing.pages.map((p) => ({
            ...p,
            gauges: {
              ...p.gauges,
              gauges: p.gauges.gauges.map((u) => {
                if (u.id === result.updateGauge.id) return result.updateGauge;
                return u;
              }),
            },
          })),
        };
      });
    },
  });

  return { data, verify, update };
};

export const useUpdateGaugeStrapping = (gaugeId: string) => {
  const { data, verify, update } = useGaugeMutation(gaugeId);

  const form = useForm({
    defaultValues: {
      model: data.model || "",
      strappingTable:
        data.strappingTable.map((s) => ({
          mm: s.mm.toString(),
          l: s.l.toString(),
        })) || [],
    },
    onSubmit: async ({ value: { model, strappingTable } }) => {
      await update.mutateAsync({
        id: gaugeId,
        fields: {
          model: model || undefined,
          strappingTable: strappingSort(
            strappingTable
              .map((s) => ({
                mm: parseFloat(s.mm),
                l: parseFloat(s.l),
              }))
              .filter(
                (s) => configValidation.strappingRow.safeParse(s).success,
              ),
            "mm",
          ),
        },
      });
      await verify.mutateAsync({ id: gaugeId });
    },
    onSubmitInvalid: () => {
      update.reset();
      verify.reset();
    },
    validatorAdapter: zodValidator(),
  });
  const validators = useValidators(
    configValidation,
    form.state.submissionAttempts,
  );

  return { data, error: update.error || verify.error, form, validators };
};

export const useUpdateGaugeConfig = (gaugeId: string) => {
  const { data, verify, update } = useGaugeMutation(gaugeId);

  const form = useForm({
    defaultValues: {
      probeName:
        data.config.__typename === "TekADCConfig"
          ? tekADCProbeName(data.config)
          : "",
      rangeDigital: {
        min: (
          ("rangeDigital" in data.config && data.config.rangeDigital.min) ||
          0
        ).toString(),
        max: (
          ("rangeDigital" in data.config && data.config.rangeDigital.max) ||
          0
        ).toString(),
      },
      rangeAnalog: {
        min: (
          ("rangeAnalog" in data.config && data.config.rangeAnalog.min) ||
          0
        ).toString(),
        max: (
          ("rangeAnalog" in data.config && data.config.rangeAnalog.max) ||
          0
        ).toString(),
      },
      rangeProbe: {
        min: (
          ("rangeProbe" in data.config && data.config.rangeProbe.min) ||
          0
        ).toString(),
        max: (
          ("rangeProbe" in data.config && data.config.rangeProbe.max) ||
          0
        ).toString(),
      },
      rangeHeight: {
        min: (
          ("rangeHeight" in data.config && data.config.rangeHeight.min) ||
          0
        ).toString(),
        max: (
          ("rangeHeight" in data.config && data.config.rangeHeight.max) ||
          0
        ).toString(),
      },

      mountToBottom: (
        ("mountToBottom" in data.config && data.config.mountToBottom) ||
        0
      ).toString(),
      offsetHeight: (
        ("offsetHeight" in data.config && data.config.offsetHeight) ||
        0
      ).toString(),
      offsetVolume: (
        ("offsetVolume" in data.config && data.config.offsetVolume) ||
        0
      ).toString(),
      specificGravityProbe: (
        ("specificGravityProbe" in data.config &&
          data.config.specificGravityProbe) ||
        1
      ).toString(),
      specificGravityActual: (
        ("specificGravityActual" in data.config &&
          data.config.specificGravityActual) ||
        1
      ).toString(),
    },
    onSubmit: async ({ value }) => {
      let config = {};
      switch (data.config.__typename) {
        case "CippusConfig":
          config = {
            offsetHeight: parseFloat(value.offsetHeight),
            offsetVolume: parseFloat(value.offsetVolume),
            specificGravityProbe: parseFloat(value.specificGravityProbe),
            specificGravityActual: parseFloat(value.specificGravityActual),
          };
          break;
        case "TekADCConfig":
          config = {
            rangeDigital: {
              min: parseFloat(value.rangeDigital.min),
              max: parseFloat(value.rangeDigital.max),
            },
            rangeAnalog: {
              min: parseFloat(value.rangeAnalog.min),
              max: parseFloat(value.rangeAnalog.max),
            },
            rangeProbe: {
              min: parseFloat(value.rangeProbe.min),
              max: parseFloat(value.rangeProbe.max),
            },
            rangeHeight: {
              min: parseFloat(value.rangeHeight.min),
              max: parseFloat(value.rangeHeight.max),
            },

            offsetHeight: parseFloat(value.offsetHeight),
            offsetVolume: parseFloat(value.offsetVolume),
            specificGravityProbe: parseFloat(value.specificGravityProbe),
            specificGravityActual: parseFloat(value.specificGravityActual),
          };
          break;
        case "TekUltrasonicConfig":
          config = {
            mountToBottom: parseFloat(value.mountToBottom),
            offsetVolume: parseFloat(value.offsetVolume),
          };
          break;
      }

      await update.mutateAsync({
        id: gaugeId,
        fields: {
          config,
        },
      });
      await verify.mutateAsync({ id: gaugeId });
    },
    onSubmitInvalid: () => {
      update.reset();
      verify.reset();
    },
    validatorAdapter: zodValidator(),
  });
  const validators = useValidators(
    configValidation,
    form.state.submissionAttempts,
  );

  return { data, error: update.error || verify.error, form, validators };
};

export type GaugeConfigContentProps = Pick<
  ReturnType<typeof useUpdateGaugeConfig>,
  "form" | "validators"
>;

export const useUpdateGauge = (gaugeId: string) => {
  const { data, verify, update } = useGaugeMutation(gaugeId);

  const form = useForm({
    defaultValues: {
      name: data.name || "",
      account: data.account,
      customer: data.customer,
    },
    onSubmit: async ({ value }) => {
      await update.mutateAsync({
        id: gaugeId,
        fields: {
          name: value.name,
          accountId: value.account?.id || "",
          customerId: value.customer?.id,
        },
      });
      await verify.mutateAsync({ id: gaugeId });
    },
    onSubmitInvalid: () => {
      update.reset();
      verify.reset();
    },
    validatorAdapter: zodValidator(),
  });
  const validators = useValidators(validation, form.state.submissionAttempts);

  return { data, error: update.error || verify.error, form, validators };
};

const deleteGaugeFn = requestFn(DeleteGaugeDocument);

export const useDeleteGauge = (gaugeId: string) => {
  const navigate = useNavigate();
  const { data } = useSuspenseQuery(gaugeQuery(gaugeId));
  const queryClient = useQueryClient();
  const { error, mutateAsync, isPending } = useMutation({
    mutationFn: deleteGaugeFn,
    onSuccess: async (result, { id }) => {
      if (result.deleteGauge) {
        queryClient.setQueryData(gaugesQuery().queryKey, (existing) => {
          if (!existing) return undefined;

          return {
            ...existing,
            pages: existing.pages.map((p) => ({
              ...p,
              gauges: {
                ...p.gauges,
                gauges: p.gauges.gauges.filter((u) => u.id !== id),
              },
            })),
          };
        });

        await navigate({ to: "/gauges" });
        queryClient.removeQueries(gaugeQuery(id));
      }
    },
  });

  return {
    data,
    error,
    isPending,
    onDelete: () => mutateAsync({ id: gaugeId }),
  };
};
