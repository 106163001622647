import { Input, InputProps } from "@headlessui/react";
import { IconExclamationCircle } from "@tabler/icons-react";
import { FieldApi } from "@tanstack/react-form";
import clsx from "clsx";
import { ReactNode } from "react";

import { tw } from "../../assets";
import { FieldError } from "./error";
import { Field, Label, fieldKinds } from "./parts";

export const inputKinds = {
  standard: tw`flex flex-1 flex-wrap items-center gap-x-2 rounded-md border-0 px-3 shadow-sm ring-1 ring-inset focus-within:ring-2 focus-within:ring-inset sm:col-span-2 sm:text-sm sm:leading-6`,
  simple: tw`flex flex-1 flex-wrap items-center gap-x-2`,
  wrapped: tw`block w-full flex-1 border-0 bg-transparent px-0 py-2 focus:ring-0`,
  icon: tw`pointer-events-none size-5 text-gray-500`,
};

export const inputVariants = {
  action: tw`bg-white ring-gray-300 placeholder:text-gray-400 focus-within:ring-sky-600`,
  error: tw`ring-red-800`,
};

export type TextInputProps = {
  className?: string;
  icon?: (props: { className?: string }) => ReactNode;
  kind?: keyof typeof inputKinds;
  variant?: keyof typeof inputVariants;
};

export const TextInput = ({
  className,
  icon: Icon,
  kind = "standard",
  variant = "action",
  ...props
}: TextInputProps & InputProps) => (
  <label
    className={clsx(
      inputKinds[kind],
      inputVariants[variant],
      props.invalid && inputVariants.error,
      className,
    )}
  >
    {Icon && <Icon className={inputKinds.icon} />}
    <Input className={inputKinds.wrapped} {...props} />
    {props.invalid && (
      <IconExclamationCircle
        className={clsx(inputKinds.icon, "text-red-700")}
      />
    )}
  </label>
);

export const TextInputField = ({
  field,
  ...props
}: {
  field: FieldApi<any, any, any, any, any>;
} & InputProps &
  TextInputProps) => (
  <TextInput
    id={field.name}
    name={field.name}
    value={field.state.value}
    onBlur={field.handleBlur}
    onChange={(e) => field.handleChange(e.target.value)}
    invalid={field.state.meta.errors.length > 0}
    {...props}
  />
);

export const TextField = ({
  field,
  label,
  fieldKind,
  ...rest
}: {
  field: FieldApi<any, any, any, any, any>;
  label: ReactNode;
  fieldKind?: keyof typeof fieldKinds;
} & TextInputProps &
  InputProps) => (
  <Field kind={fieldKind}>
    <Label htmlFor={field.name.toString()}>{label}</Label>
    <TextInputField field={field} {...rest} />
    <FieldError field={field} />
  </Field>
);
