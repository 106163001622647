import { IconAlertTriangle } from "@tabler/icons-react";

import { ModalContent, RangeInputField } from "../../components";
import { useDownloadTankHistory } from "../../data";

export const TankDownloadContent = ({ tankId }: { tankId: string }) => {
  const { data, error, form } = useDownloadTankHistory(tankId);

  return (
    <ModalContent
      icon={IconAlertTriangle}
      variant="action"
      title={`Download "${data.name}" history?`}
      description="Download up to 365 days of history as a CSV file."
      error={error}
      onSubmit={form.handleSubmit}
      loading={{ show: form.state.isSubmitting, text: "Downloading..." }}
      buttons={[
        { text: "Download", type: "submit", variant: "action" },
        { text: "Cancel", variant: "cancel", type: "reset" },
      ]}
    >
      <form.Field
        name="daysAgo"
        children={(field) => (
          <RangeInputField
            field={field}
            min={1}
            max={365}
            stepSize={1}
            tickSize={92}
            colours={[
              { bg: "bg-gray-300", text: "text-gray-500" },
              { bg: "bg-gray-300", text: "text-emerald-500" },
              { bg: "bg-emerald-500", text: "text-emerald-500" },
            ]}
          />
        )}
      />
    </ModalContent>
  );
};
