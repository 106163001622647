import { IconHistory } from "@tabler/icons-react";
import { ColumnHelper } from "@tanstack/react-table";
import clsx from "clsx";

import { brandIcons, specialChars, tw } from "../../assets";
import {
  CellImage,
  Time,
  actionCell,
  cellKinds,
  dateCell,
  displayBattery,
  imageCell,
  stackCell,
} from "../../components";
import {
  TankItem,
  displayPercent,
  displayTemperature,
  displayVolume,
  tankLevelStyle,
} from "../../data";

export const tankColumns = (
  c: ColumnHelper<TankItem>,
  viewMode: "table" | "grid" | "map",
) => {
  switch (viewMode) {
    case "table":
      return [
        c.accessor(
          "account.product",
          imageCell({
            header: "",
            icons: brandIcons,
          }),
        ),
        c.accessor(
          "name",
          stackCell({
            header: "Name",
            detail: (data) => [
              [
                displayVolume(data.level?.value, data.unit),
                displayTemperature(data.level?.temperature),
                displayBattery(data.level?.voltage, "-my-1"),
              ],
            ],
          }),
        ),
        c.accessor("account.name", {
          header: "Account",
          meta: { className: cellKinds.appears },
        }),
        c.accessor("level.percent", {
          header: "Percent",
          meta: { className: cellKinds.appears },
          cell: (r) => displayPercent(r.getValue()) || specialChars.endash,
        }),
        c.accessor("level.value", {
          header: "Amount",
          meta: { className: cellKinds.appearsxl },
          cell: (r) => displayVolume(r.getValue(), r.row.original.unit),
        }),
        c.accessor("level.voltage", {
          header: "Battery",
          meta: { className: cellKinds.appearsxl },
          cell: (r) => displayPercent(r.getValue()) || specialChars.endash,
        }),
        c.accessor("level.temperature", {
          header: "Temperature",
          meta: { className: cellKinds.appearsxl },
          cell: (r) => displayTemperature(r.getValue()) || specialChars.endash,
        }),
        c.accessor(
          "level.updatedAt",
          dateCell({
            header: "Last Active",
          }),
        ),
        c.display(actionCell()),
      ];
    case "grid":
    case "map":
      return [
        c.display({
          id: "fill",
          meta: { className: tw`absolute inset-0 flex flex-col` },
          cell: (v) => {
            const { height, colour } = tankLevelStyle(
              v.row.original.level?.percent,
              v.row.original.warningLevels,
            );
            return (
              <>
                <div className="h-3 bg-slate-200" />
                <div className="relative flex-1 overflow-hidden">
                  <svg
                    viewBox="0 0 200 200"
                    preserveAspectRatio="xMidYMin slice"
                    className={clsx(
                      "absolute inset-x-0 bottom-0 w-full opacity-30",
                      colour.text,
                    )}
                    style={{ height }}
                  >
                    <path
                      d="M0,200 L0,5.551 Q60,-2.775 120,5.551 L120,200 z"
                      fill="currentColor"
                      opacity="0.5"
                    />
                    <path
                      d="M0,200 L0,2.775 Q60,8.326 110,2.775 Q160,-2.775 200,2.775 L200,200 z"
                      fill="currentColor"
                    />
                  </svg>
                </div>
              </>
            );
          },
        }),
        c.accessor("account.name", {
          header: "Account Name",
          cell: (v) => (
            <p className="truncate text-sm font-normal">{v.getValue()}</p>
          ),
        }),
        c.accessor("name", {
          header: "Tank Name",
          cell: (v) => (
            <p className="truncate text-sm font-semibold">{v.getValue()}</p>
          ),
        }),
        c.accessor("level.percent", {
          header: "Percent Fill",
          cell: (v) => (
            <p className="text-2xl font-bold">
              {displayPercent(v.getValue()) || specialChars.endash}
            </p>
          ),
        }),
        c.accessor("level.value", {
          header: "Level",
          meta: { className: tw`absolute left-2 top-4 w-1/2 text-left` },
          cell: (v) => (
            <p className="truncate text-sm font-normal">
              {displayVolume(v.getValue(), v.row.original.unit)} /{" "}
              {displayVolume(
                v.row.original.level?.maximum,
                v.row.original.unit,
              )}
            </p>
          ),
        }),
        c.display({
          id: "level_ullage",
          meta: { className: tw`absolute right-2 top-4 w-1/2 text-right` },
          cell: (v) => (
            <p className="truncate text-sm font-normal">
              <strong>U: </strong>
              {displayVolume(
                v.row.original.level &&
                  v.row.original.level.maximum - v.row.original.level.value,
                v.row.original.unit,
              )}
            </p>
          ),
        }),
        c.accessor("level.voltage", {
          header: "Battery",
          meta: { className: tw`absolute left-2 top-9 w-1/2 text-left` },
          cell: (v) => <p>{displayBattery(v.getValue())}</p>,
        }),
        c.accessor("level.temperature", {
          meta: {
            className: tw`absolute right-2 top-9 w-1/2 justify-end text-right`,
          },
          cell: (v) => (
            <p className="truncate text-sm font-normal">
              {displayTemperature(v.getValue())}
            </p>
          ),
        }),
        c.accessor("level.updatedAt", {
          header: "Updated At",
          meta: { className: tw`absolute bottom-2 left-2 w-3/4 text-left` },
          cell: (v) => (
            <Time
              date={v.getValue()}
              className="flex items-center gap-1 truncate text-sm font-normal"
              prefix={<IconHistory className="size-5" />}
            />
          ),
        }),
        c.accessor("account.product", {
          header: "Product",
          meta: {
            className: tw`absolute bottom-2 right-2 flex w-1/4 items-center justify-end`,
          },
          cell: (v) => (
            <CellImage alt={v.getValue()} src={brandIcons[v.getValue()]} />
          ),
        }),
        c.accessor("location.latitude", {
          id: "latitude",
        }),
        c.accessor("location.longitude", {
          id: "longitude",
        }),
      ];
  }
};
